import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// fetch("https://publicapi.eje.ae/api/common/phrases")
//   .then((response) => response.json())
//   .then((resJson) => {
//     console.log(resJson);
//     const en_api = { translation: {} };
//     const ar_api = { translation: {} };
//     resJson.data.map((phrase) => {
//       en_api.translation[phrase.phraseKey] = phrase.phraseEn;
//       ar_api.translation[phrase.phraseKey] = phrase.phraseAr;
//     });
//     resources.en = en_api;
//     resources.ar = ar_api;

//   });
