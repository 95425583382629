import React from "react";
import { Link } from "react-router-dom";
const ArchiveItem = ({ item, lang, arPhrase, enPhrase }) => {
  return (
    <div className="w-full mb-2">
      <div className="flex flex-col items-center justify-center">
        <div className="items-center w-full bg-white overflow-hidden">
          <div className="flex flex-row flex-wrap md:flex-nowrap justify-between gap-y-4 md:gap-0 p-3 w-full bg-white border-2 border-solid border-[#EEE2D0] rounded">
            <div className="flex flex-col content-between justify-center basis-1/2 md:basis-auto">
              <p className="text-sm font-normal text-[#010202]">
                {lang === "en" ? enPhrase["lblCourt"] : arPhrase["lblCourt"]}
              </p>
              <p className="text-base font-bold text-[#010202]">
                {item?.courtName}
              </p>
            </div>
            <div className="flex flex-col content-between justify-center basis-1/2 md:basis-auto">
              <p className="text-sm font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblCaseNumber"]
                  : arPhrase["lblCaseNumber"]}
              </p>
              <p className="flex text-base font-bold text-[#010202]">
                {/* <span>{caseFileYear}</span>
                <span>/</span>
                <span>{caseFileNumber}</span> */}
                {item?.caseNumber}
              </p>
            </div>
            <div className="flex flex-col content-between justify-center basis-1/2 md:basis-auto">
              <p className="text-sm font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblCaseType"]
                  : arPhrase["lblCaseType"]}
              </p>
              <p className="text-base font-bold text-[#010202]">
                {item?.caseType}
              </p>
            </div>
            <div className="flex flex-col content-between justify-center basis-1/2 md:basis-auto">
              <p className="text-sm font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblCaseEndDate"]
                  : arPhrase["lblCaseEndDate"]}
              </p>
              <p className="text-base font-bold text-[#010202]">
                {lang === "en"
                  ? new Date(item?.endDate).toLocaleDateString("en-GB")
                  :new Date(item?.endDate).toLocaleDateString("en-GB")}
              </p>
            </div>
            <Link
              to={`/case-details/${item?.caseId}`}
              className="flex justify-start md:justify-center items-center gap-1 basis-96 md:basis-auto"
            >
              <div className="flex justify-start md:justify-center items-center gap-1 basis-1/2 md:basis-auto">
                <span className="text-sm text-[#B68A35] font-bold cursor-pointer">
                  {lang === "en"
                    ? enPhrase["lblCaseDetails"]
                    : arPhrase["lblCaseDetails"]}
                </span>
                <img
                  src="/assets/img/case-overview/chevron--sort--down.svg"
                  alt=""
                  className={`w-[8px] h-[16px] ${
                    lang === "en" ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveItem;
