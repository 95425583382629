import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { PhrasesContext } from "../../../context/phrasesContext";

import "react-phone-number-input/style.css";
import { Loader, toast, toastHtml } from "../../../components/utils";
import { UploadReceipt, addComment } from "../../../services/Common";
import SharedModal from "../../happiness/SharedModal";
import { RequesterContext } from "../../../context/requesterContext";
const PopupForm = ({
  handelOpenPopup,
  getCommentsService,
  serviceid,
  setOpenPopub,
}) => {
  const maxOffset = 60;
  const inputFile = useRef(null);
  const [lang, setLang] = useState("");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});
  // const [courts, setCourts] = useState([]);
  // const [caseTypes, setCaseTypes] = useState([]);
  const [years, setYears] = useState([]);
  const [file, setFile] = useState(null);
  const [fileSecond, setFileSecond] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadedFileAnother, setUploadedFileAnother] = useState({});
  // const [requester, setRequester] = useState("");
  const [documents, setDocuments] = useState([]);
  const [anotherDocuments, setAnotherDocuments] = useState([]);
  const newplugin = defaultLayoutPlugin();
  const [fileURLImage, setFileURLImage] = useState();

  const [showImage, setShowImage] = useState(false);
  const [showSecondImage, setShowSecondImage] = useState(false);

  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [requesterInfo] = useContext(RequesterContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    const year = new Date().getFullYear();
    setYears(Array.from(new Array(maxOffset), (val, index) => year - index));

    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }

    // getPhrasesService();
    // getInfoService(currentLang);
    // getRequesterService(currentLang);
  }, []);

  // const getRequesterService = (currentLang) => {
  //   return getRequester(currentLang).then((res) => {
  //     setRequester(res);
  //   });
  // };

  const changeLanguage2 = (lang) => {
    if (lang === "en") {
      document.body.dir = "rtl";
      setLang("ar");
      window.localStorage.setItem("lang", "ar");
    } else {
      document.body.dir = "ltr";
      setLang("en");
      window.localStorage.setItem("lang", "en");
    }
    window.location.reload();
  };

  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return "";
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };

  // const getInfoService = () => {
  //   return getInfo().then((res) => {
  //     if (res.status === 200) {
  //       setCourts((courts) => res.data.courts);
  //       setCaseTypes((caseTypes) => res.data.caseTypes);
  //     }
  //   });
  // };

  const updateData = (data) => {
    UploadReceipt(data).then(
      (res) => {
        setLoading(false);
        const title =
          lang === "en"
            ? enPhrase["lbl_Success_upload"]
            : arPhrase["lbl_Success_upload"];
        const success = `<div className=" flex flex-col items-center">
        <span>${
          lang === "en"
            ? enPhrase["lbl_success_upload_receipt"]
            : arPhrase["lbl_success_upload_receipt"]
        }</span><br/><p className="text-black bg-zinc-200 w-fit text-center rounded-full font-medium  px-4 py-3">${
          lang === "en" ? enPhrase["lbl_ref"] : arPhrase["lbl_ref"]
        } #${res}</p></div>`;

        const ok =
          lang === "en" ? enPhrase["lbl_Complete"] : arPhrase["lbl_Complete"];
        toastHtml(title, success, ok);

        document.getElementById("formreceipt").reset();
        document.getElementsByClassName("PhoneInputInput")[0].value = null;
      },
      (err) => {
        const title =
          lang === "en" ? enPhrase["lbl_Error"] : arPhrase["lbl_Error"];
        const success =
          lang === "en" ? enPhrase["lbl_Error_Msg"] : arPhrase["lbl_Error_Msg"];
        const ok = lang === "en" ? enPhrase["lbl_Ok"] : arPhrase["lbl_Ok"];
        toast(title, success, ok, "error");
      }
    );
  };
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);

      setFileURLImage(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const onSubmit = async (data) => {
    setLoading(true);

    let commentInfo = {
      ServiceId: serviceid,
      UserId: requesterInfo?.id,
      UserType: 1,
      Comment: data.comment,
      Files: documents.newValue,
      Files2: anotherDocuments.newValue,
    };

    addCommentService(commentInfo);
  };

  const addCommentService = (data) => {
    addComment(data).then(
      (res) => {
        setLoading(false);
        if (res?.success) {
          handelOpenPopup(false);
          getCommentsService();
        } else {
          handelOpenPopup(false);
        }
      },
      (err) => {
        handelOpenPopup(false);
      }
    );
  };

  const handleChange = (e) => {
    setUploadedFile(e.target.files);
    // setDocuments((prevState) => {
    //   return { ...prevState, enteredFile: e.target.files[0] };
    // });

    let newValue = e.target.files[0];
    setDocuments({ ...documents, newValue });
    if (e.target.files.length > 0) {
      getBase64(e.target.files[0], (res) => {
        setFile(res);
      });
    } else {
      setFile(null);
    }
  };

  const handleChangeAnother = (e) => {
    setUploadedFileAnother(e.target.files);
    let newValue = e.target.files[0];
    // setAnotherDocuments({ ...anotherDocuments, newValue });
    setAnotherDocuments({ ...anotherDocuments, newValue });

    if (e.target.files.length > 0) {
      getBase64(e.target.files[0], (res) => {
        setFileSecond(res);
      });
    } else {
      setFileSecond(null);
    }
  };

  return (
    <div className="w-full ">
      <form
        className="w-full "
        id="formreceipt"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col overflow-hidden border-2 border-solid border-[#EEE2D0] shadow-[0px_10px_40px_#361E1214] rounded-t-[16px]">
          <div className="text-sm text-[#010202]  font-bold bg-[#EEE2D0] h-[40px] leading-[40px] px-2 flex items-center justify-between gap-[20px]">
            <span>
              {lang === "en"
                ? enPhrase["lblCompleteData"]
                : arPhrase["lblCompleteData"]}
            </span>
            <div
              className="text-[#000] w-[24px] h-[24px] text-[20px] cursor-pointer flex items-center justify-center"
              onClick={() => setOpenPopub(false)}
            >
              <img src="/assets/img/close.svg" alt="" className="" />
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-b-[16px]">
          <div className="bg-white mb-4">
            <label
              htmlFor="comment"
              className="block text-[12px] font-bold text-gray-700"
            >
              {lang === "en" ? enPhrase["lblDetails"] : arPhrase["lblDetails"]}
            </label>
            <textarea
              name="comment"
              key="comment"
              placeholder={
                lang === "en"
                  ? enPhrase["lblWriteDetails"]
                  : arPhrase["lblWriteDetails"]
              }
              aria-invalid={errors.comment ? "true" : "false"}
              {...register("comment", {
                required: true,
              })}
              className={`mt-2 block w-full rounded-md h-[96px] shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm resize-none ${
                errors.comment ? " border-red-600" : " border-gray-300"
              }`}
            />
            {errors.comment && (
              <span role="alert" className=" text-xs text-red-700">
                {lang === "en"
                  ? enPhrase["lblErrorDetails"]
                  : arPhrase["lblErrorDetails"]}
              </span>
            )}
          </div>

          {Object.keys(uploadedFile).length > 0 ||
          Object.keys(uploadedFileAnother).length > 0 ? (
            <div className="h-[125px] flex flex-row gap-2">
              <div className="w-full h-[125px] flex flex-col gap-[8px]">
                {Object.keys(uploadedFile).map((item, i) => (
                  <div className="w-full  h-[66px] p-[16px] rounded-[4px] border-[1px] border-solid border-[#d6d6d6] bg-[#fff] flex items-center justify-center gap-[16px]">
                    <img
                      src="/assets/img/submission.svg"
                      alt="upload"
                      className="w-[25px] h-[30px]"
                    />
                    {showImage ? (
                      <SharedModal
                        showImage
                        setOpenModal={setShowImage}
                        openModal={showImage}
                      >
                        {uploadedFile[item].type.includes("application/pdf") ===
                        true ? (
                          <embed
                            src={file}
                            className="w-full h-[344px]"
                          ></embed>
                        ) : (
                          <img
                            className="w-full h-full"
                            alt="icon"
                            src={file}
                          />
                        )}
                      </SharedModal>
                    ) : (
                      <div className="flex flex-col h-[34px]  items-start justify-start">
                        <p className="text-[14px] font-Almarai font-bold text-[#361e12] w-[50px] sm:w-[237px]   h-[16px] truncate 	overflow-hidden ">
                          {uploadedFile[item].name}
                        </p>
                        <p className="text-[12px] font-Almarai  text-[#6e6e6e]">
                          {uploadedFile[item].size}KB{" "}
                        </p>
                      </div>
                    )}
                    <img
                      src="/assets/img/view.svg"
                      alt="view-icon"
                      className=" w-[24px] h-[24px] cursor-pointer"
                      onClick={() => setShowImage(!showImage)}
                    />
                    <img
                      src="/assets/img/trash-can.svg"
                      alt="delete-icon"
                      className="w-[18px] h-[18px] cursor-pointer"
                      onClick={() => setUploadedFile("")}
                    />
                  </div>
                ))}
                {Object.keys(uploadedFileAnother).map((item, i) => (
                  <div className="w-full  h-[66px] p-[16px] rounded-[4px] border-[1px] border-solid border-[#d6d6d6] bg-[#fff] flex items-center justify-center gap-[16px]">
                    <img
                      src="/assets/img/submission.svg"
                      alt="upload"
                      className="w-[25px] h-[30px]"
                    />
                    {showSecondImage ? (
                      <SharedModal
                        showSecondImage
                        setOpenModal={setShowSecondImage}
                        openModal={showSecondImage}
                      >
                        {uploadedFileAnother[item].type.includes(
                          "application/pdf"
                        ) === true ? (
                          // <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                          //   <Viewer
                          //     fileUrl={fileSecond}
                          //     plugins={[newplugin]}
                          //   />
                          // </Worker>
                          <embed
                            src={fileSecond}
                            className="w-full h-[344px]"
                          ></embed>
                        ) : (
                          <img
                            className="w-full h-full"
                            alt="icon"
                            src={fileSecond}
                          />
                        )}
                      </SharedModal>
                    ) : (
                      <div className="flex flex-col h-[34px]  items-start justify-start">
                        <p className="text-[14px] font-Almarai font-bold text-[#361e12] w-[50px] sm:w-[237px]   h-[16px] truncate 	overflow-hidden ">
                          {uploadedFileAnother[item].name}
                        </p>
                        <p className="text-[12px] font-Almarai  text-[#6e6e6e]">
                          {uploadedFileAnother[item].size}KB{" "}
                        </p>
                      </div>
                    )}
                    <img
                      src="/assets/img/view.svg"
                      alt="view-icon"
                      className=" w-[24px] h-[24px] cursor-pointer"
                      onClick={() => setShowSecondImage(true)}
                    />
                    <img
                      src="/assets/img/trash-can.svg"
                      alt="delete-icon"
                      className="w-[18px] h-[18px] cursor-pointer"
                      onClick={() => setUploadedFileAnother("")}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {Object.keys(uploadedFile).length > 0 ? (
            <div
              className={`my-4 ${
                Object.keys(uploadedFileAnother).length === 1
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              } flex `}
            >
              <label
                htmlFor="fileupload"
                className={`relative ${
                  Object.keys(uploadedFileAnother).length === 1
                    ? "cursor-not-allowed !bg-[#ccc] "
                    : "cursor-pointer"
                }  flex w-[100%] gap-[12px] items-center justify-center w-100 h-[48px] border-2 border-dashed bg-gold-10 border-[#B4B4B4] r rounded-md  px-6  font-medium text-gold-100 focus-within:outline-none focus-within:ring-2`}
              >
                <img
                  src="/assets/img/uploadFile.svg"
                  alt="addFile"
                  className={`w-[20px] h-[20px] ${
                    Object.keys(uploadedFileAnother).length === 1
                      ? "hidden"
                      : "block"
                  }`}
                />

                <p
                  className={`text-[12px] ${
                    Object.keys(uploadedFileAnother).length === 1
                      ? "!text-[#838383]"
                      : "text-[#6C4527]"
                  } font-bold`}
                >
                  {lang === "en"
                    ? enPhrase["lblAttachAnotherFile"]
                    : arPhrase["lblAttachAnotherFile"]}
                </p>
                {/* )} */}
                <input
                  id="fileupload"
                  name="fileupload"
                  ref={inputFile}
                  multiple
                  type="file"
                  disabled={
                    Object.keys(uploadedFileAnother).length === 1 ? true : false
                  }
                  accept="image/png, image/gif, image/jpeg, application/pdf"
                  {...register("fileupload", {
                    required: true,
                  })}
                  // onChange={(e) => {
                  //   if (e.target.files.length > 0) {
                  //     setUploadedFile(e.target.files[0]);

                  //     getBase64(e.target.files[0], (res) => {
                  //       setFile(res);
                  //     });
                  //   } else {
                  //     setFile(null);
                  //   }
                  // }}

                  onChange={handleChangeAnother}
                  className="sr-only "
                />
                {!file && errors.fileupload && (
                  <span role="alert" className="text-red-700 mx-3 text-xs">
                    {lang === "en"
                      ? enPhrase["lblAttacheFileError"]
                      : arPhrase["lblAttacheFileError"]}
                  </span>
                )}
              </label>
            </div>
          ) : (
            <div className="my-4 cursor-pointer flex ">
              <label
                htmlFor="fileupload"
                className="relative cursor-pointer flex w-[100%] gap-[12px] items-center justify-center w-100 h-[48px] border-2 border-dashed bg-gold-10 border-[#B4B4B4] r rounded-md  px-6  font-medium text-gold-100 focus-within:outline-none focus-within:ring-2  "
              >
                <img
                  src="/assets/img/uploadFile.svg"
                  alt="addFile"
                  className="w-[20px] h-[20px]"
                />
                <p className="text-[10px] xsm:text-[12px] text-[#6C4527] font-bold">
                  {lang === "en"
                    ? enPhrase["lblAttachFile"]
                    : arPhrase["lblAttachFile"]}
                </p>
                <input
                  id="fileupload"
                  name="fileupload"
                  ref={inputFile}
                  type="file"
                  accept="image/png, image/gif, image/jpeg, application/pdf"
                  {...register("fileupload", {
                    required: true,
                  })}
                  // onChange={(e) => {
                  //   if (e.target.files.length > 0) {
                  //     setUploadedFile(e.target.files[0]);
                  //     getBase64(e.target.files[0], (res) => {
                  //       setFile(res);
                  //     });
                  //   } else {
                  //     setFile(null);
                  //   }
                  // }}

                  onChange={handleChange}
                  className="sr-only "
                />
                {!file && errors.fileupload && (
                  <span role="alert" className="text-red-700 mx-3 text-xs">
                    {lang === "en"
                      ? enPhrase["lblAttacheFileError"]
                      : arPhrase["lblAttacheFileError"]}
                  </span>
                )}
              </label>
            </div>
          )}

          <div className=" flex justify-end bg-white">
            <button
              type="submit"
              disabled={loading}
              className="inline-flex justify-center gap-2 items-center w-full h-[44px] rounded-md border border-transparent bg-gold-100 py-4 px-8 text-sm font-bold text-white shadow-sm"
            >
              <span>
                {lang === "en"
                  ? enPhrase["lblResponse"]
                  : arPhrase["lblResponse"]}
              </span>
              {loading && <Loader color={"white"} />}
            </button>
          </div>
        </div>
      </form>
    </div>
    // </div>
  );
};

export default PopupForm;
