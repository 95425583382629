import { React, useContext, useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { Container } from "../components/utils";
import { PhrasesContext } from "../context/phrasesContext";
import Footer from "../layouts/Footer";
import Nav from "../layouts/Nav";
import FooterLayout from "./FooterLayout";
const ThemeLayout = ({ children }) => {
  const maxOffset = 60;

  const [lang, setLang] = useState("");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

 

  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");

    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
    } else {
      document.body.dir = "rtl";
      setLang("ar");
    }

    // getPhrasesService();
  }, []);

  const changeLanguage2 = (lang) => {
    if (lang === "en") {
      document.body.dir = "rtl";
      setLang("ar");
      window.localStorage.setItem("lang", "ar");
    } else {
      document.body.dir = "ltr";
      setLang("en");
      window.localStorage.setItem("lang", "en");
    }
    window.location.reload();
  };

  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return "";
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };

  return (
    <div className="w-full">
      <Nav
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        changeLanguage2={changeLanguage2}
        hideUpload={true}
      />
      <section
        id="overflow"
        className="w-full overflow-hidden bg-gold-10 border-t border-b wow fadeInUp pt-[0rem] xl:pt-[0rem] bg-LogoEJECommon bg-no-repeat bg-center-left min-h-[764px] xl:min-h-[728px]"
      >
        <div className="bg-white absolute h-18 w-100"> </div>
        <Container>
          <div className="flex flex-col gap-10 items-center justify-center ">
            {children}
          </div>
        </Container>
      </section>
      <FooterLayout lang={lang} enPhrase={enPhrase} arPhrase={arPhrase} />
    </div>
  );
};

export default ThemeLayout;
