import React, { useState } from "react";
export default function ChartBar({
  servicesDetails,
  data,
  maxPercentage = 100,

  isSubChart,
}) {
  const colorPalette = [
    "#9DDFFD",
    "#FF6064",
    "#C3BCB2",
    "#FFC670",
    "#67B8A1",
    "#edeae7",
    "#e61a05",
  ];

  const lang = window.localStorage.getItem("lang");

  const [isShown, setIsShown] = useState(false);
  const [title, setTitle] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [sub, setSub] = useState([]);

  const colorPaletteLatest = [
    {
      caseStatusNameLatest: "قيد المراجعة",
      caseStatusNameLatestEn: "Pending",
      colorLatest: "#CCDBFA",
    },

    {
      caseStatusNameLatest: "مطلوب بيانات اضافية",
      caseStatusNameLatestEn: "Need More Data",
      colorLatest: "#EAD1F9",
    },

    {
      caseStatusNameLatest: "قيد السداد",
      caseStatusNameLatestEn: "Pending for Payment",
      colorLatest: "#F9E5CF",
    },

    {
      caseStatusNameLatest: "قيد التنفيذ",
      caseStatusNameLatestEn: "In Progress",
      colorLatest: "#ECEDD0",
    },

    {
      caseStatusNameLatest: "منتهية",
      caseStatusNameLatestEn: "Ended",
      colorLatest: "#C8E5DD",
    },

    {
      caseStatusNameLatest: "يتعذر التنفيذ",
      caseStatusNameLatestEn: "Unable to Progress",
      colorLatest: "#D4D2CE",
    },

    {
      caseStatusNameLatest: "وقوف مؤقت",
      caseStatusNameLatestEn: "Partially Stopped",
      colorLatest: "#FFB9AD",
    },
  ];

  const barData = [
    [22, 2, 40, 4, 25],
    [22, 2, 40, 4, 25],
    [22, 2, 40, 4, 25],
    [22, 2, 40, 4, 25],
    [22, 2, 40, 4, 25],
    [22, 2, 40, 4, 25],
    [22, 2, 40, 4, 25],
    [22, 2, 40, 4, 32],
    [22, 2, 40, 4, 25],
  ];

  // const categories = [
  //   {
  //     id: 0,
  //     name: "الاعلانات",
  //     count: 27,
  //     statuses: [
  //       {
  //         id: 1,
  //         name: "قيد المراجعة",
  //         count: 20,
  //       },
  //       {
  //         id: 2,
  //         name: "مطلوب بيانات اضافية",
  //         count: 1,
  //       },
  //       {
  //         id: 3,
  //         name: "قيد السداد",
  //         count: 1,
  //       },
  //       {
  //         id: 4,
  //         name: "قيد التنفيذ",
  //         count: 2,
  //       },
  //       {
  //         id: 5,
  //         name: "منتهية",
  //         count: 1,
  //       },
  //       {
  //         id: 6,
  //         name: "يتعذر التنفيذ",
  //         count: 1,
  //       },
  //       {
  //         id: 7,
  //         name: "وقوف مؤقت",
  //         count: 1,
  //       },
  //     ],
  //   },

  //   {
  //     id: 0,
  //     name: "الاعلانات",
  //     count: 17,
  //     statuses: [
  //       {
  //         id: 1,
  //         name: "قيد المراجعة",
  //         count: 10,
  //       },
  //       {
  //         id: 2,
  //         name: "مطلوب بيانات اضافية",
  //         count: 1,
  //       },
  //       {
  //         id: 3,
  //         name: "قيد السداد",
  //         count: 5,
  //       },
  //       {
  //         id: 4,
  //         name: "قيد التنفيذ",
  //         count: 1,
  //       },
  //       {
  //         id: 5,
  //         name: "منتهية",
  //         count: 0,
  //       },
  //       {
  //         id: 6,
  //         name: "يتعذر التنفيذ",
  //         count: 0,
  //       },
  //       {
  //         id: 7,
  //         name: "وقوف مؤقت",
  //         count: 0,
  //       },
  //     ],
  //   },

  //   {
  //     id: 0,
  //     name: "الاعلانات",
  //     count: 37,
  //     statuses: [
  //       {
  //         id: 1,
  //         name: "قيد المراجعة",
  //         count: 20,
  //       },
  //       {
  //         id: 2,
  //         name: "مطلوب بيانات اضافية",
  //         count: 1,
  //       },
  //       {
  //         id: 3,
  //         name: "قيد السداد",
  //         count: 6,
  //       },
  //       {
  //         id: 4,
  //         name: "قيد التنفيذ",
  //         count: 2,
  //       },
  //       {
  //         id: 5,
  //         name: "منتهية",
  //         count: 1,
  //       },
  //       {
  //         id: 6,
  //         name: "يتعذر التنفيذ",
  //         count: 1,
  //       },
  //       {
  //         id: 7,
  //         name: "وقوف مؤقت",
  //         count: 6,
  //       },
  //     ],
  //   },

  //   {
  //     id: 0,
  //     name: "الاعلانات",
  //     count: 27,
  //     statuses: [
  //       {
  //         id: 1,
  //         name: "قيد المراجعة",
  //         count: 20,
  //       },
  //       {
  //         id: 2,
  //         name: "مطلوب بيانات اضافية",
  //         count: 1,
  //       },
  //       {
  //         id: 3,
  //         name: "قيد السداد",
  //         count: 1,
  //       },
  //       {
  //         id: 4,
  //         name: "قيد التنفيذ",
  //         count: 2,
  //       },
  //       {
  //         id: 5,
  //         name: "منتهية",
  //         count: 1,
  //       },
  //       {
  //         id: 6,
  //         name: "يتعذر التنفيذ",
  //         count: 1,
  //       },
  //       {
  //         id: 7,
  //         name: "وقوف مؤقت",
  //         count: 1,
  //       },
  //     ],
  //   },

  //   {
  //     id: 0,
  //     name: "الاعلانات",
  //     count: 27,
  //     statuses: [
  //       {
  //         id: 1,
  //         name: "قيد المراجعة",
  //         count: 20,
  //       },
  //       {
  //         id: 2,
  //         name: "مطلوب بيانات اضافية",
  //         count: 1,
  //       },
  //       {
  //         id: 3,
  //         name: "قيد السداد",
  //         count: 1,
  //       },
  //       {
  //         id: 4,
  //         name: "قيد التنفيذ",
  //         count: 2,
  //       },
  //       {
  //         id: 5,
  //         name: "منتهية",
  //         count: 1,
  //       },
  //       {
  //         id: 6,
  //         name: "يتعذر التنفيذ",
  //         count: 1,
  //       },
  //       {
  //         id: 7,
  //         name: "وقوف مؤقت",
  //         count: 1,
  //       },
  //     ],
  //   },
  // ];

  // console.log("dataa", servicesDetails?.serviceStatusCounts);
  // console.log("colorPaletteLatest", colorPaletteLatest);

  function roundToNearestUP(number) {
    if (number > 10000) {
      return Math.ceil(number / 10000) * 10000;
    } else if (number > 1000) {
      return Math.ceil(number / 1000) * 1000;
    } else if (number > 100) {
      return Math.ceil(number / 100) * 100;
    } else return Math.floor(number) * 10;
  }

  function roundToNearestDown(number) {
    if (number > 10000) {
      return Math.floor(number / 10000) * 10000;
    } else if (number > 1000) {
      return Math.floor(number / 1000) * 1000;
    } else if (number > 100) {
      return Math.floor(number / 100) * 100;
    } else return Math.floor(number) * 10;
  }
  // console.log("roundToNearest10", roundToNearestUP(20));
  // console.log("roundToNearestDown", roundToNearestDown(10));

  const statusChartBarHAndler = (id) => {
    setIsShown(id);
    // console.log("indexindex", id + 1, id.id, isShown);
    setTitle(id?.name);
    setTotalCount(id?.count);
    setSub(id?.statuses);
  };

  console.log("servicesDetails", servicesDetails);

  return (
    <div>
      {window && window.innerWidth <= 1024 ? (
        <div className="w-full">
          {servicesDetails?.servicesChart[0].categories.map((item) => (
            <div className="mb-[15px]">
              <div className="flex items-center justify-between w-full">
                <p className="text-[12px] font-Almarai text-[#000] h-[13px] flex items-center gap-[2px]">
                  {item?.name}({item?.count})
                </p>
                <div className="flex items-center gap-[12px] w-full justify-end h-[16px] !w-fit">
                  {item.statuses.map((count, index) => (
                    <div className="flex items-center gap-[4px]">
                      <div
                        className="rounded-[50%]  w-[6px] h-[6px]"
                        style={{
                          background: `${
                            count.id === 1
                              ? "#CCDBFA"
                              : count.id === 2
                              ? "#EAD1F9"
                              : count.id === 3
                              ? "#F9E5CF"
                              : count.id === 4
                              ? "#ECEDD0"
                              : count.id === 5
                              ? "#C8E5DD"
                              : count.id === 6
                              ? "#D4D2CE"
                              : count.id === 7
                              ? "#FFB9AD"
                              : ""
                          }`,
                        }}
                      ></div>
                      <span className="text-[10px] font-Almarai">
                        {count.count}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              {isSubChart ? (
                <div className="flex gap-[1px] items-center justify-end mt-[8px]">
                  {item.statuses.map((itemColor, index) => (
                    <div>
                      <div
                        className={`rounded-[3px] h-[10px] bg-blue-500 mt-[6px]`}
                        style={{
                          background: `${
                            itemColor.id === 1
                              ? "#CCDBFA"
                              : itemColor.id === 2
                              ? "#EAD1F9"
                              : itemColor.id === 3
                              ? "#F9E5CF"
                              : itemColor.id === 4
                              ? "#ECEDD0"
                              : itemColor.id === 5
                              ? "#C8E5DD"
                              : itemColor.id === 6
                              ? "#D4D2CE"
                              : itemColor.id === 7
                              ? "#FFB9AD"
                              : ""
                          }`,
                          width: `${
                            servicesDetails?.services?.length < 50
                              ? itemColor.count === 0
                                ? "0px"
                                : `${itemColor.count * 3.5}px`
                              : itemColor.count === 0
                              ? "0px"
                              : `${
                                  (itemColor.count /
                                    (roundToNearestDown(
                                      servicesDetails?.services?.length
                                    ) /
                                      5)) *
                                  3.5
                                }px`
                          }`,
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex gap-[1px] items-center justify-end mt-[8px]">
                  {item.statuses.map((itemColor, index) => (
                    <div>
                      <div
                        className={`rounded-[3px] h-[10px] bg-blue-500 mt-[6px]`}
                        style={{
                          background: `${
                            itemColor.id === 1
                              ? "#CCDBFA"
                              : itemColor.id === 2
                              ? "#EAD1F9"
                              : itemColor.id === 3
                              ? "#F9E5CF"
                              : itemColor.id === 4
                              ? "#ECEDD0"
                              : itemColor.id === 5
                              ? "#C8E5DD"
                              : itemColor.id === 6
                              ? "#D4D2CE"
                              : itemColor.id === 7
                              ? "#FFB9AD"
                              : ""
                          }`,
                          width: `${
                            servicesDetails?.service?.length < 50
                              ? itemColor.count === 0
                                ? "0px"
                                : `${itemColor.count * 3.5}px`
                              : itemColor.count === 0
                              ? "0px"
                              : itemColor.count < 10
                              ? `${
                                  (itemColor.count /
                                    (roundToNearestDown(
                                      servicesDetails?.service?.length
                                    ) /
                                      5)) *
                                  35.5
                                }px`
                              : `${
                                  (itemColor.count /
                                    (roundToNearestDown(
                                      servicesDetails?.service?.length
                                    ) /
                                      5)) *
                                  3.5
                                }px`
                          }`,
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          <div className="flex items-start gap-[8px] mt-6 justify-center w-full flex-wrap">
            {colorPaletteLatest.map((item) => (
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <span
                  className="w-[9px] h-[9px] rotate-45"
                  style={{ background: item.colorLatest }}
                ></span>
                <p className="text-[12px] md:text-[10px] font-bold ">
                  {lang === "en"
                    ? item.caseStatusNameLatestEn
                    : item.caseStatusNameLatest}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          {isSubChart ? (
            <div
              className={`w-full min-w-400 ${
                isSubChart ? "px-[24px]" : ""
              } bg-white relative h-${(maxPercentage / 20) * 30 + 30}`}
            >
              {[5, 4, 3, 2, 1, 0].map((item) => (
                <div className="flex items-end gap-3 h-[42px]">
                  {servicesDetails?.services?.length < 5 ? (
                    <span className="w-5 leading-4  text-xs">{item}</span>
                  ) : (
                    <span className="w-5 leading-4  text-xs">
                      {item *
                        roundToNearestUP(servicesDetails?.services?.length / 5)}
                    </span>
                  )}

                  <div className="bg-transparent	h-[30px] w-full border-[#EEE2D0] border-b-2"></div>
                </div>
              ))}
              <div className="absolute top-0 right-2 left-2 w-full h-full flex items-end">
                {servicesDetails?.servicesChart[0].categories.map(
                  (item, index) => (
                    <div
                      className={`flex flex-col items-end md:items-center`}
                      style={{
                        width: `${
                          95 /
                          servicesDetails?.servicesChart[0].categories.length
                        }%`,
                      }}
                      onMouseEnter={(id) => {
                        statusChartBarHAndler(item);
                      }}
                      onMouseLeave={() => setIsShown(false)}
                    >
                      <div
                        className={`absolute  top-[-25px]   border-[1px] border-solid border-[#eee2d0] rounded-[8px] ${
                          isShown === item ? "flex" : "hidden"
                        } flex-col w-[235px] bg-[#fff] px-[16px] pt-[8px] py-[12px] shadow-[0_4px_16px_0_rgba(0,0,0,0.3)]`}
                      >
                        <div className="flex items-center justify-between gap-[28px]">
                          <p className="text-[12px] text-[#010202] font-Almarai">
                            {title}
                          </p>
                          <p className="text-[12px] text-[#010202] font-Almarai">
                            {totalCount}
                          </p>
                        </div>
                        <div className="w-[185px] h-[1px] bg-[#eee2d0] mt-[7.5px] mb-[11.5px]"></div>
                        <div>
                          {sub?.map((status) => (
                            <div className="flex items-center justify-between gap-[30px] mb-[12px]">
                              <div className="flex items-center justify-center gap-[8px]">
                                <div
                                  className={`h-[14px] w-[4px] ${
                                    status.id === 1
                                      ? "bg-[#9DDFFD]"
                                      : status.id === 2
                                      ? "bg-[#FF6064]"
                                      : status.id === 3
                                      ? "bg-[#C3BCB2]"
                                      : status.id === 4
                                      ? "bg-[#FFC670]"
                                      : status.id === 5
                                      ? "bg-[#67B8A1]"
                                      : status.id === 6
                                      ? "bg-[#EDEAE7]"
                                      : status.id === 7
                                      ? "bg-[#D23419]"
                                      : ""
                                  }`}
                                ></div>
                                <p className="text-[12px] text-[#010202] font-Almarai font-bold">
                                  {status?.name}
                                </p>
                              </div>
                              <div className="flex items-center justify-center gap-[3px]">
                                <p className="text-[12px] text-[#010202] font-Almarai font-bold">
                                  {status?.count}
                                </p>
                                <p className="text-[12px] text-[#949494] font-Almarai">
                                  {lang === "en" ? "Case" : "قضية"}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {item.statuses.map((itemColor, index) => (
                        <div
                          className={`rounded-sm  w-2 md:w-4`}
                          style={{
                            background: `${
                              itemColor.id === 1
                                ? "#CCDBFA"
                                : itemColor.id === 2
                                ? "#EAD1F9"
                                : itemColor.id === 3
                                ? "#F9E5CF"
                                : itemColor.id === 4
                                ? "#ECEDD0"
                                : itemColor.id === 5
                                ? "#C8E5DD"
                                : itemColor.id === 6
                                ? "#D4D2CE"
                                : itemColor.id === 7
                                ? "#FFB9AD"
                                : ""
                            }`,
                            height: `${
                              itemColor.count === 0
                                ? "0px"
                                : servicesDetails?.services?.length < 5
                                ? `${
                                    (itemColor.count * 42) /
                                    roundToNearestUP(
                                      servicesDetails?.services?.length
                                    )
                                  }px`
                                : `${
                                    (itemColor.count * 42) /
                                    roundToNearestUP(
                                      servicesDetails?.services?.length / 5
                                    )
                                  }px`
                            }`,
                          }}
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            <div
              className={`w-full min-w-400 ${
                isSubChart ? "px-[24px]" : ""
              } bg-white relative h-${(maxPercentage / 20) * 30 + 30}`}
            >
              {[5, 4, 3, 2, 1, 0].map((item) => (
                <div className="flex items-end gap-3 h-[42px]">
                  {servicesDetails?.service?.length < 5 ? (
                    <span className="w-5 leading-4  text-xs">{item}</span>
                  ) : (
                    <span className="w-5 leading-4  text-xs">
                      {item *
                        roundToNearestUP(servicesDetails?.service?.length / 5)}
                    </span>
                  )}

                  <div className="bg-transparent	h-[30px] w-full border-[#EEE2D0] border-b-2"></div>
                </div>
              ))}
              <div className="absolute top-0 right-2 left-2 w-full h-full flex items-end">
                {servicesDetails?.servicesChart[0].categories.map(
                  (item, index) => (
                    <div
                      className={`flex flex-col items-end md:items-center`}
                      style={{
                        width: `${
                          95 /
                          servicesDetails?.servicesChart[0].categories.length
                        }%`,
                      }}
                      onMouseEnter={(id) => {
                        statusChartBarHAndler(item);
                      }}
                      onMouseLeave={() => setIsShown(false)}
                    >
                      <div
                        className={`absolute  top-[-25px]   border-[1px] border-solid border-[#eee2d0] rounded-[8px] ${
                          isShown === item ? "flex" : "hidden"
                        } flex-col w-[235px] bg-[#fff] px-[16px] pt-[8px] py-[12px] shadow-[0_4px_16px_0_rgba(0,0,0,0.3)]`}
                      >
                        <div className="flex items-center justify-between gap-[28px]">
                          <p className="text-[12px] text-[#010202] font-Almarai">
                            {title}
                          </p>
                          <p className="text-[12px] text-[#010202] font-Almarai">
                            {totalCount}
                          </p>
                        </div>
                        <div className="w-[185px] h-[1px] bg-[#eee2d0] mt-[7.5px] mb-[11.5px]"></div>
                        <div>
                          {sub?.map((status) => (
                            <div className="flex items-center justify-between gap-[30px] mb-[12px]">
                              <div className="flex items-center justify-center gap-[8px]">
                                <div
                                  className={`h-[14px] w-[4px] ${
                                    status.id === 1
                                      ? "bg-[#CCDBFA]"
                                      : status.id === 2
                                      ? "bg-[#EAD1F9]"
                                      : status.id === 3
                                      ? "bg-[#F9E5CF]"
                                      : status.id === 4
                                      ? "bg-[#ECEDD0]"
                                      : status.id === 5
                                      ? "bg-[#C8E5DD]"
                                      : status.id === 6
                                      ? "bg-[#D4D2CE]"
                                      : status.id === 7
                                      ? "bg-[#FFB9AD]"
                                      : ""
                                  }`}
                                ></div>
                                <p className="text-[12px] text-[#010202] font-Almarai font-bold">
                                  {status?.name}
                                </p>
                              </div>
                              <div className="flex items-center justify-center gap-[3px]">
                                <p className="text-[12px] text-[#010202] font-Almarai font-bold">
                                  {status?.count}
                                </p>
                                <p className="text-[12px] text-[#949494] font-Almarai">
                                  {lang === "en" ? "Case" : "قضية"}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {item.statuses.map((itemColor, index) => (
                        <div
                          className={`rounded-sm  w-2 md:w-4`}
                          style={{
                            background: `${
                              itemColor.id === 1
                                ? "#CCDBFA"
                                : itemColor.id === 2
                                ? "#EAD1F9"
                                : itemColor.id === 3
                                ? "#F9E5CF"
                                : itemColor.id === 4
                                ? "#ECEDD0"
                                : itemColor.id === 5
                                ? "#C8E5DD"
                                : itemColor.id === 6
                                ? "#D4D2CE"
                                : itemColor.id === 7
                                ? "#FFB9AD"
                                : ""
                            }`,
                            height: `${
                              itemColor.count === 0
                                ? "0px"
                                : servicesDetails?.service?.length < 5
                                ? `${itemColor.count * 42}px`
                                : `${
                                    (itemColor.count * 42) /
                                    roundToNearestUP(
                                      servicesDetails?.service?.length / 5
                                    )
                                  }px`
                            }`,
                          }}
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          <div className="flex items-end   mt-[16px] w-full">
            {servicesDetails?.servicesChart[0].categories.map((ser) => (
              <div
                style={{
                  width: `${
                    95 / servicesDetails?.servicesChart[0].categories.length
                  }%`,
                  maxWidth: `${
                    95 / servicesDetails?.servicesChart[0].categories.length
                  }%`,
                  marginInlineStart: "10px",
                }}
              >
                <p className={`text-[8px] lg:text-xs text-center`}>
                  {ser.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex items-start gap-3 mt-6 justify-center w-full flex-wrap">
            {colorPaletteLatest.map((item) => (
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <span
                  className="w-[12px] h-[12px] rotate-45"
                  style={{ background: item.colorLatest }}
                ></span>
                <p className="text-[8px] md:text-[10px] font-bold ">
                  {lang === "en"
                    ? item.caseStatusNameLatestEn
                    : item.caseStatusNameLatest}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
