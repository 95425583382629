import React, { useState } from "react";
import { toast } from "../../../components/utils";
import { AddSurvey } from "../../../services/Common";

import { useContext } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";
import styles from "./styles.module.css";

const Survey = (props) => {
  const {
    happinessMeter,
    setOpenModal,
    setSurveyShowModal,
    setHappinessMeter,
    surveyDetails,
    answersList,
  } = props;
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [surveyQuestion, setSurveyQuestion] = useState([]);
  const [surveyAnswer, setSurveyAnswer] = useState([]);
  const [surveyCategoryId, setSurveyCategoryId] = useState([]);

  const [surveyNote, setSurveyNote] = useState("");

  const getMeterTitle = (id) => {
    let MeterTitle = "";
    switch (id) {
      case 1:
        MeterTitle =
          lang === "en" ? enPhrase["lblSatisfied"] : arPhrase["lblSatisfied"];
        break;
      case 2:
        MeterTitle = lang === "en" ? enPhrase["lblAcceptable"] : arPhrase["lblAcceptable"];
        break;
      case 3:
        MeterTitle = lang === "en" ? enPhrase["lblNotSatisfied"] : arPhrase["lblNotSatisfied"];
        break;
      default:
        MeterTitle = "";
    }

    return MeterTitle;
  };

  const getMeterImg = (id) => {
    let MeterImg = "";
    switch (id) {
      case 1:
        MeterImg = "/assets/img/happiness/satisfied.svg";
        break;
      case 2:
        MeterImg = "/assets/img/happiness/acceptable.svg";
        break;
      case 3:
        MeterImg = "/assets/img/happiness/notSatisfied.svg";
        break;
      default:
        MeterImg = "";
    }

    return MeterImg;
  };

  const getAnswerImg = (answerID) => {
    let MeterImg = "";
    switch (answerID) {
      case 1:
        MeterImg = "/assets/img/happiness/satisfiedNotActive.svg";
        break;
      case 2:
        MeterImg = "/assets/img/happiness/acceptableNotActive.svg";
        break;
      case 3:
        MeterImg = "/assets/img/happiness/notSatisfiedNotActive.svg";
        break;
      default:
        MeterImg = "";
    }

    return MeterImg;
  };

  const getActiveClassImg = (answerID, questionID, categoryID) => {
    let ActiveClassImg = false;

    if (
      surveyQuestion.indexOf(questionID) !== -1 &&
      surveyAnswer[surveyQuestion.indexOf(questionID)] === answerID
    ) {
      ActiveClassImg = true;
    }

    return ActiveClassImg;
  };

  const getActiveAnswerImg = (answerID, questionID, categoryID) => {
    let MeterImg = "";
    switch (answerID) {
      case 1:
        MeterImg =
          surveyQuestion.indexOf(questionID) !== -1 &&
          surveyAnswer[surveyQuestion.indexOf(questionID)] === answerID
            ? "/assets/img/happiness/satisfiedActive.svg"
            : "/assets/img/happiness/satisfiedNotActive.svg";
        break;
      case 2:
        MeterImg =
          surveyQuestion.indexOf(questionID) !== -1 &&
          surveyAnswer[surveyQuestion.indexOf(questionID)] === answerID
            ? "/assets/img/happiness/acceptableActive.svg"
            : "/assets/img/happiness/acceptableNotActive.svg";
        break;
      case 3:
        MeterImg =
          surveyQuestion.indexOf(questionID) !== -1 &&
          surveyAnswer[surveyQuestion.indexOf(questionID)] === answerID
            ? "/assets/img/happiness/notSatisfiedActive.svg"
            : "/assets/img/happiness/notSatisfiedNotActive.svg";
        break;
      default:
        MeterImg = "";
    }

    return MeterImg;
  };

  const BackHandlerClick = () => {
    setHappinessMeter(null);
    setSurveyShowModal(false);
    setOpenModal(true);
  };

  const AnswerHandlerClick = (answerID, questionID, categoryID) => {
    if (surveyQuestion.indexOf(questionID) === -1) {
      setSurveyQuestion([...surveyQuestion, questionID]);
      setSurveyAnswer([...surveyAnswer, answerID]);
      setSurveyCategoryId([...surveyCategoryId, categoryID]);
    } else {
      let arrA = [...surveyAnswer];
      let arrC = [...surveyCategoryId];

      arrA[surveyQuestion.indexOf(questionID)] = answerID;
      arrC[surveyQuestion.indexOf(questionID)] = categoryID;

      setSurveyAnswer(arrA);
      setSurveyCategoryId(arrC);
    }
  };

  const surveyAction = () => {
    if (surveyNote !== "" && surveyQuestion?.length > 0) {
      let surveyQuestionsNow = [];
      surveyQuestion.map((item, index) =>
        surveyQuestionsNow.push({
          questionId: item,
          categoryId: surveyCategoryId[index],
          answerId: surveyAnswer[index],
        })
      );

      let surveySchema = {
        surveyAnswer: happinessMeter,
        surveyNote: surveyNote,
        surveyQuestion: surveyQuestionsNow,
      };

      AddSurvey(surveySchema).then(
        (res) => {
          setHappinessMeter(null);
          setSurveyShowModal(false);
          setOpenModal(false);
        },
        (err) => {
          const title = "error title";
          const success = "error message";
          const ok = "ok";
          toast(title, success, ok, "error");
        }
      );

      //   console.log("surveySchema", surveySchema);
    }
  };

  return (
    <>
      <div className={styles.surveyWrapper}>
        <div className={styles.surveyHead}>
          <div className={styles.surveyHeadLogo}>
            <img src="/assets/img/happiness/logo.svg" alt="logo" />
          </div>
          <div className={styles.surveyHeadTitle}>
            <h2>
            {lang === "en" ? enPhrase["lblHappiness"] : arPhrase["lblHappiness"]}
            </h2>
            <p>{"Happiness Meter"}</p>
          </div>
        </div>
        <div className={styles.surveySubHead}>
          <div className={styles.surveySubHeadTitle}>
            <h2>
           { lang === "en" ? enPhrase["lblTellImproveServices"] : arPhrase["lblTellImproveServices"]}
            </h2>
          </div>
          <div className={styles.surveySubHeadSelected}>
            <div className={styles.surveyHappinessMeter}>
              <img
                src={getMeterImg(happinessMeter)}
                alt={getMeterTitle(happinessMeter)}
              />
              <p>{getMeterTitle(happinessMeter)}</p>
            </div>
            <div
              onClick={() => BackHandlerClick()}
              className={styles.surveyHappinessMeterBack}
            >
              <img src="/assets/img/happiness/arrow.svg" alt="arrow" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.surveyDetailsWrapper}>
        {surveyDetails?.map((item) => {
          return (
            <div key={item?.categoryId} className={styles.surveyDetails}>
              <div className={styles.surveyDetailsHead}>
                <h3>{item?.categoryName}</h3>
              </div>
              <div className={styles.questionsWrapper}>
                {item?.questions?.map((itemQ) => {
                  return (
                    <div key={itemQ?.id} className={styles.questionWrapper}>
                      <div className={styles.questionTitle}>
                        <p>{itemQ?.question}</p>
                      </div>
                      <div className={styles.answersWrapper}>
                        {answersList?.map((itemA) => {
                          return (
                            <div
                              key={itemA?.id}
                              className={
                                getActiveClassImg(
                                  itemA?.id,
                                  itemQ?.id,
                                  item?.categoryId
                                )
                                  ? styles.answerActiveWrappe
                                  : styles.answerWrapper
                              }
                              onClick={(answerID, questionID, categoryID) =>
                                AnswerHandlerClick(
                                  itemA?.id,
                                  itemQ?.id,
                                  item?.categoryId
                                )
                              }
                            >
                              <img
                                src={
                                  surveyQuestion.indexOf(itemQ?.id) !== -1 &&
                                  surveyAnswer.indexOf(itemA?.id) !== -1
                                    ? getActiveAnswerImg(
                                        itemA?.id,
                                        itemQ?.id,
                                        item?.categoryId
                                      )
                                    : getAnswerImg(itemA?.id)
                                }
                                alt={itemA?.answer}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.surveyMessageWrapper}>
        <div className={styles.surveyMessageHead}>
          <h2>{"اخبرنا المزيد"}</h2>
        </div>
        <div className={styles.surveyMessage}>
          <textarea
            className={`mt-2 block w-full rounded-md h-[96px] shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm`}
            type="text"
            name="message"
            placeholder={"اكتب رسالتك هنا …."}
            value={surveyNote}
            onChange={(event) => {
              setSurveyNote(event.target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.surveyButtonWrapper}>
        <div onClick={() => surveyAction()} className={styles.surveyButton}>
          <p>{lang === "en" ? enPhrase["btnSend"] : arPhrase["btnSend"]}</p>
        </div>
      </div>
    </>
  );
};

export default Survey;
