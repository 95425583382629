// import { useRouter } from "next/router";
import React, { createContext, useEffect, useState } from "react";
// import ListPhrases from "@/network/api/phrases";

import { getPhrases } from "../services/Common";

const PhrasesContext = createContext([{}, () => {}]);

const PhrasesProvider = ({ children }) => {
  const [enPhrase, setEnPhrase] = useState({});
  const [arPhrase, setArPhrase] = useState({});
  const [phrasesLoading, setPhrasesLoading] = useState(true);
  

  useEffect(() => {
    fetchListPhrasesFromAPI();
  }, []);

  const fetchListPhrasesFromAPI = () => {
    setPhrasesLoading(true);

    getPhrases()
      .then((res) => {
        
        if (res.status === 200) {
          const en_api = {};
          const ar_api = {};
          res.data.map((phrase) => {
            en_api[phrase.phraseKey] = phrase.phraseEn;
            ar_api[phrase.phraseKey] = phrase.phraseAr;
            return "";
          });
          setEnPhrase(en_api);
          setArPhrase(ar_api);
        }
        setPhrasesLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <PhrasesContext.Provider value={[enPhrase, arPhrase, phrasesLoading]}>
      {!phrasesLoading && children}
    </PhrasesContext.Provider>
  );
};

export { PhrasesContext, PhrasesProvider };

