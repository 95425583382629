import React, { useContext, useEffect, useState } from 'react';
import { Container } from '../components/utils';
import { PhrasesContext } from '../context/phrasesContext';
import FooterLayout from '../layouts/FooterLayout';
import Nav from '../layouts/Nav';

const TermsAndConditions = () => {
  const [lang, setLang] = useState("");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});

  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  useEffect(() => {
    const lang = window.localStorage.getItem('lang');
    if (lang === 'en') {
      document.body.dir = 'ltr';
      setLang('en');
    } else {
      document.body.dir = 'rtl';
      setLang('ar');
    }
    // getPhrasesService();
  }, []);

  const changeLanguage2 = (lang) => {
    if (lang === 'en') {
      document.body.dir = 'rtl';
      setLang('ar');
      window.localStorage.setItem('lang', 'ar');
    } else {
      document.body.dir = 'ltr';
      setLang('en');
      window.localStorage.setItem('lang', 'en');
    }
    window.location.reload();
  };
  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return '';
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };
  return (
    <div className='w-full'>
      <Nav
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        changeLanguage2={changeLanguage2}
      />
      <section
        id='faq'
        className='w-full overflow-hidden bg-gold-10 border-t border-b wow fadeInUp '>
        <Container>
          <div className='flex flex-col items-center justify-center pb-16 text-justify'>
            <div className='flex flex-col items-center justify-center my-5'>
              <h3 className='text-3xl lg:text-4xl text-black leading-snug font-semibold'>
                {lang === 'en'
                  ? enPhrase['lbl_terms_condtions']
                  : arPhrase['lbl_terms_condtions']}
              </h3>
            </div>
            <div className='items-center w-full bg-white'>
              {lang === 'en' && (
                <div className='w-full p-10 terms'>
                  <div className='GlobCount'>
                    <span>
                      By using our services, you hereby consent to the following
                      Terms of Service.
                    </span>
                    <p></p>
                    <p>
                      These terms constitute a binding agreement. Please read
                      this Agreement carefully before you use EJE. By using our
                      site and/or mobile application you agree to be bound by
                      everything in this Agreement and to the collection and use
                      of the information set forth in the EJE Privacy Policy, if
                      you are a registered user of our Services. If you do not
                      agree, please do not use EJE.
                    </p>

                    <h4> 1- Contractual Relationship</h4>
                    <p>
                      This is a contract (“Agreement”) between you and EJE. EJE
                      is a company and it is establishment in Dubai, applicable
                      when you use or access the site, services and mobile
                      application by EJE, in existence now or in the future
                      (“EJE Services,” “EJE,” or “Services”). In this Agreement,
                      the words “including” and “include” mean “including, but
                      not limited to.” Your access and use of the Services
                      constitute your consent to be bound by this Agreement,
                      which establishes a contractual relationship between you
                      and EJE. If you do not agree to any of the terms of this
                      Agreement, you may not access or use the Services. This
                      Agreement expressly supersedes any prior agreements or
                      arrangements with you. EJE may immediately terminate these
                      Terms or any Services with respect to you, or generally
                      cease offering or deny access to the Services or any
                      portion thereof, at any time for any reason. Supplemental
                      Terms may apply to certain Services, such as policies for
                      a particular event, activity or promotion, and such
                      supplemental terms will be disclosed to you in connection
                      with the applicable Services (“Supplemental Terms”).
                      Supplemental Terms are in addition to, and shall be deemed
                      a part of, the Agreement for the purposes of the
                      applicable Services. Supplemental Terms shall prevail over
                      this Agreement in the event of a conflict with respect to
                      the applicable Services. EJE may amend the terms related
                      to the Services from time to time. Amendments will become
                      effective upon the publication of an amended Agreement.
                      From time to time, the Agreement may change. If/When this
                      occurs, EJE will notify you by revising the date at the
                      top of the Agreement, and/or by providing you with an
                      additional notice. Your continued access or use of the
                      Services after such posting or notification constitutes
                      your consent to be bound by the amended Agreement. If you
                      do not agree with the amended Agreement, please stop using
                      the Services as the amended Agreement will apply starting
                      the date of posting or notification of the amendment.
                    </p>

                    <h4>2- THE SERVICES</h4>
                    <p>
                      You must upload the EJE app on your smart device, follow
                      the steps instructions and provide all required
                      information in order to create your account to allow EJE
                      to provide you Services. You must be 18 years of age or
                      older and provide a certain amount of personal information
                      to create an account. You hereby allow EJE to collect your
                      personal information and your precise geolocation. EJE may
                      send you informational and marketing text messages. You
                      own your personal information and User Content, permitting
                      EJE to use your User Content. We own our company and
                      intellectual property information. You alone are
                      responsible for keeping your account secure. You may not
                      have more than one account. The Services constitute a
                      technology platform that enables users of EJE’s
                      applications or websites (each, an “Application”) to
                      arrange and schedule logistics services, including
                      delivery of fuel and other products and services. Unless
                      otherwise agreed by EJE in a separate written agreement
                      with you, the Services are made available solely for your
                      personal and noncommercial use.
                    </p>

                    <h4>3-User Eligibility &amp; Accounts</h4>
                    <p>
                      In order to use most aspects of the Services, you must
                      register for and maintain an active personal user Services
                      account (“Account”). You must be at least 18 years of age
                      to obtain an Account. You must upload the EJE app on your
                      smart device, follow the steps instructions and provide
                      all required information in order to create your account
                      to allow EJE to provide you Services. Account registration
                      requires you to submit to EJE certain personal
                      information, such as your name, address, mobile phone
                      number, password, vehicle year, make, license plate, and
                      model, as well as at least one valid payment method
                      (either a credit card or accepted payment partner). You
                      agree to maintain accurate, complete, and up-to-date
                      information in your Account. Your failure to maintain
                      accurate, complete, and up-to-date Account information,
                      including having an invalid or expired payment method on
                      file, may result in your inability to access and use the
                      Services or EJE’s termination of this Agreement with you.
                      You are responsible for all activity that occurs under
                      your Account, and you agree to maintain the security and
                      secrecy of your Account username and password at{' '}
                    </p>

                    <h4>4- Geolocation</h4>
                    <p>
                      You agree to allow EJE to collect your precise
                      geolocation. We collect this geolocation for the purpose
                      of improving your user experience - such as reminding you
                      to request a fill. You may disable your geolocation but be
                      advised this may impact Services from EJE to you. We will
                      not disclose your geolocation to any third parties without
                      your consent.
                    </p>

                    <h4>5- Network Access &amp; Devices</h4>
                    <p>
                      You are responsible for obtaining the data network access
                      necessary to use the Services. Your mobile network’s data
                      and messaging rates and fees may apply if you access or
                      use the Services from a wireless-enabled device. You are
                      responsible for acquiring and updating compatible hardware
                      or devices necessary to access and use the Services and
                      Applications and any updates thereto. EJE does not
                      guarantee that the Services, or any portion thereof, will
                      function on any particular hardware or devices. In
                      addition, the Services may be subject to malfunctions and
                      delays inherent in the use of the Internet and electronic
                      communications.
                    </p>

                    <h4>6- License</h4>
                    <p>
                      Subject to your compliance with this Agreement, EJE grants
                      you a limited, non-exclusive, non-sublicensable,
                      revocable, non-transferrable license to: access and use
                      the Applications on your personal device solely in
                      connection with your use of the Services; and access and
                      use any content, information and related materials that
                      may be made available through the Services, in each case
                      solely for your personal and non-commercial use. Any
                      rights not expressly granted herein are hereby reserved by
                      EJE and EJE’s licensors.
                    </p>

                    <h4>7- Prohibited Activities</h4>
                    <p>You may not:</p>
                    <ul>
                      <li>Have more than one active Account.</li>
                      <li>
                        Remove any copyright, trademark or other proprietary
                        notices from any portion of the Services.
                      </li>
                      <li>
                        Link to, mirror or frame any portion of the Services.
                      </li>
                      <li>
                        or attempt to gain unauthorized access to or impair any
                        aspect of the Services or its related systems or
                        networks.
                      </li>
                    </ul>

                    <h4>8- Third Party Services &amp; Content</h4>
                    <p>
                      The Services may be made available or accessed in
                      connection with third party services and content
                      (including advertising) that EJE does not control. You
                      acknowledge that different terms of use and privacy
                      policies may apply to your use of such third-party
                      services and content. EJE does not endorse such
                      third-party services and content, and in no event shall
                      EJE be responsible or liable for any products or services
                      of such third-party providers. Additionally, Apple Inc.,
                      Google, Inc., Microsoft Corporation or BlackBerry Limited
                      will be a third-party beneficiary to this contract if you
                      access the Services using Applications developed for Apple
                      iOS, Android, Microsoft Windows, or Blackberry-powered
                      mobile devices, respectively. These third-party
                      beneficiaries are not parties to this contract and are not
                      responsible for the provision or support of the Services
                      in any manner. Your access to the Services using these
                      devices is subject to terms set forth in the applicable
                      third-party beneficiary’s terms of service. You agree to
                      comply with any applicable third-party terms when using
                      the Services.
                    </p>

                    <h4>9- Ownership</h4>
                    <p>
                      The Services and all rights therein are and shall remain
                      EJE’s property or the property of EJE’s licensors. Neither
                      this Agreement nor your use of the Services convey or
                      grant to you any rights:
                    </p>
                    <ul>
                      <li>
                        to use or reference in any manner EJE’s company names,
                        logos, product and service names, trademarks or services
                        marks or those of EJE’s licensors.
                      </li>
                    </ul>

                    <h4>10- Text Messaging</h4>
                    <p>
                      You agree to allow EJE to send you informational text
                      (SMS) messages. We send you messages for the purpose of
                      delivering the service and improving your customer
                      experience - for example, if we need to contact you if we
                      are not able to locate your vehicle. You can opt out of
                      receiving the text messages by replying “STOP” after any
                      text message received, but be advised this may impact
                      Services from EJE to you.
                    </p>

                    <h4>11- PAYMENT</h4>
                    <p>
                      You are responsible for all fees associated with your use
                      of EJE’s Services. We are responsible for communicating
                      those fees to you clearly and accurately. You agree to
                      receive a receipt via email or text or through the EJE App
                      itself. If a modification or cancellation of Services is
                      necessary, EJE will notify you of this. You are under no
                      obligation to tip your driver for Services, though we
                      would appreciate it you provided feedback about your
                      experience.
                    </p>

                    <h4>11-1 Charges</h4>
                    <p>
                      You understand that use of the Services may result in
                      charges to you for the services or goods you receive from
                      EJE (“Charges”). Charges will be inclusive of applicable
                      taxes where required by law. Charges paid by you are final
                      and non-refundable, unless otherwise determined by EJE.
                      All Charges are due immediately and payment will be
                      facilitated by EJE using the preferred payment method
                      designated in your Account. If your primary Account
                      payment method is determined to be expired, invalid, or
                      otherwise not able to be charged.
                    </p>
                    <p>
                      You agree that EJE may use the secondary payment method
                      from your Account, if available. You agree that EJE will
                      send you a receipt by email or text message, and/or
                      through the application. This electronic receipt is
                      sufficient for all purposes, including any specific
                      requirements under applicable law. If you prefer to
                      receive a paper receipt, please contact us on our email
                      within 30 days of each fill, to request a physical receipt
                      which will be mailed to you at our earliest convenience.
                    </p>

                    <h4>11-2 Modifications &amp; Cancellations</h4>
                    <p>
                      EJE, at its sole discretion, reserves the right to
                      establish and amend fees for all services or products
                      obtained through the use of services.
                    </p>

                    <h4>12- DISCLAIMERS</h4>
                    <p>
                      You use the EJE services at your own risk. the services
                      are provided “as is” and “as available.” EJE disclaims all
                      representations and warranties, express, implied, or
                      statutory, not expressly set out in these terms, including
                      the implied warranties of merchantability, fitness for a
                      particular purpose and non-infringement. in addition, EJE
                      makes no representation, warranty, or guarantee regarding
                      the reliability, timeliness, quality, suitability, or
                      availability of the services or any services or goods
                      requested through the use of the services, or that the
                      services will be uninterrupted or error-free. EJE does not
                      guarantee the quality, suitability, safety of fuels
                      provided to you. you agree that the entire risk arising
                      out of your use of the services, and any service or good
                      requested in connection therewith, remains solely with
                      you, to the maximum extent permitted under applicable law.
                    </p>
                    <ul>
                      <li>
                        EJE is not responsible for any damages sustained by the
                        customer’s vehicle outside the scope of insurance.
                      </li>
                      <li>
                        EJE is not responsible for the accuracy of the location
                        provided by the customer.
                      </li>
                      <li>
                        EJE reserves the right to impose delivery charges in
                        case the customer's vehicle is not accessible.
                      </li>
                      <li>
                        In the event of cancellation for a reason related to EJE
                        or its driver, the customer will not bear any fees.
                      </li>
                      <li>
                        In the event of any interference in the driver’s work,
                        EJE reserves the right to file an official complaint in
                        this regard.
                      </li>
                      <li>
                        EJE reserves the right to impose delivery charges in the
                        event that it is not possible to access the customer’s
                        vehicle or does not comply with safety requirements that
                        impede the company's employee’s provision of the service
                        in the best way.
                      </li>
                      <li>
                        EJE does not bear any responsibility for indirect,
                        incidental, special, deterrent or disciplinary damages,
                        including missed gain, loss of data, personal injury, or
                        damage to property in relation to, pertaining or
                        otherwise, resulting from any use of the services until
                        If EJE had been aware of the possibility of such damage.
                      </li>
                      <li>
                        EJE also does not bear any responsibility for delay,
                        failure to perform, any defect, viruses or the like that
                        may be transmitted to or through the services by any
                        external party, user content, or any defamatory or
                        abusive behavior or It is illegal for any other external
                        party.
                      </li>
                    </ul>

                    <h4>13- Indemnification</h4>
                    <p>
                      You are responsible for your use of the Services, and you
                      will indemnify and hold EJE and its officers, directors,
                      employees, consultants, affiliates, subsidiaries, and
                      agents (together, the “EJE Entities”) from and against any
                      and all claims, demands, losses, liabilities, and expenses
                      (including attorneys’ fees), arising out of or in any way
                      connected with:
                    </p>
                    <ul>
                      <li>
                        Your access to, use of, or alleged use of, the Services
                        or services or goods obtained through your use of the
                        Services.
                      </li>
                      <li>
                        Your breach or violation of any of these Terms, any
                        representation, warranty, or agreement referenced in
                        these Terms, or any applicable law or regulation.
                      </li>
                      <li>
                        Your violation of the rights of any third party,
                        including any intellectual property right or publicity,
                        confidentiality, other property, or privacy, right.
                      </li>
                    </ul>

                    <h4>14- Data Privacy and Security</h4>
                    <p>
                      You hereby consent to the collection and/or processing of
                      your personal data by EJE. EJE is committed to protecting
                      the personal data that EJE receives from you, or otherwise
                      processes, in the course of or in connection with the
                      Services. EJE will take commercially reasonable and
                      appropriate technical and organizational measures to
                      protect your personal data against unauthorized access,
                      accidental loss or damage and unauthorized destruction.
                      The security provided by EJE shall be in accordance with
                      good industry practices relating to protection of the
                      types of data typically processed in the Services by EJE
                      customers. You shall act as the data controller in respect
                      of any and all personal data contained in the account. EJE
                      shall act as the data processor in respect of any
                      processing by it of personal data contained within the
                      account on your behalf, and shall process such personal
                      data in accordance with this Agreement. You hereby consent
                      to the processing of your data by EJE. You acknowledge and
                      agree that EJE may in connection with the Services engage
                      third party sub-processors, who may be based in, or may
                      process, access or have access to personal data in or
                      from, jurisdictions outside the UAE. EJE will execute
                      written contracts with such sub-processors which impose on
                      sub-processors data privacy and security terms and
                      obligations materially equivalent to the terms and
                      obligations hereof.
                    </p>

                    <h4>15- Applicable Law and Jurisdiction</h4>
                    <p>
                      The customer and EJE agree to attempt to resolve any
                      dispute or claim arising out of this agreement or related
                      to it in good faith through negotiations between a manager
                      representing each party who has the authority to settle
                      the relevant dispute. In the event that the dispute cannot
                      be resolved amicably within 14 days from the date on which
                      either of the parties sends a written notification to the
                      other party regarding the dispute.
                    </p>
                    <p>
                      These terms and conditions are subject and shall be
                      interpreted in accordance with the provisions of the
                      Emirate of Dubai and the UAE federal laws, as case may be,
                      in the Emirate of Dubai. Any dispute which may arise from
                      or in connection with these terms and conditions shall be
                      referred to Dubai courts which shall have jurisdiction to
                      hear settle any claim or procedure and finalize any such
                      dispute.
                    </p>
                  </div>
                </div>
              )}

              {lang === 'ar' && (
                <div className='w-full p-10 terms'>
                  <div className='GlobCount'>
                    <h2>شروط الاستخدام</h2>

                    <h4>
                      من خلال اشتراكك بخدماتنا، فإنك توافق بموجب هذه الوثيقة على
                      شروط الخدمة التالية:
                    </h4>
                    <p>
                      تشكل هذه الشروط اتفاقية ملزمة قانونية؛ لذلك يرجى قراءة
                      شروط الإتفاقية بعناية قبل الاشتراك في خدمات شركة الإمارات
                      لتنفيذ الاحكام في حال كان العميل مستخدما مسجلاً لخدمات
                      شركة الإمارات لتنفيذ الاحكام ، فإنه ومن خلال استخدام
                      موقعها الإلكتروني أو تطبيقها على الهاتف الجوال، يوافق على
                      الالتزام بالبنود الواردة في هذه الاتفاقية وعلى بنود جمع
                      واستخدام المعلومات الواردة في سياسة الخصوصية الخاصة بشركة
                      الإمارات لتنفيذ الاحكام وإذا لم يوافق العميل على هذه
                      البنود، فيرجى عدم استخدام خدمات شركة الإمارات لتنفيذ
                      الاحكام.
                    </p>

                    <h4>1- العلاقة التعاقدية</h4>
                    <p>
                      تشكل هذه الوثيقة عقداً (اتفاقية) بين العميل وشركة الإمارات
                      لتنفيذ الاحكام وهي شركة مؤسسة فى (حسب الجهة المختصة) في
                      إمارة دبي، الإمارات العربية المتحدة (ويشار إليها فيما يلي
                      بشركة الإمارات لتنفيذ الاحكام). وتنطبق شروط الاتفاقية على
                      استخدام خدماتها عبر موقعها الإلكتروني أو تطبيقها على
                      الهاتف الجوال، سواء المتوفرة حاليا أو في المستقبل (يشار
                      إليها فيما يلي بخدمات شركة الإمارات لتنفيذ الاحكام أو
                      الخدمات). وفي سياق هذه الاتفاقية، فإن كلمة "يتضمن" و"يشمل"
                      تعني بما في ذلك على سبيل المثال لا الحصر". واستخدام العميل
                      للخدمات والدخول إليها يعني موافقته على الالتزام بهذه
                      الاتفاقية، والتي تشكل علاقة تعاقدية بينه وبين شركة
                      الإمارات لدعم الطرق وفي حال عدم موافقة العميل على شروط هذه
                      الاتفاقية، يرجى عدم استخدام الخدمات أو الدخول إليها. وتلغي
                      هذه الاتفاقية صراحة أية اتفاقيات أو ترتيبات سابقة بين
                      العميل وشركة الإمارات لتنفيذ الاحكام ويجوز لشركة الإمارات
                      لتنفيذ الاحكام أن تُلغي فوراً هذه الشروط أو أي من الخدمات
                      المتوفرة للعميل، أو توقف أو ترفض عموماً تقديم الخدمات أو
                      أي جزء منها في أي وقت ولأي سبب كان، ويجوز أن تنطبق الشروط
                      التكميلية على خدمات معينة، مثل السياسات المتعلقة بفعالية
                      أو نشاط أو عرض ترويجي معين، ويتم الإفصاح عن هذه الشروط
                      التكميلية للعميل فيما يتعلق بالخدمات المعنية (الشروط
                      التكميلية). وتشكل الشروط التكميلية جزء لا يتجزأ من
                      الاتفاقية فيما يتعلق بالخدمات المعنية، وتكون السيادة
                      للشروط التكميلية على هذه الاتفاقية في حال وجود تعارض فيما
                      يتعلق بالخدمات المعنية، ويجوز لشركة الإمارات لتنفيذ
                      الاحكام تعديل الشروط المتعلقة بالخدمات من وقت لآخر، وتدخل
                      التعديلات حيز النفاذ عند نشر الاتفاقية المعدلة، كما يجوز
                      تغيير الأتفاقية من وقت لآخر، وفي هذه الحالة تقوم شركة
                      الإمارات لتنفيذ الاحكام بإبلاغ العميل من خلال تعديل
                      التاريخ الوارد في صدر هذه الاتفاقية و/أو بإرسال إخطار
                      للعميل بهذا الصدد. ويشكل الأستخدام المستمر للخدمات بعد هذا
                      الإعلان أو الإخطار موافقة من العميل على الإلتزام
                      بالأتفاقية المعدلة، وفي حال عدم موافقة العميل على
                      الأتفاقية المعدلة، يرجى التوقف عن استخدام الخدمات نظراً
                      لأن الأتفاقية المعدلة ستنطبق ابتداء من تاريخ الإعلان عن
                      التعديل أو الإخطار بشأنه.
                    </p>

                    <h4>2- تقديم الخدمات </h4>
                    <p>
                      ينبغي على العميل تحميل تطبيق (الإمارات لتنفيذ الاحكام) على
                      جهازه الذكي واتباع الخطوات والإرشادات وتقديم كافة
                      المعلومات المطلوبة من أجل إنشاء حساب لكي يتيح لشركة
                      الإمارات لتنفيذ الاحكام تقديم الخدمات للعميل، ويجب ألا يقل
                      عمر العميل عن 18 عاماً وعليه تقديم معلومات شخصية محددة
                      لإنشاء الحساب. ويسمح العميل بموجب هذا لشركة الإمارات
                      لتنفيذ الاحكام بالحصول على معلوماته الشخصية وموقعه
                      الجغرافي المحدد. ويمكن أن ترسل شركة الإمارات لتنفيذ
                      الاحكام للعميل رسائل نصية تتضمن معلومات وعروضا ترويجية.
                      ويكون العميل هو المالك لمعلوماته الشخصية ومحتوى المستخدم
                      مع السماح لشركة الإمارات لدعم الطرق بإستخدام محتوى
                      المستخدم الخاص به، وتكون شركة الإمارات لتنفيذ الاحكام هي
                      المالك لمعلومات الشركة ومعلومات الملكية الفكرية .. ويكون
                      العميل وحده مسؤولا عن الحفاظ على أمن وسلامة حسابه .. كما
                      لا يجوز أن يمتلك العميل أكثر من حساب واحد.
                      <br />
                      وتتألف الخدمات من منصة تكنولوجية تتيح لمستخدمي تطبيق شركة
                      الإمارات لتنفيذ الاحكام أو موقعها الإلكتروني (يشار لكل
                      منهما "بتطبيق (الإمارات لتنفيذ الاحكام)") بترتيب وتنسيق
                      الخدمات اللوجستية بما في ذلك ملئ الوقود والمنتجات والخدمات
                      الأخرى. وما لم توافق شركة الإمارات لتنفيذ الاحكام على خلاف
                      ذلك بموجب اتفاقية خطية منفصلة مع العميل، يتم توفير الخدمات
                      للعميل لغايات الاستخدام الشخصي وغير التجاري فقط.
                    </p>

                    <h4>3- أهمية المستخدم والحسابات </h4>
                    <p>
                      لأغراض الاستفادة من الخدمات على أكمل وجه، يتعين على العميل
                      التسجيل لإنشاء حساب الخدمات (الحساب) الشخصي والحفاظ على
                      استخدامه بشكل نشط. ويجب ألا يقل عمر العميل عن 18 عاما وذلك
                      للحصول على حساب. وينبغي على العميل تحميل تطبيق (الإمارات
                      لتنفيذ الاحكام) على جهازه الذكي واتباع الخطوات والإرشادات
                      وتقديم كافة المعلومات المطلوبة من أجل إنشاء حساب يتيح
                      لشركة الإمارات لتنفيذ الاحكام تقديم الخدمات للعميل. ويتطلب
                      تسجيل الحساب من العميل تزويد شركة الإمارات لتنفيذ الاحكام
                      بمعلومات شخصية محددة مثل الأسم والعنوان ورقم الهاتف ورقم
                      جواز السفر وسنة صنع المركبة ورقم لوحة الترخيص والطراز
                      إضافة إلى تحديد طريقة الدفع (من خلال بطاقة الائتمان) ..
                      ويوافق العميل بالحفاظ على معلومات دقيقة ومكتملة ومحدثة
                      بشأن الحساب. ويمكن أن يؤدي إخفاق العميل في الحفاظ على
                      معلومات دقيقة ومكتملة ومحدثة بشأن الحساب بما في ذلك طريقة
                      الدفع غير السارية أو منتهية الصلاحية إلى عدم قدرته على
                      استخدام خدمات شركة الإمارات لتنفيذ الاحكام أو إلى إنهاء
                      شركة الإمارات لتنفيذ الاحكام للإتفاقية مع العميل.
                      <br /> ويتحمل العميل مسؤولية كافة العمليات التي تتم من
                      خلال الحساب ويوافق على الحفاظ على أمن وسرية أسم المستخدم
                      وكلمة المرور الخاصة بالحساب في كافة الأوقات، ما لم تسمح
                      شركة الإمارات لتنفيذ الاحكام بخلاف ذلك خطياً من أنه لا
                      يجوز أن يمتلك للعميل أكثر من حساب واحد.
                    </p>

                    <h4>4- الموقع الجغرافي</h4>
                    <p>
                      يوافق العميل على السماح لشركة الإمارات لتنفيذ الاحكام
                      بالحصول على معلومات دقيقة بشأن الموقع الجغرافي، وتجمع شركة
                      الإمارات لتنفيذ الاحكام المعلومات المتعلقة بالموقع
                      الجغرافي لغايات تحسين تجربة المستخدم - مثل تذكير المستخدم
                      بطلب التزود بالوقود (على سبيل المثال لا الحصر) ، ويجوز
                      للعميل تعطيل خاصية الموقع الجغرافي ولكن هذا الأمر قد يؤثر
                      على الخدمات التي تقدمها شركة الإمارات لتنفيذ الاحكام
                      للعميل.
                    </p>

                    <h4>5- الوصول إلى الشبكة والأجهزة</h4>
                    <p>
                      يتحمل العميل مسؤولية الحصول على وسيلة وصول إلى شبكة
                      البيانات اللازمة لأستخدام الخدمات، ويمكن أن تنطبق أسعار
                      الرسائل وحزم البيانات المحددة من شبكة الهاتف الجوال التي
                      يستخدمها العميل في حال استخدام الخدمات عن طريق جهاز مزود
                      بخاصية الأتصال اللاسلكي .. ويتحمل العميل مسؤولية امتلاك
                      وتحديث المعدات أو الأجهزة المتوافقة اللازمة لاستخدام
                      الخدمات والتطبيق وأي تحديثات لها.
                      <br />
                      ولا تضمن شركة الإمارات لتنفيذ الاحكام أن تعمل جميع الخدمات
                      أو أي جزء منها على أية معدات أو أجهزة معينة، إضافة لذلك
                      يمكن أن تتعرض الخدمات للأعطال أو التأخيرات الناجمة عن
                      استخدام الإنترنت أو الإتصالات الإلكترونية.
                    </p>

                    <h4>6- الترخيص</h4>
                    <p>
                      مع مراعاة امتثال العميل لهذه الاتفاقية، تمنح شركة الإمارات
                      لتنفيذ الاحكام للعميل ترخيصاً محدوداً وغير حصري ولا يجوز
                      ترخيصه من الباطن وقابل للإلغاء وغير قابل للتحويل من أجل
                      استخدام التطبيق على جهاز العميل الشخصي فقط فيما يتعلق
                      بإستخدامه للخدمات واستخدام المحتوى والمعلومات والمواد ذات
                      العلاقة التي يمكن توفيرها من خلال الخدمات لكل حالة على حدة
                      لغايات الأستخدام الشخصي وغير التجاري.
                      <br />
                      وتحتفظ شركة الإمارات لتنفيذ الاحكام والمرخص لهم من قبلها
                      بموجب ذلك بأية حقوق غير ممنوحة صراحة في هذه الاتفاقية.
                    </p>

                    <h4>7- الأنشطة المحظورة</h4>
                    <p>لا يجوز للعميل أن :-</p>
                    <ol>
                      <li>• يمتلك أكثر من حساب واحد .</li>
                      <li>
                        • يلغي أية حقوق نشر أو علامة تجارية أو إشارات ملكية أخرى
                        من أي جزء من الخدمات.
                      </li>
                      <li>
                        • يضع روابط لأي جزء من الخدمات أو يضع أطار لها أو
                        يستخدمها من خلال برامج أخرى.
                      </li>
                      <li>
                        • محاولة الحصول على إمكانية وصول غير مصرح بها أو يعيق أي
                        جانب من الخدمات أو الأنظمة أو الشبكات ذات العلاقة بها.
                      </li>
                    </ol>
                    <p></p>

                    <h4>8- خدمات ومحتوى الأطراف الخارجية</h4>
                    <p>
                      لا تتحمل شركة الإمارات لتنفيذ الاحكام مسؤولية أية منتجات
                      أو خدمات يقدمها المزودون الخارجيون، إضافة لذلك تعتبر شركة
                      آبل أو شركة غوغل أو شركة مايكروسوفت منتفعين خارجيين من هذا
                      العقد في حال الوصول إلى الخدمات بإستخدام التطبيقات المطورة
                      للأجهزة التي تعمل بإستخدام نظام تشغيل آبل أو أندرويد أو
                      ويندوز.
                      <br />
                      ولا يعتبر المنتفعون الخارجيون أطرافاً في هذا العقد ولا
                      يتحملون مسؤولية تقديم الخدمات أو توفير الدعم لها بأي شكل
                      من الأشكال، ويخضع وصول العميل إلى الخدمات بإستخدام هذه
                      الأجهزة للشروط الواردة في شروط الخدمة الخاصة بالمنتفعين
                      الخارجيين، ويوافق العميل على الأمتثال لأي شروط معمول بها
                      لأي أطراف خارجية عند استخدام الخدمات.
                    </p>

                    <h4>9- حقوق الملكية</h4>
                    <p>
                      تعتبر الخدمات وأي حقوق فيها مملوكة لشركة الإمارات لدعم
                      الطرق ، ولا تمنح هذه الاتفاقية أو استخدام العميل للخدمات
                      أي حقوق للعميل في:
                    </p>
                    <ol>
                      <li>
                        • استخدام الأسماء أو الشعارات أو أسماء المنتجات والخدمات
                        أو العلامات التجارية أو علامات الخدمة التي تخص شركة
                        الإمارات لتنفيذ الاحكام أو الإشارة إليها بأي شكل من
                        الأشكال.
                      </li>
                    </ol>
                    <h4>10- الرسائل النصية</h4>
                    <p>
                      يوافق العميل على السماح لشركة الإمارات لتنفيذ الاحكام بأن
                      ترسل له رسائل نصية قصيرة SMS. وترسل شركة الإمارات لتنفيذ
                      الاحكام رسائل للعميل بهدف تقديم الخدمات وتحسين تجربة
                      العميل، مثل الأتصال بالعميل في حال عدم القدرة على تحديد
                      موقع مركبته، ويمكن للعميل وقف استلام الرسائل من خلال الرد
                      بكلمة "إيقاف" بعد استلام أي رسالة ولكن هذا الأمر قد يؤثر
                      على الخدمات التي تقدمها شركة الإمارات لتنفيذ الاحكام .
                    </p>
                    <h4>11- خاصية الدفع</h4>
                    <p>
                      يتحمل العميل كافة الرسوم المتعلقة بإستخدام خدمات شركة
                      الإمارات لتنفيذ الاحكام وتتحمل شركة الإمارات لتنفيذ
                      الاحكام مسؤولية إبلاغ العميل بهذه الرسوم بدقة ووضوح،
                      ويوافق العميل على استلام الإيصال من خلال بريد إلكتروني أو
                      رسالة نصية أو عبر تطبيق الإمارات لتنفيذ الاحكام وفي حال
                      دعت الحاجة إلى تعديل أو إلغاء الخدمات، تقوم شركة الإمارات
                      لتنفيذ الاحكام بإخطار العميل بذلك. ولا يتحمل العميل أي
                      التزام بدفع إكرامية للسائق الذي يوصل الخدمات ولكن شركة
                      الإمارات لتنفيذ الاحكام تثمن ملاحظات العميل بشأن تجربته.
                    </p>
                    <h4>11-1- الرسوم</h4>
                    <p>
                      يدرك العميل أن استخدام الخدمات يعني فرض رسوم عليه مقابل
                      الخدمات أو المنتجات التي يحصل عليها من شركة الإمارات لدعم
                      الطرق (الرسوم). وتكون الرسوم شاملة لكافة الضرائب المعمول
                      بها بموجب القانون، وتكون الرسوم التي يدفعها العميل نهائية
                      وغير قابلة للاسترداد، ما لم تحدد شركة الإمارات لتنفيذ
                      الاحكام خلاف ذلك، وتستحق كافة الرسوم مباشرة وتسهل شركة
                      الإمارات لتنفيذ الاحكام دفع الرسوم من خلال استخدام طريقة
                      الدفع المفضلة المحددة في حساب العميل. في حال انتهت صلاحية
                      طريقة الدفع الأساسية المحددة في حساب العميل أو لم تعد
                      فعالة أو خلافا لذلك لا يمكن تقييد الرسوم من خلالها.
                      <br />
                      ويوافق العميل على استخدام شركة الإمارات لتنفيذ الاحكام
                      لطريقة الدفع الثانوية المحددة في حسابه .. في حال توفرها ..
                      ويوافق العميل على أن تقوم شركة الإمارات لتنفيذ الاحكام
                      بإرسال الإيصال من خلال بريد إلكتروني أو رسالة نصية أو عبر
                      تطبيق شركة الإمارات لتنفيذ الاحكام ويعتبر الإيصال
                      الإلكتروني كافياً لكافة الأغراض، بما في ذلك أي متطلبات
                      محددة بموجب القانون. وفي حال كان العميل يفضل استلام إيصال
                      ورقي، يرجى الأتصال بخدمات الدعم على (البريد الإلكتروني
                      الخاص بالشركة) خلال 30 يوما من بعد كل تزويد العمل بأى خدمة
                      تم تقديمها إليه وذلك لطلب إيصال ورقي حيث سيتم إرساله عبر
                      البريد الخاص بالعميل في أقرب وقت ممكن.
                    </p>

                    <h4>11-2- تعديل الرسوم</h4>
                    <p>
                      تحتفظ شركة الإمارات لتنفيذ الاحكام وفقاً لتقديرها الخاص،
                      بالحق في إنشاء وتعديل الرسوم لكافة الخدمات أو المنتجات
                      التي يتم الحصول عليها من خلال استخدام الخدمات.
                    </p>

                    <h4>12- انتفاء المسؤولية عن الشركة</h4>
                    <p>
                      لا تتحمل شركة الإمارات لتنفيذ الاحكام مسؤولية أية أضرار أو
                      خسائر ناجمة عن استخدام العميل أو عدم قدرته على استخدام
                      الخدمات أو خلافا لذلك أي أضرار أو خسائر ناجمة عن هذه
                      الاتفاقية .. يستخدم العميل خدمات شركة الإمارات لتنفيذ
                      الاحكام على مسؤوليته الخاصة، ويتم تقديم الخدمات كما هي
                      وعند توفرها. وتخلي شركة الإمارات لتنفيذ الاحكام مسؤوليتها
                      من أي تعهدات أو ضمانات صريحة أو ضمنية أو قانونية غير
                      مذكورة صراحة في هذه الشروط، ويوافق العميل على تحمله وحده
                      لكافة المخاطر الناجمة عن استخدام الخدمات وأي خدمات أو
                      منتجات تطلب من خلال استخدام الخدمات، وبالحد الأقصى المسموح
                      به بموجب القانون المعمول به.
                    </p>
                    <ol>
                      <li>
                        • لا تتحمل شركة الإمارات لتنفيذ الاحكام مسؤولية أية
                        أضرار تتعرض لها مركبة العميل خارج نطاق التأمين.
                      </li>
                      <li>
                        • لا تتحمل شركة الإمارات لتنفيذ الاحكام مسؤولية دقة
                        الموقع الذي يوفره العميل.
                      </li>
                      <li>
                        • تحتفظ شركة الإمارات لتنفيذ الاحكام بالحق في فرض رسوم
                        التسليم في حال لم يكن من الممكن الوصول إلى مركبة العميل.
                      </li>
                      <li>
                        • في حال الإلغاء لسبب يتعلق بشركة الإمارات لتنفيذ
                        الاحكام أو السائق التابع لها، لا يتحمل العميل أية رسوم.
                      </li>
                      <li>
                        • فى حال التدخل في عمل السائق بأي طريقة كانت تحتفظ شركة
                        الإمارات لتنفيذ الاحكام بالحق في تقديم شكوى رسمية بهذا
                        الصدد.
                      </li>
                      <li>
                        • تحتفظ شركة الإمارات لتنفيذ الاحكام بالحق في فرض رسوم
                        التسليم في حال لم يكن من الممكن الوصول إلى مركبة العميل
                        أو عدم امتثاله لمتطلبات السلامة التى تؤدي الى عرقلة
                        تقديم الموظف التابع للشركة للخدمة على الوجه الامثل.{' '}
                      </li>
                      <li>
                        • لا تتحمل شركة الإمارات لتنفيذ الاحكام أية مسؤولية عن
                        الأضرار غير المباشرة أو العرضية أو الخاصة أو الرادعة أو
                        التأديبية، بما في ذلك الكسب الفائت أو فقدان البيانات أو
                        الإصابة الشخصية أو الأضرار التي تلحق بالممتلكات فيما
                        يتعلق أو يخص أو خلافا ينتج عن أي استخدام للخدمات حتى لو
                        كانت شركة الإمارات لتنفيذ الاحكام على علم بإمكانية وقوع
                        مثل هذه الأضرار.
                      </li>
                    </ol>
                    <h4>13-</h4>
                    <p>
                      لا تتحمل شركة الإمارات لتنفيذ الاحكام أيضاً أي مسؤولية عن
                      التأخير أو الإخفاق في الأداء أو أي خلل أو فيروسات أو ما
                      شابه ذلك والتي يمكن أن تنتقل إلى الخدمات أو من خلالها من
                      قِبل أي طرف خارجي أو محتوى مستخدم أو أي سلوك تشهيري أو
                      مسيء أو غير قانوني لأي طرف خارجي أخر.
                    </p>
                    <h4>14- التعويض </h4>
                    <p>
                      يتحمل العميل مسؤولية استخدامه للخدمات، ويقوم بتعويض شركة
                      الإمارات لتنفيذ الاحكام ويحفظ مسؤوليها ومديريها وموظفيها
                      والشركات التابعة لها وشركاتها الفرعية ووكلائها ضد أي أو
                      جميع المطالبات والطلبات والخسائر والإلتزامات والنفقات (بما
                      في ذلك الأتعاب القانونية) ، الناشئة عن أو المرتبطة بأي شكل
                      من الأشكال بما يلي:
                    </p>
                    <ol>
                      <li>
                        • وصول العميل أو استخدامه للخدمات أو الخدمات والمنتجات
                        التي تم الحصول عليها من خلال استخدام العميل للخدمات.
                      </li>
                      <li>
                        • خرق أو انتهاك العميل لأي من هذه الشروط أو أي تعهدات أو
                        ضمانات أو اتفاقات تمت الإشارة إليها في هذه الشروط أو أي
                        قانون أو لائحة نافذة.
                      </li>
                      <li>
                        • انتهاك العميل لحقوق أي طرف خارجي بما في ذلك أي حق من
                        حقوق الملكية الفكرية أو الدعاية أو -السرية أو الملكية
                        الأخرى أو الخصوصية.
                      </li>
                    </ol>
                    <h4>15- خصوصية البيانات </h4>
                    <p>
                      يوافق العميل بموجب هذا على قيام شركة الإمارات لتنفيذ
                      الاحكام بجمع و/أو معالجة معلومات العميل الشخصية. وتلتزم
                      شركة الإمارات لتنفيذ الاحكام بحماية البيانات الشخصية التي
                      تستلمها من العميل أو خلافا لذلك تعالجها في سياق تقديم
                      الخدمات أو فيما يتعلق بها، وتتخذ شركة الإمارات لتنفيذ
                      الاحكام كافة الإجراءات الفنية والتنظيمية المناسبة
                      والمعقولة تجارياً لحماية البيانات الشخصية للعميل ضد أي
                      وصول غير مصرح به أو فقدان عرضي أو إتلاف غير مصرح به. ويقر
                      العميل ويوافق على أنه يجوز لشركة الإمارات لتنفيذ الاحكام
                      فيما يتعلق بالخدمات إشراك معالجين فرعين خارجيين ويمكنهم
                      معالجة البيانات الشخصية أو الوصول إليها من هذه المقرات.
                    </p>

                    <h4>16- حل النزاعات </h4>
                    <p>
                      يوافق العميل وشركة الإمارات لتنفيذ الاحكام على محاولة حل
                      أي نزاع أو مطالبة ناشئة عن هذه الاتفاقية أو تتعلق بها بنية
                      حسنة من خلال مفاوضات تتم بين مدير يمثل كل طرف له صلاحية
                      تسوية النزاع المعني. وفي حال تعذر حل النزاع ودياً خلال 14
                      يوما من تاريخ إرسال أي من الأطراف لإخطار خطي للطرف الآخر
                      بشأن النزاع.
                      <br />
                      وتخضع هذه الشروط والأحكام وتفسر وفقاً لقوانين إمارة دبي
                      والقوانين الاتحادية لدولة الإمارات العربية المتحدة حسب
                      مقتضى الحال في إمارة دبي, و أي نزاع ينشأ عن أو فيما يتعلق
                      بهذه الشروط والأحكام فإن محاكم دبي سيكون لها الإختصاص
                      للنظر والبت في أي عمل أو إجراء، وتسوية أي نزاعات قد تنشأ.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
      <FooterLayout
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        dashboard
      />
    </div>
  );
};

export default TermsAndConditions;
