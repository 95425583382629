import React from "react";
import { USDollar } from "../../../utils/helpers";
const ServiceDetails = ({
  serviceDescription,
  lang,
  arPhrase,
  enPhrase,
  service,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col justify-center w-full bg-white px-4 py-5 h-[86px]">
        <p className="text-[16px] text-[#010202] md:mb-[8px]">
          {lang === "en"
            ? enPhrase["lblServiceType"]
            : arPhrase["lblServiceType"]}
        </p>
        <p className="text-base text-[#010202] font-bold">
          {serviceDescription}
        </p>
      </div>
      <hr className="w-full h-[1px] bg-[#B68A35] text-[#B68A35] flex" />
      <div className="flex flex-col justify-center w-full bg-white px-4 py-4">
        <p className="text-[16px] text-[#010202] font-Almarai md:mb-[8px]">
          {lang === "en"
            ? enPhrase["lblServiceDetailsAmount"]
            : arPhrase["lblServiceDetailsAmount"]}
        </p>
        <div className="mb-[8px] flex items-center justify-between">
          <p className="text-[16px] text-[#010202] font-bold font-Almarai ">
            {" "}
            {lang === "en"
              ? enPhrase["lblAmountValue"]
              : arPhrase["lblAmountValue"]}
          </p>
          <div className="flex gap-[3px] items-center justify-center">
            <p className="text-[16px] text-[#8D867D] font-Almarai">
              {USDollar.format(service?.excludingVAT)}
            </p>
            <span className="text-sm font-Almarai text-[#8D867D]">
              {lang === "en" ? "AED" : "درهم"}
            </span>
          </div>
        </div>

        <div className="mb-[8px] flex items-center justify-between">
          <p className="text-[16px] text-[#010202] font-bold font-Almarai">
            {" "}
            {lang === "en"
              ? enPhrase["lblAmountVat"]
              : arPhrase["lblAmountVat"]}
          </p>
          <div className="flex gap-[3px] items-center justify-center">
            <p className="text-[16px] text-[#8D867D] font-Almarai">
              {USDollar.format(service?.vatValue)}
            </p>
            <span className="text-sm font-Almarai text-[#8D867D]">
              {lang === "en" ? "AED" : "درهم"}
            </span>
          </div>
        </div>

        <div className="mb-[8px] flex items-center justify-between">
          <p className="text-[16px] text-[#010202] font-bold font-Almarai">
            {" "}
            {lang === "en"
              ? enPhrase["lblTotalAmount"]
              : arPhrase["lblTotalAmount"]}
          </p>
          <div className="flex gap-[3px] items-center justify-center">
            <p className="text-[16px] text-[#A98031] font-Almarai">
              {USDollar.format(service?.serviceValue)}
            </p>
            <span className="text-sm font-Almarai text-[#A98031]">
              {lang === "en" ? "AED" : "درهم"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
