import React, { useContext, useEffect, useState } from "react";
import { Container } from "../components/utils";
import { PhrasesContext } from "../context/phrasesContext";
import ThemeLayout from "../layouts/ThemeLayout";
const Refund = () => {
  const [lang, setLang] = useState("");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});

  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
    } else {
      document.body.dir = "rtl";
      setLang("ar");
    }
    // getPhrasesService();
  }, []);

  const changeLanguage2 = (lang) => {
    if (lang === "en") {
      document.body.dir = "rtl";
      setLang("ar");
      window.localStorage.setItem("lang", "ar");
    } else {
      document.body.dir = "ltr";
      setLang("en");
      window.localStorage.setItem("lang", "en");
    }
    window.location.reload();
  };
  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return '';
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };
  return (
    <ThemeLayout>
      <section
        id="faq"
        className="w-full overflow-hidden bg-gold-10 border-t border-b wow fadeInUp min-h-[678px] xl:pt-[0rem]"
      >
        <Container>
          <div className="flex flex-col  items-center justify-center pb-16 text-justify">
            <div className="flex flex-col items-center justify-center my-5">
              <h3 className="text-3xl lg:text-4xl text-black leading-snug font-semibold">
                {lang === "en"
                  ? enPhrase["lbl_Refund_Policy"]
                  : arPhrase["lbl_Refund_Policy"]}
              </h3>
            </div>
            <div className="items-center w-full bg-white">
              {lang === "en" && (
                <div className="w-full p-10 terms">
                  <div>
                    <p>
                      - The user has the right to request a refund for any
                      services that they did not receive.
                    </p>

                    <p>
                      - The refund amount will return to the user in the same
                      way of the payment.
                    </p>
                    <p>
                      - Any service that is cancelled by EJE will be refunded
                      automatically.
                    </p>
                  </div>
                </div>
              )}

              {lang === "ar" && (
                <div className="w-full p-10 terms">
                  <div>
                    <p>- يحق للمستخدم طلب استرداد لأي خدمات لم يتلقها.</p>

                    <p>
                      - سيتم إسرتجاع المبلغ المسترد إلى المستخدم بنفس طريقة
                      الدفع.
                    </p>
                    <p>
                      - أي خدمة تم إلغاؤها بواسطة الإمارات لتنفيذ الاحكام سيتم
                      استردادها تلقائيًا.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
    </ThemeLayout>
  );
};

export default Refund;
