import React from "react";
import { Link } from "react-router-dom";

const PaymentBank = ({ service, lang, arPhrase, enPhrase }) => {
  return (
    <div className={`w-full md:w-[50%] border-[1px] border-solid border-[#E6D7A2] rounded-[8px] bg-[#fff] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] p-[24px] h-auto ${lang === "en"? "md:!h-[200px]":"md:!h-[182px]"}`}>
      <div className="flex items-center justify-between mb-[24px]">
        <div className="flex flex-col gap-[5px]">
          <p className="text-[20px] font-Almarai font-[700] text-[#292724]">
            {lang === "en"
              ? enPhrase["lblPaymentBank"]
              : arPhrase["lblPaymentBank"]}
          </p>

          <p className="text-[15px] font-Almarai font-[400] text-[#666]">
            {lang === "en"
              ? enPhrase["lblPaymentBankDetails"]
              : arPhrase["lblPaymentBankDetails"]}
          </p>
        </div>
      </div>
      <Link
        to={`/payment-Via-Bank/${service.caseId}/${service.id}`}
        className="w-full flex items-center justify-center bg-[#F2ECCF] rounded-[4px] h-[48px] p-[8px]"
      >
        <p className="text-[16px] font-Almarai font-[700] text-[#815327] leading-normal">
          {lang === "en"
            ? enPhrase["lblPaymentBank"]
            : arPhrase["lblPaymentBank"]}
        </p>
      </Link>
    </div>
  );
};

export default PaymentBank;
