import React, { useContext, useEffect, useState } from "react";
import ArchiveItem from "../components/archive-item";
import Breadcrumb from "../components/breadCrumb";
import NoRequest from "../components/case-overview/no-requests";
import LargeTitle from "../components/case-tracking/LargeTitle";
import { PhrasesContext } from "../context/phrasesContext";
import ThemeLayout from "../layouts/ThemeLayout";
import { getArchives } from "../services/Common";

const CaseArchives = () => {
  const [lang, setLang] = useState("");
  
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [apiData, setApiData] = useState({
    loading: true,
    data: [],
    error: false,
  });
  const [filteredArchives, setFilteredArchives] = useState([]);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getDetails(currentLang);
  }, []);

  const getDetails = (currentLang) => {
    setApiData({ loading: true, data: [] });
    return getArchives(currentLang).then((res) => {
      setApiData({ loading: false, data: res });
      setFilteredArchives(res);
    });
  };

  const handleFilteredArchives = (value) => {
    const filterd = apiData?.data?.filter((el) =>
      el?.caseNumber?.includes(value)
    );
    setFilteredArchives(filterd);
  };

 

  return (
    <ThemeLayout>
      <div className="flex flex-col w-full py-4 px-[24px] lg:px-0">
        <Breadcrumb
          title={
            lang === "en"
              ? enPhrase["lblFollowCases"]
              : arPhrase["lblFollowCases"]
          }
          last={
            lang === "en"
              ? enPhrase["lblCaseArchive"]
              : arPhrase["lblCaseArchive"]
          }
        />
        {!apiData?.loading ? (
          apiData?.data?.length > 0 ? (
            <>
              <div className="flex flex-col md:flex-row justify-between items-center gap-4 md:gap-2 mb-8">
                <LargeTitle
                  title={
                    lang === "en"
                      ? enPhrase["lblArchiveOfCompletedCases"]
                      : arPhrase["lblArchiveOfCompletedCases"]
                  }
                  nullmargin
                />
                <div className="relative">
                  <input
                    type="text"
                    name="search"
                    key="search"
                    placeholder={
                      lang === "en"
                        ? enPhrase["lblSearchCase"]
                        : arPhrase["lblSearchCase"]
                    }
                    className={`block w-full md:w-[364px] h-10 px-10 rounded-md  shadow-sm focus:border-gold-100 focus:ring-gold-100 sm:text-sm border-gray-300`}
                    onChange={(e) => {
                      handleFilteredArchives(e.target.value);
                    }}
                  />
                  <img
                    src="/assets/img/button/search.svg"
                    alt="logout"
                    className={`absolute top-3 ${
                      lang === "en" ? "left-3" : "right-3"
                    } w-5 h-5`}
                  />
                </div>
              </div>
              {filteredArchives?.length > 0
                ? filteredArchives?.map((item, index) => (
                    <ArchiveItem
                      key={index}
                      item={item}
                      lang={lang}
                      enPhrase={enPhrase}
                      arPhrase={arPhrase}
                    />
                  ))
                : lang === "en"
                ? enPhrase["lblNoResultFound"]
                : arPhrase["lblNoResultFound"]}
            </>
          ) : (
            <NoRequest isArchive />
          )
        ) : (
          "loading...."
        )}
      </div>
    </ThemeLayout>
  );
};

export default CaseArchives;
