import { useContext } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";

const FailedPayment = ({ setOpenModal }) => {
  const lang = window.localStorage.getItem("lang");
  // const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  return (
    <div className=" p-4">
      <img
        src="/assets/img/payment/failed.svg"
        alt="failed"
        className="m-auto w-18 h-18"
      />
      <p className="mt-[32px] mb-[16px] font-Almarai text-2xl text-[#FF3131] font-bold text-center">
        {lang === "en"
          ? enPhrase["lblPaymentFailed"]
          : arPhrase["lblPaymentFailed"]}
      </p>
      <p className=" text-base text-[#666666] font-Almarai text-center max-w-[296px] m-auto">
        {lang === "en"
          ? enPhrase["lblUnfortunatelypaymentFailed"]
          : arPhrase["lblUnfortunatelypaymentFailed"]}
      </p>
      <button
        className="flex flex-row justify-center items-center gap-2 w-full h-[48px] text-sm font-bold bg-[#B68A35] px-3 rounded-3 text-white mt-[32px]"
        onClick={() => setOpenModal(false)}
      >
        {/* <img
          src="/assets/img/button/add.svg"
          alt="logout"
          className="w-5 h-5"
        /> */}
        <span>حسناً</span>
      </button>
    </div>
  );
};

export default FailedPayment;
