import React from 'react';
export default function HeadSection({ label, label2 }) {
  return (
    <div
      className={`flex flex-col lg:flex-row items-start lg:items-center justify-between gap-1  mb-2 lg:mb-0`}>
      <div className={`flex lex-row items-center gap-1 `}>
        <label className='text-sm lg:text-lg font-bold pb-0 xs:pb-3 sm:-pb-2 '>
          {label}
        </label>
        {label2 && (
          <label className='text-xs lg:text-medium  pb-0 xs:pb-3 sm:-pb-2 '>
            {label2}
          </label>
        )}
      </div>
      {label2 && (
        <div className='  w-full lg:w-1/3 group relative'>
          <div className='bg-white flex justify-between items-center border-b-2 border-b-[#EEE2D0] px-4 py-3 rounded-t-lg w-auto shadow-[#361E121F] shadow-lg'>
            <span className='text-xs'>قوائم التوزيع والصرف</span>
            <span>100</span>
          </div>
          <div className='invisible !group-hover:visible  absolute z-50 w-full mb-4 p-4 bg-white rounded-b-lg shadow-[ #361E121F] shadow-lg w-full'>
            {[1, 2, 3, 4, 5].map((items) => (
              <div className='flex justify-between items-center w-auto  mb-3 '>
                <span className='text-xs'>قيد المراجعة</span>
                <span>20 قضية</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
