import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PhrasesContext } from "../../../context/phrasesContext";
import { USDollar } from "../../../utils/helpers";
import LargeTitle from "../LargeTitle";
const Case = ({ caseData }) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  const {
    courtName,
    caseFileNumber,
    caseFileType,
    caseFileYear,
    caseTypeName,
    claimedValue,
    caseStatus,
  } = caseData;
  return (
    <div className="w-full mb-12">
      <div className="flex flex-row  items-center justify-between gap-[16px] mb-[25px]">
        <div className="flex items-center gap-[16px]">
          <LargeTitle
            title={
              lang === "en"
                ? enPhrase["lblCaseDetails"]
                : arPhrase["lblCaseDetails"]
            }
            nullmargin
          />
          {caseStatus === "منتهية" || caseStatus === "Ended" ? (
            <div className="border-[1px] border-solid bg-[#6c4527] w-[58px] h-[20px] rounded-[10px] px-[8px] py-[3px] flex items-center justify-center">
              <span className="text-[12px] font-Almarai text-[#fff]">
                {lang === "en"
                  ? enPhrase["lblCaseArchive"]
                  : arPhrase["lblCaseArchive"]}
              </span>
            </div>
          ) : null}
        </div>
        <Link
          to="/request"
          className={`flex transition-all duration-300 font-medium py-2`}
        >
          <button className="flex flex-row items-center gap-2 w-[166px] justify-center h-[44px] font-bold bg-[#B68A35] px-3 rounded-3 text-white">
            <img
              src="/assets/img/button/add.svg"
              alt="logout"
              className="w-5 h-5"
            />
            <span>
              {lang === "en"
                ? enPhrase["lblNewApplication"]
                : arPhrase["lblNewApplication"]}
            </span>
          </button>
        </Link>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="items-center w-full bg-white">
          <div className="flex flex-col md:flex-row justify-between px-3 py-3 w-full bg-white border-2 border-solid border-[#EEE2D0] rounded shadow-[0px_10px_40px_#361E1214]">
            <div className="flex flex-col content-between justify-between gap-2">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en" ? enPhrase["lblCourt"] : arPhrase["lblCourt"]}
              </p>
              <p className="text-sm font-bold text-[#010202]">{courtName}</p>
            </div>
            <div className="flex flex-col content-between justify-between items-start gap-2">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblCaseNumber"]
                  : arPhrase["lblCaseNumber"]}
              </p>
              <p className="text-sm font-bold text-[#010202] flex" dir="ltr">
                {/* <span>{caseFileYear}</span>
                <span>/</span>
                <span>{caseFileType}</span> */}
                <span>{caseFileNumber}</span>
              </p>
            </div>
            <div className="flex flex-col content-between justify-between gap-2">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblCaseYear"]
                  : arPhrase["lblCaseYear"]}
              </p>
              <p className="text-sm font-bold text-[#010202]">{caseFileYear}</p>
            </div>
            <div className="flex flex-col content-between justify-between gap-2">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblCaseType"]
                  : arPhrase["lblCaseType"]}
              </p>
              <p className="text-sm font-bold text-[#010202]">{caseTypeName}</p>
            </div>
            <div className="flex flex-col content-between justify-between gap-2">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblCaseStatus"]
                  : arPhrase["lblCaseStatus"]}
              </p>
              <p className="text-sm font-bold text-[#010202]">{caseStatus}</p>
            </div>
            {/* <div className="flex flex-col content-between justify-between gap-2">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblClaimAmount"]
                  : arPhrase["lblClaimAmount"]}
              </p>
              <p className="text-sm font-bold text-[#010202]">
                <span>{USDollar.format(claimedValue)} </span>
                <span>{lang === "en" ? "AED" : "درهم"}</span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Case;
