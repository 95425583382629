import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { PhrasesContext } from "../../../context/phrasesContext";
import { UploadReceipt } from "../../../services/Common";
import FalidUploadReciept from "../../FalidUploadReciept";
import SuccessUploadReciept from "../../SuccessUploadReciept";
import SharedModal from "../../happiness/SharedModal";
import { Loader } from "../../utils";

const ReceiptForm = ({
  receiptStatus,
  setReceiptStatus,
  uploadReceiptHandler,
  service,
  successUploadReciept,
  setSuccessUploadReciept,
  setFalidUploadReciept,
  falidUploadReciept,
}) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const inputFile = useRef(null);
  const [file, setFile] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [showImage, setShowImage] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
      setFileUrl(reader.result);
    };
    reader.onerror = function (error) {};
  };

  

  const updateData = (data) => {
    setLoading(true);
    
    UploadReceipt(data).then((res) => {
      
      if (res.success === true) {
        setSuccessUploadReciept(true);

        setLoading(false);
      } else {
        setFalidUploadReciept(true);

        setLoading(false);
      }
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let recepitInfo = {
      ServiceId: service.id,
      CaseNumber: service.caseNumber,
      Receipt: uploadedFile,
    };
    
    updateData(recepitInfo);
  };


  return (
    <div className="w-full  ">
      <div className=" bg-[#eee2d0] w-full rounded-t-[16px]">
        <div className="h-[56px] w-full flex items-center px-[24px] justify-between">
          <h2 className="text-[14px] font-Almarai font-bold h-[16px] text-[#010202]">
            {lang === "en" ? enPhrase["btnuploadpr"] : arPhrase["btnuploadpr"]}
          </h2>
          <img
            src="/assets/img/close.svg"
            alt=""
            className="cursor-pointer"
            onClick={() => setReceiptStatus(false)}
          />
        </div>
      </div>

      <div className="mt-1 cursor-pointer flex p-[24px]">
        {file ? (
          //    <span role="alert" className=" text-xs">
          //    {uploadedFile?.name}
          //  </span>
          <div className="w-full md:w-[412px] h-[66px] p-[16px] rounded-[4px] border-[1px] border-solid border-[#d6d6d6] bg-[#fff] flex items-center justify-start md:justify-center gap-[16px]">
            <img
              src="/assets/img/submission.svg"
              alt="upload"
              className="w-[25px] h-[30px]"
            />
            {showImage ? (
              <SharedModal
                showImage
                setOpenModal={setShowImage}
                openModal={showImage}
              >
                <img className="w-full h-full" alt="" src={fileUrl} />
              </SharedModal>
            ) : (
              <div className="flex flex-col h-[34px]  items-start justify-start">
                <p className="text-[14px] font-Almarai font-bold text-[#361e12] w-[50px] sm:w-[237px]   h-[16px] truncate 	overflow-hidden ">
                  {uploadedFile.name}
                </p>
                <p className="text-[12px] font-Almarai  text-[#6e6e6e]">
                  {uploadedFile.size}KB{" "}
                </p>
              </div>
            )}
            <img
              src="/assets/img/view.svg"
              alt="view-icon"
              className=" w-[24px] h-[24px] cursor-pointer"
              onClick={() => setShowImage(!showImage)}
            />
            <img
              src="/assets/img/trash-can.svg"
              alt="delete-icon"
              className="w-[18px] h-[18px] cursor-pointer"
              onClick={() => setFile("")}
            />
          </div>
        ) : (
          <label
            htmlFor="fileupload"
            className="relative cursor-pointer flex flex-col w-[100%] items-center justify-center w-100 h-[123px] border-2 border-dashed bg-gold-10 border-[#B4B4B4] r rounded-md  px-6 pt-[46px] pb-[45px] font-medium text-gold-100 focus-within:outline-none focus-within:ring-2  "
          >
            {!file && errors.fileupload && (
              <span
                role="alert"
                className="text-red-700 mx-3 text-xs mb-[10px]"
              >
                {lang === "en"
                  ? enPhrase["lblUploadRecipt"]
                  : arPhrase["lblUploadRecipt"]}
              </span>
            )}
            <div className="flex items-center gap-[8px]">
              {!file && (
                <img
                  src="/assets/img/uploadFile.svg"
                  alt="Upload-File"
                  className="w-[32px] h-[32px]"
                />
              )}
              {!file && (
                <p className="text-[12px] text-[#6C4527] font-bold">
                  {lang === "en"
                    ? enPhrase["lblUploadReceipt"]
                    : arPhrase["lblUploadReceipt"]}
                </p>
              )}
            </div>

            <input
              id="fileupload"
              name="fileupload"
              ref={inputFile}
              type="file"
              accept="image/png, image/gif, image/jpeg"
              {...register("fileupload", {
                required: true,
              })}
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  setUploadedFile(e.target.files[0]);
                  getBase64(e.target.files[0], (res) => {
                    setFile(res);
                    
                  });
                } else {
                  setFile(null);
                }
              }}
              className="sr-only "
            />

            {/* {file && (
          <span role="alert" className=" text-xs">
            {uploadedFile?.name}
          </span>
        )} */}
          </label>
        )}
      </div>

      <div className="px-[24px] mb-[24px] ">
        <button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          disabled={loading}
          className="w-full h-[44px] bg-[#b68a35] flex items-center justify-center gap-[8px]"
        >
          <p className="text-[#fff] font-Almarai text-[14px]">
            {" "}
            {lang === "en" ? enPhrase["btnSend"] : arPhrase["btnSend"]}
          </p>
          {loading && <Loader color={"white"} />}
        </button>
      </div>
      {successUploadReciept && (
        <SharedModal
          isSuccessUpload
          openModal={successUploadReciept}
          setOpenModal={setSuccessUploadReciept}
        >
          <SuccessUploadReciept
            successUploadReciept={successUploadReciept}
            setSuccessUploadReciept={setSuccessUploadReciept}
            setReceiptStatus={setReceiptStatus}
            service={service}
          />
        </SharedModal>
      )}

      {falidUploadReciept && (
        <SharedModal
          isFaildUpload
          openModal={falidUploadReciept}
          setOpenModal={setFalidUploadReciept}
        >
          <FalidUploadReciept
            lang={lang}
            setFalidUploadReciept={setFalidUploadReciept}
            setUploadedFile={setUploadedFile}
          />
        </SharedModal>
      )}
    </div>
  );
};

export default ReceiptForm;
