import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PhrasesContext } from "../../context/phrasesContext";
import { getAllDetails } from "../../services/Common";
import TableMobile from "./TableMobile";
import ChartBar from "./charts/ChartBar";
import HeadSection from "./head-section";
import "./index.css";
import NoRequest from "./no-requests";
import Table from "./table";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function CaseOverViewComponent() {
  const [servicesDetails, setServicesDetails] = useState({});
  const colors = ["#2D555D", "#327C88", "#47ADB5", "#BAE5E7"];
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  const [lang, setLang] = useState("");
  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getDetails(currentLang);
  }, []);

  const [apiData, setApiData] = useState({
    loading: true,
    data: [],
    error: false,
  });

  const getDetails = (currentLang) => {
    setApiData({ loading: true, data: [] });
    return getAllDetails(currentLang).then((res) => {
      setApiData({ loading: false, data: res });
      setServicesDetails(res);
    });
  };

  const handlerRequestId = () => {
    localStorage.setItem("requestId", "");
  };

  return (
    <div className="h-full flex flex-col items-start px-[16px] lg:px-[0px] w-full lg:w-[1140px] mt-8 mb-4">
      {!apiData?.loading ? (
        servicesDetails?.service?.length > 0 ? (
          <>
            <div className="w-[100%] m-auto ">
              <div className="w-full flex flex-col lg:flex-row gap-[1rem] lg:gap-[1.5rem] items-center lg:items-start justify-between">
                <div className="bg-white p-[16px] h-auto lg:min-h-[400px] w-full lg:w-[90%] border-[1px] border-solid border-[#eee2d0] rounded-[4px]">
                  <HeadSection
                    label={
                      lang === "en"
                        ? enPhrase["lblNumofRequests"]
                        : arPhrase["lblNumofRequests"]
                    }
                  />
                  <ChartBar servicesDetails={servicesDetails} lang={lang} />
                </div>

                <div className="bg-white p-[16px] h-auto w-full  lg:w-[29%] shadow-[0_4px_20px_0_rgba(54,30,18,0.08)] border-[1px] border-solid border-[#eee2d0]">
                  <HeadSection
                    label={
                      lang === "en"
                        ? enPhrase["lblTracking"]
                        : arPhrase["lblTracking"]
                    }
                  />
                  <div className="flex flex-wrap gap-[8px] w-full lg:w-[236px] m-auto lg:mt-[18px] !mt-[16px] lg:mb-[0px] justify-between ">
                    {servicesDetails?.serviceStatusCounts?.map((count, id) => {
                      return (
                        <div
                          className={`w-[95.3px] h-[84px] lg:w-[114px] lg:h-[83px] rounded-[4px] ${
                            count.caseStatusName ===
                            `${lang === "en" ? "Pending" : "قيد المراجعة"}`
                              ? "bg-[#CCDBFA]"
                              : count.caseStatusName ===
                                `${
                                  lang === "en"
                                    ? "Need More Data"
                                    : "مطلوب بيانات إضافيه"
                                }`
                              ? "bg-[#EAD1F9]"
                              : count.caseStatusName ===
                                `${
                                  lang === "en"
                                    ? "Pending for Payment"
                                    : "قيد السداد"
                                }`
                              ? "bg-[#F9E5CF]"
                              : count.caseStatusName ===
                                `${
                                  lang === "en" ? "In Progress" : "قيد التنفيذ"
                                }`
                              ? "bg-[#ECEDD0]"
                              : count.caseStatusName ===
                                `${lang === "en" ? "Ended" : "منتهية"}`
                              ? "bg-[#C8E5DD]"
                              : count.caseStatusName ===
                                `${
                                  lang === "en"
                                    ? "Unable to Progress"
                                    : "يتعذر التنفيذ"
                                }`
                              ? "bg-[#D4D2CE]  border-[1px] border-solid border-[#968779] "
                              : "bg-[#FFB9AD] border-[1px] border-solid border-[#e61a05]"
                          }   bg-no-repeat	flex flex-col items-center justify-center  px-[27.3px] py-[11.5px] ${
                            id ==
                            servicesDetails?.serviceStatusCounts.length - 1
                              ? "!h-[44px] !w-full lg:!w-[236px] flex-row justify-between px-[14px]"
                              : ""
                          }`}
                        >
                          <p className="text-[#010202] text-center text-[12px] w-[94px] font-Almarai">
                            {count.caseStatusName}
                          </p>
                          <p className="text-[#010202] text-[20px] text-center font-Almarai font-bold">
                            {count.count}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 mainHead flex flex-col items-center  justify-center lg:flex-row lg:justify-between">
              <p className="mb-[16px] text-xl font-bold textHead text-center lg:text-start !lg:w-117px">
                {lang === "en"
                  ? enPhrase["lblAllApplications"]
                  : arPhrase["lblAllApplications"]}
              </p>
              <div className="flex flex-row gap-3">
                <Link
                  to="/archives"
                  className={`flex transition-all duration-300 font-medium py-2`}
                >
                  <button className="flex flex-row items-center gap-[8px]  h-[44px] font-bold font-Almarai text-[14px]  bg-[#F2ECCF] py-3 px-3 rounded-[8px]  text-[#815327]">
                    <img
                      src="/assets/img/button/archive.svg"
                      alt="archive"
                      className="w-5 h-5"
                    />
                    <span>
                      {lang === "en"
                        ? enPhrase["lblCaseArchive"]
                        : arPhrase["lblCaseArchive"]}{" "}
                    </span>
                  </button>
                </Link>
                <Link
                  to="/request"
                  className={`flex transition-all duration-300 font-medium py-2`}
                  onClick={handlerRequestId}
                >
                  <button
                    className={`flex items-center  w-[166px] h-[44px] rounded-[8px] bg-[#b68a35] py-[12px] ${
                      lang === "en"
                        ? "pl-[12px] pr-[16px]"
                        : "pl-[16px] pr-[12px]"
                    }`}
                  >
                    <img
                      src="/assets/img/button/add.svg"
                      alt="add"
                      className="w-[20px] h-[20px]"
                    />
                    <span className="text-[14px] text-[#fff] font-Almarai  font-bold  w-[110px] ">
                      {lang === "en"
                        ? enPhrase["lblNewApplication"]
                        : arPhrase["lblNewApplication"]}
                    </span>
                  </button>
                </Link>
              </div>
            </div>

            {window && window.innerWidth <= 1024 ? (
              <TableMobile servicesData={servicesDetails?.service || []} />
            ) : (
              <Table servicesData={servicesDetails?.service || []} />
            )}
          </>
        ) : (
          <NoRequest />
        )
      ) : (
        // <div className="w-full flex flex-col">
        //   <div className="flex w-full items-center justify-between">
        //     <div
        //       className={
        //         "flex items-center  justify-center flex-col w-[70%] p-[24px] bg-[#fff] border-solid border-[1px] border-[#B68A35] rounded-[4px] shadow-[0_25px_50px_0_rgba(54,30,18,0.15)]"
        //       }
        //     >
        //       <SkeletonTheme>
        //         <Skeleton
        //           count={8}
        //           containerClassName="flex flex-col w-[80%]"
        //         ></Skeleton>
        //       </SkeletonTheme>
        //     </div>

        //     <div
        //       className={
        //         "mt-2 flex items-center justify-center flex-col w-[20%] p-[24px] bg-[#fff] border-solid border-[1px] border-[#B68A35] rounded-[4px] shadow-[0_25px_50px_0_rgba(54,30,18,0.15)]"
        //       }
        //     >
        //       <SkeletonTheme>
        //         <Skeleton
        //           count={8}
        //           containerClassName="flex flex-col w-[90%]"
        //         ></Skeleton>
        //       </SkeletonTheme>
        //     </div>
        //   </div>

        //   <div
        //     className={
        //       "mt-[100px] flex items-center justify-center flex-col w-full p-[24px] bg-[#fff] border-solid border-[1px] border-[#B68A35] rounded-[4px] shadow-[0_25px_50px_0_rgba(54,30,18,0.15)]"
        //     }
        //   >
        //     <SkeletonTheme>
        //       <Skeleton
        //         count={8}
        //         containerClassName="flex flex-col w-[90%]"
        //       ></Skeleton>
        //     </SkeletonTheme>
        //   </div>
        // </div>
        <p className="text-[#b68a35] font-bold font-Almarai">
          {lang === "en" ? "Loading..." : "جاري عرض البيانات..."}
        </p>
      )}
    </div>
  );
}
