import React from "react";
import styles from "./styles.module.css";

const PopWrapper = (props) => {
  const { children } = props;
  return (
    <>
      <div className={styles.pop_wrapper}>{children}</div>
    </>
  );
};

export default PopWrapper;
