import React, { useEffect, useState } from "react";

const ChatToAdel = ({ lang, enPhrase, arPhrase, ChatHandler }) => {
const [backToTop,setBackToTop] =useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTop(true);
      } else {
        setBackToTop(false);
      }
    });
  }, []);
  return (
   <>
   {backToTop && ( <div
      className={`fixed bottom-[50px] z-[999] ${
        lang === "en" ? "right-0" : "left-0"
      } `}
    >
      <div
        className={
          lang === "ar"
            ? "mt-[16px] ml-[24px]  md:ml-[78px] cursor-pointer md:mt-[8px]"
            : "mt-[8px]  mr-[78px] cursor-pointer"
        }
      >
        {window && window.innerWidth < 1024 ? (
          <div className="flex items-center justify-end" onClick={ChatHandler}>
            <div
              className={`relative w-[112px] h-[32px] bg-[#fff] rounded-md border-[1px] border-solid border-[#e6d7a2] py-[7px] pr-[12px] pl-[46px] `}
            >
              <p
                className={`text-[14px] font-Almarai font-bold w-[102px] mt-[-3px] ${
                  lang === "en" ? "text-center ml-[-40px]" : ""
                }`}
              >
                {lang === "en" ? "Adel" : "عادل"}
              </p>
            </div>
            <div
              className={`absolute w-[60px] h-[60px] bg-[#b68a35] rounded-[30px]" ${
                lang === "en"
                  ? "right-[22px] md:right-[56px]  rounded-[30px]"
                  : "left-[15px] md:left-[56px] rounded-[30px]"
              }`}
            ></div>
            <section
              className={`absolute  ${
                lang === "en"
                  ? "right-[22px] md:right-[56px] "
                  : "left-[15px] md:left-[56px]"
              }`}
            >
              <img src="/assets/img/home/fares.png" alt="" />
            </section>
          </div>
        ) : (
          <div className="flex items-center justify-end" onClick={ChatHandler}>
            <div
              className={`relative w-[154px] h-[32px] bg-[#fff] rounded-md border-[1px] border-solid border-[#e6d7a2] py-[7px] pr-[12px] pl-[46px] `}
            >
              <p
                className={`text-[14px] font-Almarai font-bold w-[102px] mt-[-3px] ${
                  lang === "en" ? "text-center ml-[-40px]" : ""
                }`}
              >
                {lang === "en"
                  ? enPhrase["lblChatToFares"]
                  : arPhrase["lblChatToFares"]}
              </p>
            </div>
            <div
              className={`" absolute w-[60px] h-[60px] bg-[#b68a35] rounded-[30px]" ${
                lang === "en"
                  ? "right-[22px] md:right-[56px]  rounded-[30px]"
                  : "left-[22px] md:left-[56px] rounded-[30px]"
              }`}
            ></div>
            <section
              className={`absolute  ${
                lang === "en"
                  ? "right-[22px] md:right-[56px] "
                  : "left-[22px] md:left-[56px]"
              }`}
            >
              <img src="/assets/img/home/fares.png" alt="" />
            </section>
          </div>
        )}
      </div>
    </div>)}
   </>
  );
};

export default ChatToAdel;
