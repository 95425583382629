import React from 'react';
import './style.css';

const HelpLatest = ({ lang, arPhrase, enPhrase, ContactUsHandler }) => {
  return (
    <div className='absolute left-0 right-0 m-auto bottom-[-84px] px-3 lg:px-[60px]'>
      <div className='mx-auto 2xl:max-w-[1320px] xl:max-w-[1320px] lg:max-w-full md:max-w-full gap-[0px]  sm:max-w-[100%] w-full h-[164px] bg-[#ede2cc] !rounded-[20px]  flex items-center justify-between'>
        <div
          className={`h-[164px] py-[40px]  relative bg-[#fff] ${
            lang === 'en'
              ? 'rounded-tl-[20px] rounded-bl-[20px] pr-[40px] xl:pr-[256px] pl-[56px]'
              : 'rounded-tr-[20px] rounded-br-[20px] lg:-[40px] xl:pl-[256px] pr-[56px]'
          }`}>
          <p className='text-[42px] text-[#212121] font-Almarai font-bold leading-snug '>
            {lang === 'en' ? enPhrase['lblNeedHelp'] : arPhrase['lblNeedHelp']}
          </p>

          <p className='text-[16px] text-[#888888] font-Almarai  mt-[8px]'>
            {lang === 'en'
              ? enPhrase['lblshareOpinions']
              : arPhrase['lblshareOpinions']}
          </p>

          <div
            id={`${lang === 'en' ? 'outerEn' : 'outer'}`}
            className='absolute top-0'>
            <div id={`${lang === 'en' ? 'innerEn' : 'inner'}`}>&nbsp;</div>
          </div>
        </div>

        <div
          className={`h-[164px] py-[56px]  bg-[#ede2cc] w-[389px]  ${
            lang === 'en'
              ? 'rounded-tr-[20px] rounded-br-[20px] pl-[178px]'
              : 'rounded-tl-[20px] rounded-bl-[20px] pl-[56px] pr-[178px]'
          }`}>
          <button
            className='bg-[#b68a35] h-[52px] py-[18px] px-[32px] max-w-[158px] rounded-[8px] flex items-center justify-center shadow-[0_8px_28px_0_rgba(54, 30, 18, 0.2)]'
            onClick={ContactUsHandler}>
            <p className='text-[#fff] text-[13px] font-Almarai font-bold '>
              {lang === 'en'
                ? enPhrase['lblRequestHelp']
                : arPhrase['lblRequestHelp']}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HelpLatest;
