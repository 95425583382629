import React from "react";
import { returnPaginationRange } from "../../utils/helpers";
import "./index.css";
const Pagination = ({
  requestPerPage,
  totalRequests,
  paginate,
  currentPage,
  filtered2,
  lang,
  isMobile
}) => {
  const pageNumbers = [];
  for (
    let i = 1;
    i <=
    Math.ceil(
      `${
        filtered2.length <= requestPerPage
          ? totalRequests / requestPerPage
          : filtered2?.length / requestPerPage
      }`
    );
    i++
  ) {
    pageNumbers.push(i);
  }
  
  let totalPage = pageNumbers.length


  let pageArray = returnPaginationRange(totalPage, currentPage, requestPerPage)

  const HandlerClick = () => {
    paginate(pageNumbers.length);
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
  };
  
  // const nextbtnHandler =()=>{
  //     paginate(currentPage + 1);

  //     if(currentPage + 1 > max)
  // }

  const handlePageChange = (value) => {
    
    if (value === "... ") {
      paginate(1)
    } else if (value === " ...") {
      paginate(totalPage)
    } else {
      paginate(value);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <nav>
      <ul className='pagination'>
        <li
          onClick={() => paginate(1)}
          className={`${isMobile ? "pageItemNextMobile":"pageItemNext"} ${currentPage === 1 ? "disable" : ""}`}
        >
          {/* <FontAwesomeIcon
            icon={faAnglesRight}
            style={{
              color: "#838383",
              display: "flex",
              alignItems: "center",
              marginInlineStart: "10px",
              width: "11px",
              height: "8px",
            }}
          /> */}
          <img src="/assets/img/case-overview/double-angle-right.svg" alt="" className={`${lang === "en" ? "rotate-180 ml-[3px]" : ""}`} />
        </li>
        <li
          onClick={currentPage === 1 ? null : () => paginate(currentPage - 1)}
          className={`${isMobile ? "pageItemNextMobile":"pageItemNext"} ${currentPage === 1 ? "disable" : ""}`}
        >
          <img src="/assets/img/case-overview/single-angle-right.svg" alt="" className={`${lang === "en" ? "rotate-180 ml-[3px]" : ""}`} />
        </li>

        <div className="paginationPages">
          {pageArray.map((number) => (
            <>
              <li
                key={number}
                // onClick={() => {
                //   paginate(number);
                //   window.scrollTo({
                //     top: 0,
                //     behavior: "smooth",
                //   });
                // }}
                onClick={() => handlePageChange(number)}
                className={`pageItem ${currentPage === number ? "active" : ""}`}
              >
                <a href="#" className="pageLink">
                  {number}
                </a>
              </li>
            </>
          ))}
        </div>

        <li
          onClick={
            currentPage === pageNumbers.length
              ? null
              : () => paginate(currentPage + 1) 
          }
          className={`${isMobile ? "pageItemPrevMobile":"pageItemPrev"} ${currentPage >= totalRequests / requestPerPage ? "disable" : ""
            }`}
        >
          <img src="/assets/img/case-overview/single-angle-left.svg" alt="" className={`${lang === "en" ? "rotate-180 ml-[3px]" : ""}`} />
        </li>
        <li
          onClick={HandlerClick}
          className={`${isMobile ? "pageItemPrevMobile":"pageItemPrev"}  ${pageNumbers.length === Math.ceil(currentPage) ? "disable" : ""
            }`}
        >
          {/* <FontAwesomeIcon
            icon={faAnglesLeft}
            style={{
              color: "#b68a35",
              display: "flex",
              alignItems: "center",
              marginInlineStart: "10px",
              width: "11px",
              height: "8px",
            }}
          /> */}
          <img src="/assets/img/case-overview/double-angle-left.svg" alt="" className={`${lang === "en" ? "rotate-180 ml-[3px]" : ""}`} />
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
