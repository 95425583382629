import React from "react";

const AboutSection = ({ lang, enPhrase, arPhrase, vision }) => {
  return (
    <div
      id="about"
      className="w-full overflow-hidden bg-[#fff] h-auto md:h-[593px] py-[56px] md:py-[60px]   mt-[0px] md:mt-[40px]"
    >
      {/* <p className="text-center text-[28px] md:text-[44px] mb-[48px] md:mb-[56px]">
        {lang === "en"
          ? enPhrase["lblEmiratesJudgmentEnforcement"]
          : arPhrase["lblEmiratesJudgmentEnforcement"]}
      </p> */}
      <section className="mb-[40px]">
        {window && window.innerWidth <= 1024 ? (
          <img
            src="assets/img/home/EJE Logo-mobile.svg"
            alt=""
            className="m-auto"
          />
        ) : (
          <img src="assets/img/home/EJE Logo.svg" alt="" className="m-auto" />
        )}
      </section>
      <div className="flex flex-col gap-[25px] xl:flex-row items-center justify-between  lg:justify-between xl:justify-center  xl:m-auto !mb-[0px] md:!mb-[64px] ">
        <div
          className={`flex flex-col items-center justify-center w-full h-[324px] xl:w-[50%] bg-background-About1 ${
            lang === "en"
              ? "xl:bg-background-About1 xl:bg-left-top"
              : "xl:bg-background-AboutOverView xl:bg-right-top"
          } bg-contain bg-no-repeat  bg-center  `}
        >
          <div
            className={`flex flex-col items-center justify-center mr-0 ${
              lang === "en" ? "xl:ml-[123.5px]" : "xl:mr-[123.5px]"
            }`}
          >
            <p className="text-center text-[28px] md:text-[36px] text-[#361e12] font-bold font-Almarai h-[41px] ">
              {" "}
              {lang === "en"
                ? enPhrase["lblOverview"]
                : arPhrase["lblOverview"]}
            </p>
            <div className="divide-black h-[4px] border-2 w-[96px] divide-x-2 text-center mb-[24px] mt-[16px] md:mt-[24px] md:mb-[32px] border-[#b68a35] rounded-[2px]"></div>
            <p
              className={`w-[327px] md:w-[424px] text-center text-[#361e12] text-[16px] md:text-[24px] ${
                lang === "en" ? "" : "h-[41px] md:h-[63px]"
              }`}
            >
              {lang === "en"
                ? enPhrase["lblFacilitateExpediteCases"]
                : arPhrase["lblFacilitateExpediteCases"]}
            </p>

            <div
              className={`bg-gradient-to-b from-[#fff] to-[#f2eccf] rounded-[12px] w-[282px] h-[95px]  xl:h-[120px] flex items-center justify-between px-[32px] py-[16px] xl:px-[0px] xl:py-[0px] xl:justify-center gap-[32px] border-[#B68A35] border-[1px] border-solid mt-[32px] xl:mt-[40px] ${
                lang === "en" ? "!px-[24px] xl:w-[415px]" : "xl:w-[342px]"
              }`}
            >
              <section>
                <img
                  src="/assets/img/home/productivity_12892200.svg"
                  alt=""
                  className="w-[56px] h-[56px]  xl:w-full xl:h-full"
                />
              </section>
              <div>
                <p className=" text-[16px] xl:text-[22px] text-[#020202] font-Almarai">
                  <strong className="text-[#020202] text-[32px] font-[Pro Display]">
                    {vision?.processTimeCount}
                  </strong>{" "}
                  {lang === "en" ? enPhrase["lblHours"] : arPhrase["lblHours"]}
                </p>
                <p className="text-[#02020280] text-[13px] xl:text-[18px] font-bold">
                  {lang === "en"
                    ? enPhrase["lblTimeIndicator"]
                    : arPhrase["lblTimeIndicator"]}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-1 divide-x-2  bg-[#ebe0c7] h-[324px] hidden xl:block "></div>
        <div
          className={`flex flex-col items-center justify-center mt-[40px] md:mt-0 mb-0 lg:mb-[40px] h-[324px] md:mb-0 w-full xl:w-[50%] bg-background-About2 ${
            lang === "en"
              ? "xl:bg-background-About2 xl:bg-right-top"
              : "xl:bg-background-AboutVision xl:bg-left-top"
          } bg-no-repeat bg-contain bg-center `}
        >
          <div
            className={`flex flex-col items-center ml-0 ${
              lang === "en" ? "xl:mr-[123.5px]" : "xl:ml-[123.5px]"
            }`}
          >
            <p className="text-center text-[28px] md:text-[36px] text-[#361e12] font-bold h-[41px]">
              {lang === "en" ? enPhrase["lblVision"] : arPhrase["lblVision"]}
            </p>
            <div className=" h-[4px] border-2 w-[96px] divide-x-2 text-center mb-[24px] mt-[16px] md:mt-[24px] md:mb-[32px] rounded-[2px] border-[#b68a35]"></div>
            <p
              className={`w-[327px] md:w-[424px] text-center text-[#361e12] text-[16px] md:text-[24px] ${
                lang === "en" ? "" : "h-[41px] md:h-[63px]"
              }`}
            >
              {lang === "en"
                ? enPhrase["lblLeadingProvider"]
                : arPhrase["lblLeadingProvider"]}
            </p>
            <div
              className={`bg-gradient-to-b from-[#fff] to-[#f2eccf] rounded-[12px] w-[282px] h-[95px]  xl:h-[120px] flex items-center justify-between px-[32px] py-[16px] xl:px-[0px] xl:py-[0px] xl:justify-center gap-[32px] border-[#B68A35] border-[1px] border-solid  mt-[32px] xl:mt-[40px] ${
                lang === "en" ? "!px-[24px] xl:w-[415px]" : "xl:w-[342px]"
              }`}
            >
              <section>
                <img
                  src="/assets/img/home/snazzy-image.svg"
                  alt=""
                  className="w-[56px] h-[56px]  xl:w-full xl:h-full"
                />
              </section>
              <div>
                <p className="text-[#020202] font-Almarai text-[32px] font-[Pro Display] font-bold">
                  {vision?.registeredUsersCount}+
                </p>
                <p className="text-[#02020280] text-[13px] xl:text-[18px] font-bold">
                  {lang === "en"
                    ? enPhrase["lblNumOfTasks"]
                    : arPhrase["lblNumOfTasks"]}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className={`flex items-start	md:items-center justify-center gap-[7px] md:gap-[24px] mt-0  ${
          lang === "en" ? "md:!mt-[-30px]" : "md:mt-[64px]"
        }`}
      >
        <div className="flex items-center justify-center w-[160px] md:w-[296px] h-[101px] md:h-[124px] py-[24px] px-[20px] md:px-[40px] bg-white	rounded-xl gap-[16px] md:gap[40px]">
          <img
            src="/assets/img/home/collaborate.svg"
            alt=""
            className="w-[32px] md:w-[64px] h-[32px] md:h-[64px]"
          />
          <div className="flex flex-col">
            <span className="text-[36px] md:text-[40px] leading-none">45</span>
            <p className="text-[14px] md:text-[22px] text-gray-400 font-normal	">
              {lang === "en"
                ? enPhrase["lblNumberCourts"]
                : arPhrase["lblNumberCourts"]}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center w-[160px] md:w-[296px] h-[101px] md:h-[124px] py-[24px] px-[20px] md:px-[40px] bg-white	rounded-xl gap-[16px] md:gap[40px]">
          <img
            src="/assets/img/home/collaborate.svg"
            alt=""
            className="w-[32px] md:w-[64px] h-[32px] md:h-[64px]"
          />
          <div className="flex flex-col">
            <span className="text-[36px] md:text-[40px] leading-none">45</span>
            <p className="text-[14px] md:text-[22px] text-gray-400 font-normal	">
            {lang === "en"
                ? enPhrase["lblNumberCourts"]
                : arPhrase["lblNumberCourts"]}
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AboutSection;
