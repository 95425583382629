import React, { useContext, useEffect, useState } from "react";
import { PhrasesContext } from "../context/phrasesContext";
import ThemeLayout from "../layouts/ThemeLayout";
import { useAuthContext } from "../providers/authContext";

export default function Login() {
  const [lang, setLang] = useState("");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});

  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
    } else {
      document.body.dir = "rtl";
      setLang("ar");
    }
    // getPhrasesService();
  }, []);

  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return "";
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };
  const changeLanguage2 = (lang) => {
    if (lang === "en") {
      document.body.dir = "rtl";
      setLang("ar");
      window.localStorage.setItem("lang", "ar");
    } else {
      document.body.dir = "ltr";
      setLang("en");
      window.localStorage.setItem("lang", "en");
    }
    window.location.reload();
  };
  // const getUri = () => {
  //   return "https://stg-id.uaepass.ae/idshub/authorize?response_type=code&client_id=ajm_eje_web_stage&scope=urn:uae:digitalid:profile:general&state=CVvGgPTpRmEkjZBX&redirect_uri=https://eje.arabiansystems.com/authentication/&acr_values=urn:safelayer:tws:policies:authentication:level:low";
  // };

  // Stage Main
  // const getUri = () => {
  //   return "https://stg-id.uaepass.ae/idshub/authorize?redirect_uri=https://eje.arabiansystems.com/authentication&client_id=sandbox_stage&response_type=code&state=HnlHOJTkTb66Y5H&scope=urn:uae:digitalid:profile:generalurn:uae:digitalid:profile:general:profileTypeurn:uae:digitalid:profile:general:unifiedId&acr_values=urn:safelayer:tws:policies:authentication:level:low";
  // };

  //  Stage Main Test
  // const getUri = () => {
  //   return (
  //     `https://stg-id.uaepass.ae/idshub/authorize?redirect_uri=https://eje.arabiansystems.com/authentication&client_id=sandbox_stage&response_type=code&state=ShNP22hyl1jUU2RGjTRkpg==&scope=urn:uae:digitalid:profile:generalurn:uae:digitalid:profile:general:profileTypeurn:uae:digitalid:profile:general:unifiedId&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=` +
  //     lang
  //   );
  // };

  // const getUri = () => {
  //   return "https://stg-id.uaepass.ae/idshub/authorize?response_type=code&client_id=sandbox_stage&scope=urn:uae:digitalid:profile:general&state=HnlHOJTkTb66Y5H&redirect_uri=https://eje.arabiansystems.com/authentication/&acr_values=urn:safelayer:tws:policies:authentication:level:low";
  // };

  // const getUri = () => {
  //   return "https://stg-id.uaepass.ae/idshub/authorize?redirect_uri=https://eje.arabiansystems.com/authentication&client_id=sandbox_stage&response_type=code&state=pd3PgezRwk596u2yfRwqOgru&scope=urn:uae:digitalid:profile:general&acr_values=urn:safelayer:tws:policies:authentication:level:low";
  // };
  //MAin
  const getUri = () => {
    return (
      `https://id.uaepass.ae/idshub/authorize?redirect_uri=https://auth.eje.ae&client_id=ajm_eje_web_prod&response_type=code&state=PW8uDbLcMEeft9Ap&scope=urn:uae:digitalid:profile:general&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=` +
      lang
    );
  };

  // const getUri = () => {
  //   return (
  //     "https://id.uaepass.ae/idshub/authorize?response_type=code&client_id=ajm_eje_web_prod&scope=urn:uae:digitalid:profile:general&state=PW8uDbLcMEeft9Ap&redirect_uri=https://auth.eje.ae&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=" +
  //     lang
  //   );
  // };

  const { token = null } = useAuthContext();
  console.log("token", token);
  console.log("langlang", lang);

  return (
    <ThemeLayout>
      <div
        id="login"
        className={
          "mt-[100px] md:mt-[188px] mb-[100px] md:mb-[217px] flex items-center  flex-col px-[24px] lg:px-0"
        }
      >
        <div className="mb-8 text-center">
          <p className="text-sm md:text-3xl font-bold ">
            {lang === "en"
              ? enPhrase["lblSignUAEPass"]
              : arPhrase["lblSignUAEPass"]}
          </p>
        </div>
        <button
          type="button"
          className={`rounded-full text-white    focus:ring-5 focus:outline-none focus:ring-gray-100 font-medium text-sm md:text-base px-5 py-2.5 text-center inline-flex items-center     mr-2 mb-2 h-[56px]`}
          onClick={() => {
            // window.open(getUri(), "_blank");
            window.location.replace(getUri());
          }}
        >
          <img
            className=" me-2"
            src={`${
              lang === "en"
                ? "./assets/img/en_uaepass_btn.png"
                : "./assets/img/ar_uaepass_btn.png"
            }`}
            alt="icon-login"
          />
        </button>
      </div>
    </ThemeLayout>
  );
}
