import React from "react";
const ServiceDetailsPart2 = ({ service, lang, enPhrase, arPhrase }) => {
  const { createdAt, requesterName, requesterMobile, requesterEmail } = service;
  return (
    <div className="w-full ">
      <div className="flex  items-center justify-center ">
        <div className="p-[16px] flex-2 md:px-4 md:py-6 w-full bg-white flex !flex-col md:flex-row flex-wrap  gap-[24px] md:gap-[0.5rem] content-between  border-2 border-solid border-[#EEE2D0] rounded-[8px] md:rounded shadow-[0px_10px_40px_#361E1214] md:bg-none bg-LogoEJECommonCard bg-no-repeat bg-left ">
          <div className="flex flex-col md:flex-row gap-[20px]">
            <div className="flex flex-row md:flex-col content-between gap-1 justify-start  min-w-[45%]">
              <p className="text-[12px] font-normal text-[#010202] h-[36px]">
                {lang === "en"
                  ? enPhrase["lblApplicant"]
                  : arPhrase["lblApplicant"]}
              </p>
              <p className="text-sm font-bold text-[#010202] max-w-[180px] truncate">
                {requesterName}
              </p>
            </div>
            <div className="flex flex-row md:flex-col content-between gap-1 justify-start md:justify-start min-w-[52%]">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en"
                  ? enPhrase["lblApplicationDate"]
                  : arPhrase["lblApplicationDate"]}
              </p>
              <p className="text-sm font-bold text-[#010202]">
                {new Date(createdAt).toLocaleDateString("en-GB")}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[24px]">
            <div className="flex flex-row md:flex-col content-between gap-1 justify-start  min-w-[45%]">
              <p className="text-[12px] font-normal text-[#010202]">
                {lang === "en" ? "Mobile" : "الهاتف"}:
              </p>
              <p
                className={`text-sm font-bold text-[#010202] ${
                  lang === "en" ? "" : "text-right"
                } `}
                dir="ltr"
              >
                {requesterMobile}
              </p>
            </div>
            <div className="flex flex-row md:flex-col content-between gap-1 justify-start min-w-[45%]">
              <p className="text-[12px]  font-normal text-[#010202]">
                {lang === "en" ? enPhrase["lblEmail"] : arPhrase["lblEmail"]}:
              </p>
              <p className="text-sm font-bold font-Almarai text-[#010202]">
                {requesterEmail}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsPart2;
