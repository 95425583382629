const SuccessPayment = ({ setOpenModal }) => {
  return (
    <div className=" p-[32px]">
      <img
        src="/assets/img/payment/success.svg"
        alt="success"
        className="m-auto w-[72px] h-[72px]"
      />
      <p className="mt-[32px] mb-[12px] text-2xl text-[#2E7E31] font-bold text-center max-w-[296px] ">
        تم السداد بنجاح!
      </p>
      <p className="mb-[16px]  font-Almarai text-base text-[#666] text-center max-w-[296px] m-auto">
        عميلنا العزيز نشكرك لسداد اجمالي القيمة المستحقة لقيمة الطلب
      </p>
      <p className="mb-6  text-[16px] text-[#010202] text-center font-Almarai font-bold max-w-[296px] m-auto">
      في حال تبين وجود فارق في الرسوم المقدرة أثناء تنفيذ الخدمة، سيتم إشعاركم بسداد الفارق قبل البدء في التنفيذ.
      </p>
      <button
        className="flex flex-row justify-center items-center gap-2 w-full h-[48px] text-sm font-bold bg-[#B68A35] px-3 rounded-3 text-white"
        onClick={() => setOpenModal(false)}
      >
        {/* <img
          src="/assets/img/button/add.svg"
          alt="logout"
          className="w-5 h-5"
        /> */}
        <span className="text-[14px]">حسناً</span>
      </button>
    </div>
  );
};

export default SuccessPayment;
