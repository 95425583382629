import React from 'react';
import ThemeLayout from "../layouts/ThemeLayout";
import PaymentDiffrentMethod from '../components/PaymentDiffrentMethod';


const PaymentMethodPage = () => {
  return (
    <ThemeLayout>
        <PaymentDiffrentMethod />
    </ThemeLayout>
  )
}

export default PaymentMethodPage