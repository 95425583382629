import React, { useContext, useEffect, useState } from "react";
import ThemeLayout from "../layouts/ThemeLayout";
import { PhrasesContext } from "../context/phrasesContext";

const PageNotFound = () => {
  const [lang, setLang] = useState("");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
    } else {
      document.body.dir = "rtl";
      setLang("ar");
    }
  }, []);
  const redirectToSupport = () => {
    window.location.href = "/";
  };

  const redirectTryAgain = () => {
    window.location.reload();
  };
  return (
    <ThemeLayout>
      <div className="flex flex-col gap-[16px] md:gap-[32px] items-center w-[300px] md:w-[572px] max-h-[538px] py-[24px] md:py-[48px] mt-[48px] md:mt-[88px] mb-[72px] md:mb-[150px] rounded-[6px] md:rounded-[8px] shadow-[0_4px_24px_0_rgba(0,0,0,0.16)] bg-[#fff]">
        <img
          src="/assets/img/home/404Error.png"
          alt=""
          className="w-[200px] md:w-[388px] h-[115px] md:h-[225px] object-contain"
        />
        <p className="px-[24px] md:px-[48px] text-[12px] md:text-[18px] font-Almarai leading-[1.44] text-[#5d5d5d] text-center">
          {lang === "en"
            ? enPhrase["lblErrorTryAgain"]
            : arPhrase["lblErrorTryAgain"]}
        </p>
        <div className="flex flex-col md:flex-row gap-[8px] md:gap-[16px]">
          <button
            className="flex flex-row justify-center items-center w-[150px] md:w-[201px] h-[36px] md:h-[48px] rounded-[6px] md:rounded-[8px] bg-[#4a4a4a] font-Almarai text-[12px] md:text-[16px] text-[#fff] cursor-pointer"
            onClick={() => redirectToSupport()}
          >
            {" "}
            {lang === "en" ? enPhrase["btnBackToHome"] : arPhrase["btnBackToHome"]}
          </button>
          <button
            className="flex flex-row justify-center items-center w-[150px] md:w-[201px] h-[36px] md:h-[48px] rounded-[6px] md:rounded-[8px] bg-gold-100 font-Almarai text-[12px] md:text-[16px] text-[#fff] cursor-pointer"
            onClick={() => redirectTryAgain()}
          >
            {lang === "en" ? enPhrase["btnTryAgain"] : arPhrase["btnTryAgain"]}
          </button>
        </div>
      </div>
    </ThemeLayout>
  );
};

export default PageNotFound;
