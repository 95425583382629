import React, { useContext } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";
import { USDollar } from "../../../utils/helpers";
import { Link } from "react-router-dom";
const ServiceValue = ({
  claimedValue,
  serviceStatusId,
  setOpenModal,
  successUploadReciept,
  service,
}) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  console.log("service", service);
  return (
    <div className="w-full ">
      {(service?.serviceStatusId === 4 ||
        service?.serviceStatusId === 5 ||
        service?.serviceStatusId === 6 ||
        service?.serviceStatusId === 7) &&
      service.isUploadReceipts ? (
        <div
          className={`flex gap-[24px] items-center w-full bg-background-PaymednBtn bg-contain bg-[#b68a35] bg-no-repeat ${
            lang === "en" ? "bg-right" : "bg-left"
          }  px-[24px] py-5 h-[86px]`}
        >
          <img
            src="/assets/img/Waiting-approval.svg"
            alt="Waiting-approval"
            className=""
          />
          {service?.isPaid === true ? (
            <p className="text-[14px] md:text-[18px] font-Almarai font-bold leading-[1.71]  text-[#fff]">
              {lang === "en"
                ? enPhrase["lblPaymentIsSuccess"]
                : arPhrase["lblPaymentIsSuccess"]}
            </p>
          ) : (
            <p className="text-[14px] md:text-[18px] font-Almarai font-bold leading-[1.71]  text-[#fff]">
              {lang === "en"
                ? enPhrase["lblReceiptUnderReview"]
                : arPhrase["lblReceiptUnderReview"]}
            </p>
          )}
        </div>
      ) : (
        <div className="flex justify-between items-center w-full bg-background-PaymednBtn bg-contain bg-[#b68a35] bg-no-repeat bg-left  px-[24px] py-5 h-[86px]">
          <div>
            <p className="text-[16px] text-[#FFFFFF] leading-[24px] mb-[2px] font-Almarai md:h-[14px] md:mb-[5px]">
              {lang === "en"
                ? enPhrase["lblServiceValue"]
                : arPhrase["lblServiceValue"]}
            </p>
            <p className="flex items-start gap-[7px] text-2xl text-[#FFFFFF] leading-normal md:h-[27px]">
              <span className="font-bold text-[24px]">
                {USDollar.format(claimedValue)}{" "}
              </span>
              <span className="text-sm font-Almarai">
                {lang === "en" ? "AED" : "درهم"}
              </span>
            </p>
          </div>

          {/* {serviceStatusId === 3 && service.isUploadReceipts !== true ? (
            <button
              className={`flex ${
                lang === "en" ? " w-[180px]" : " w-[130px]"
              } flex-row-reverse justify-center font-Almarai items-center gap-[8px] transition-all duration-300 px-[12px] font-semibold md:px-1 lg:px-4 xl:px-[12px] h-[44px] text-sm border-2 text-[#815327] bg-[#FFF] border-[#815327] rounded focus:outline-none`}
              onClick={() => setOpenModal(true)}
            >
              <span className=" h-[18px] text-[13px] font-bold font-Almarai">
                {lang === "en"
                  ? enPhrase["lblPaymentMethod"]
                  : arPhrase["lblPaymentMethod"]}
              </span>
              <img
                src="/assets/img/button/receipt.svg"
                alt="logout"
                className="w-5 h-5"
              />
            </button>
          ) : null} */}

          {/* New Update */}
          {serviceStatusId === 3 && service.isUploadReceipts !== true ? (
            <Link
              to={`/payment-method/${service.caseId}/${service.id}`}
              className={`flex ${
                lang === "en" ? " w-[180px]" : " w-[166px]"
              } flex-row-reverse justify-center font-Almarai items-center gap-[8px] transition-all duration-300 px-[12px] font-semibold md:px-1 lg:px-4 xl:px-[12px] h-[44px] text-sm border-2 text-[#815327] bg-[#FFF] border-[#815327] rounded focus:outline-none`}
            >
              <span className=" h-[18px] text-[13px] font-bold font-Almarai drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                {lang === "en"
                  ? enPhrase["lblPaymentMethod"]
                  : arPhrase["lblPaymentMethod"]}
              </span>
              <img
                src="/assets/img/button/receipt.svg"
                alt="logout"
                className="w-5 h-5"
              />
            </Link>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ServiceValue;
