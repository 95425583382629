import React from 'react';
import PaymentMethod from "../../components/payment/paymentMethod";
import PaymentSummary from "../../components/payment/paymentSummary";
import styles from "./styles.module.css";

export default function Payment() {
  return (
    <div className={styles.PaymentWrapper}>
      <div className={styles.PaymentHead}>
        <h2>{"طرق الدفع المختلفة"}</h2>
      </div>
      <div className={styles.PaymentContainer}>
      <PaymentSummary />
        <PaymentMethod />
      </div>
    </div>
  );
}
