import React from "react";
import styles from "./styles.module.css";
import { PhrasesContext } from "../../../context/phrasesContext";
import { useContext } from "react";

const PaymentMethod = (props) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  return (
    <div className={styles.PaymentMethodContainer}>
      <div className={styles.payAccordion}>
        <div className={styles.summaryAccordion}>
          <div className={styles.payHead}>
            <div className={styles.logosWrapper}>
              <img
                src="/assets/img/payment/americanexpress.svg"
                alt="americanexpress"
              />
              <img src="/assets/img/payment/mastercard.svg" alt="mastercard" />
              <img src="/assets/img/payment/visa.svg" alt="visa" />
            </div>
            <div className={`${styles.radioLabelWrapper}`}>
              <label
                className={`${styles.accordionLabel}`}
                htmlFor="CreditCard"
              >
                {"Credit Card"}
              </label>
            </div>
          </div>
        </div>
        <div className={styles.payAction}>
          <p>
          {lang === "en"
                ? enPhrase["btnMakePay"]
                : arPhrase["btnMakePay"]} 
          </p>
          <img src="/assets/img/payment/arrow.svg" alt="arrow" />
        </div>
      </div>

      <div className={styles.payAccordion}>
        <div className={styles.summaryAccordion}>
          <div className={styles.payHead}>
            <div className={styles.logosWrapper}>
              <img src="/assets/img/payment/PayPal.svg" alt="PayPal" />
            </div>
            <div className={`${styles.radioLabelWrapper}`}>
              <label className={`${styles.accordionLabel}`} htmlFor="PayPal">
                {"PayPal"}
              </label>
            </div>
          </div>
        </div>
        <div className={styles.payAction}>
          <p>{lang === "en"
                ? enPhrase["btnMakePay"]
                : arPhrase["btnMakePay"]}</p>
          <img src="/assets/img/payment/arrow.svg" alt="arrow" />
        </div>
      </div>

      <div className={styles.payAccordion}>
        <div className={styles.summaryAccordion}>
          <div className={styles.payHead}>
            <div className={styles.logosWrapper}>
              <img src="/assets/img/payment/SamsungPay.svg" alt="Samsung pay" />
            </div>
            <div className={`${styles.radioLabelWrapper}`}>
              <label
                className={`${styles.accordionLabel}`}
                htmlFor="SamsungPay"
              >
                {"Samsung pay"}
              </label>
            </div>
          </div>
        </div>
        <div className={styles.payAction}>
          <p>{lang === "en"
                ? enPhrase["btnMakePay"]
                : arPhrase["btnMakePay"]}</p>
          <img src="/assets/img/payment/arrow.svg" alt="arrow" />
        </div>
      </div>

      <div className={styles.payAccordion}>
        <div className={styles.summaryAccordion}>
          <div className={styles.payHead}>
            <div className={styles.logosWrapper}>
              <img src="/assets/img/payment/ApplePay.svg" alt="Apple Pay" />
            </div>
            <div className={`${styles.radioLabelWrapper}`}>
              <label className={`${styles.accordionLabel}`} htmlFor="ApplePay">
                {"Apple Pay"}
              </label>
            </div>
          </div>
        </div>
        <div className={styles.payAction}>
          <p>{lang === "en"
                ? enPhrase["btnMakePay"]
                : arPhrase["btnMakePay"]}</p>
          <img src="/assets/img/payment/arrow.svg" alt="arrow" />
        </div>
      </div>

      <div className={styles.payAccordion}>
        <div className={styles.summaryAccordion}>
          <div className={styles.payHead}>
            <div className={styles.logosWrapper}>
              <img src="/assets/img/payment/AmazonPay.svg" alt="AmazonPay" />
            </div>
            <div className={`${styles.radioLabelWrapper}`}>
              <label className={`${styles.accordionLabel}`} htmlFor="AmazonPay">
                {"Amazon Pay"}
              </label>
            </div>
          </div>
        </div>
        <div className={styles.payAction}>
          <p>{lang === "en"
                ? enPhrase["btnMakePay"]
                : arPhrase["btnMakePay"]}</p>
          <img src="/assets/img/payment/arrow.svg" alt="arrow" />
        </div>
      </div>

      <div className={styles.payAccordion}>
        <div className={styles.summaryAccordion}>
          <div className={styles.payHead}>
            <div className={styles.logosWrapper}>
              <img src="/assets/img/payment/GooglePay.svg" alt="Google Pay" />
            </div>
            <div className={`${styles.radioLabelWrapper}`}>
              <label className={`${styles.accordionLabel}`} htmlFor="GooglePay">
                {"Google Pay"}
              </label>
            </div>
          </div>
        </div>
        <div className={styles.payAction}>
          <p>{lang === "en"
                ? enPhrase["btnMakePay"]
                : arPhrase["btnMakePay"]}</p>
          <img src="/assets/img/payment/arrow.svg" alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
