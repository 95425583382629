import React, { useContext } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";


const ConfirmPayment = ({ claimedValue, setOpenModal, service }) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  return (
    <div className="relative w-full p-4 m-auto ">
      <div
        onClick={() => setOpenModal(false)}
        className={`w-[48px] h-[48px] rounded-[16px] bg-[#f2eccf] p-[18px] flex items-center justify-center absolute  top-[16px] ${lang === "en" ? "right-[16px]" : "left-[16px]"
          }`}
      >
        <p className="w-[32px] h-[32px ] flex items-center justify-center font-bold cursor-pointer">
          X
        </p>
      </div>
      <p className="mb-8 text-2xl text-[#333333] font-bold text-center">
        {lang === "en"
          ? enPhrase["btnConfirmInvoicePayment"]
          : arPhrase["btnConfirmInvoicePayment"]}
      </p>
      <p className="mb-[16px] text-base text-[#333333] text-center">
        {lang === "en"
          ? enPhrase["lblEnsureamountdueforapplication"]
          : arPhrase["lblEnsureamountdueforapplication"]}
      </p>
      <div className="flex justify-between items-center mb-2 px-4 py-[16px] bg-[#E4EAF5] rounded-2">
        <p className="text-xs text-[#43528A]">
          {lang === "en"
            ? enPhrase["lblApplicationTypeDot"]
            : arPhrase["lblApplicationTypeDot"]}
          :
        </p>
        <p className="text-base text-[#43528A] font-bold">
          {service?.serviceCategory}
        </p>
      </div>
      <div className="flex justify-between items-center mb-6 px-4 py-[16px] bg-[#F2ECCF] rounded-2">
        <p className="text-xs text-[#815327]"> {
          lang === "en"
            ? enPhrase["lblDueAmount"]
            : arPhrase["lblDueAmount"]
        }</p>
        {/* <p className="text-2xl text-[#815327] font-bold">{claimedValue}</p> */}
        <p className="flex items-start gap-2 leading-normal">
          <span className="text-2xl text-[#815327] font-bold">
            {claimedValue}
          </span>
          <span className="text-sm text-[#815327]">
            {
              lang === "en"
                ? enPhrase["lblCurrency"]
                : arPhrase["lblCurrency"]
            }
          </span>
        </p>
      </div>
      <button
        className="flex flex-row justify-center items-center gap-2 w-full h-[48px] text-sm font-bold bg-[#B68A35] px-3 rounded-3 text-white"
        onClick={() => window.location.replace("https://www.google.com/")}
      >
        {/* <img
          src="/assets/img/button/add.svg"
          alt="logout"
          className="w-5 h-5"
        /> */}
        <span>
          {lang === "en" ? enPhrase["btnPayNow"] : arPhrase["btnPayNow"]}
        </span>
      </button>
    </div>
  );
};

export default ConfirmPayment;
