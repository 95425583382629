import React from "react";

const PaymentElectronic = ({ service, lang, arPhrase, enPhrase,setOpenModal }) => {
  return (
    <div className={`w-full md:w-[50%] border-[1px] border-solid border-[#E6D7A2] rounded-[8px] bg-[#fff] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] p-[24px] h-auto ${lang === "en" ? "md:!h-[200px]":"md:!h-[182px]"}`}>
      <div className="flex flex-col md:flex-row items-start justify-between mb-[24px]">
        <div className="flex flex-col gap-[5px] ">
          <p className="text-[20px] font-Almarai font-[700] text-[#292724]">
            {lang === "en"
              ? enPhrase["lblPaymentElectronic"]
              : arPhrase["lblPaymentElectronic"]}
          </p>

          <p className={`text-[16px] font-Almarai font-[400] text-[#666] ${lang === "en" && "max-w-[300px]"}`}>
            {lang === "en"
              ? enPhrase["lblPaymentElectronicDetails"]
              : arPhrase["lblPaymentElectronicDetails"]}
          </p>
        </div>
        <div className="flex items-center gap-[16px] md:gap-[8px]">
          <img src="/assets/img/payment/visa.svg" alt="" className="" />
          <img src="/assets/img/payment/master-card.svg" alt="" className="" />
        </div>
      </div>
      <button className="w-full flex items-center justify-center bg-[#B68A35] rounded-[4px] h-[48px] p-[8px]" 
      // onClick={()=>setOpenModal(true)}
      onClick={() => window.location.replace("https://www.google.com/")}
      >
        <p className="text-[16px] font-Almarai font-[700] text-[#fff] leading-normal">
        {lang === "en"
              ? enPhrase["btnPayNow"]
              : arPhrase["btnPayNow"]}
        </p>
      </button>
    </div>
  );
};

export default PaymentElectronic;
