import React from "react";
import { USDollar } from "../../../utils/helpers";
import ServiceStatus from "../../serviceStatus";
const ServiceDetailsPart1 = ({ service, lang, arPhrase, enPhrase }) => {
  const {
    id,
    serviceCategory,
    status,
    serviceStatusId,
    caseNumber,
    caseTypeName,
    claimedValue,
  } = service;

  
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center">
        <div className="px-4 py-6  w-full bg-white flex flex-col md:flex-row flex-wrap gap-6 content-between justify-between border-2 border-solid border-[#EEE2D0] rounded shadow-[0px_10px_40px_#361E1214]">
          <div className="flex flex-col content-between justify-between gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en"
                ? enPhrase["lblApplicationNumber"]
                : arPhrase["lblApplicationNumber"]}
            </p>
            <p className="text-sm font-bold text-[#010202]">{id}</p>
          </div>

          <div className="flex flex-col content-between justify-between gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en"
                ? enPhrase["lblApplicationType"]
                : arPhrase["lblApplicationType"]}
            </p>
            <p className="text-sm font-bold text-[#010202]">
              {serviceCategory}
            </p>
          </div>
          <div className="md:flex flex-col content-between  justify-start gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en" ? enPhrase["lblStatus"] : arPhrase["lblStatus"]}
            </p>
            <p className="flex gap-2 items-center text-sm font-bold text-[#010202]">
              <ServiceStatus serviceStatusId={serviceStatusId} />
              <span>{status}</span>
            </p>
          </div>
          <div className="flex flex-col content-between justify-between gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en"
                ? enPhrase["lblCaseNumber"]
                : arPhrase["lblCaseNumber"]}
            </p>
            <p className="text-sm font-bold text-[#010202] max-w-[180px] truncate">
              {caseNumber}
            </p>
          </div>
          <div className="flex flex-col content-between justify-between gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en"
                ? enPhrase["lblCaseType"]
                : arPhrase["lblCaseType"]}
            </p>
            <p className="text-sm font-bold text-[#010202]">{caseTypeName}</p>
          </div>
          {/* <div className="flex flex-col  content-between justify-between gap-1 min-w-[30%]">
            <p className="text-[12px] font-normal text-[#010202]">
              {lang === "en"
                ? enPhrase["lblClaimAmount"]
                : arPhrase["lblClaimAmount"]}
              :
            </p>
            <p className="text-[16px] md:text-sm font-bold text-[#a98031] md:text-[#010202]">
              <span>{USDollar.format(claimedValue)} </span>
              <span>{lang === "en" ? "AED" : "درهم"}</span>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsPart1;
