import React, { useContext } from "react";
import { PhrasesContext } from "../../context/phrasesContext";
import { useNavigate } from "react-router-dom";

const SuccessUploadReciept = ({
  setSuccessUploadReciept,
  setReceiptStatus,
  service
}) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const navigate = useNavigate();

  return (
    <div className="px-[24px] pt-[32px] pb-[24px] flex flex-col items-center justify-center">
      <img src="/assets/img/SuccessUpload.svg" alt="" className=" mb-[24px]" />
      <h2 className="mb-[24px] text-[18px] sm:text-[24px] text-[#010202] leading-[1.67] font-Almarai text-center font-bold">
        {/* لقد تم استلام الايصال بنجاح! */}
        {lang === "en"
          ? enPhrase["lblSuccessUploadReciept"]
          : arPhrase["lblSuccessUploadReciept"]}
      </h2>
      <p className="mb-[40px] text-[14px] sm:text-[16px] text-[#666666] leading-3-[1.5] text-center font-Almarai h-[48px]">
        {/* تم استلام ايصال الدفع بنجاح. سيقوم احد ممثلينا بمراجعة طلبك والرد عليكم
        في اسرع وقت. */}
        {lang === "en"
          ? enPhrase["lblSuccessUploadRecieptText"]
          : arPhrase["lblSuccessUploadRecieptText"]}
      </p>
      <div className="w-full">
        <button className="h-[44px] bg-[#b68a35] w-full">
          <p
            className="text-[#fff] text-[14px] font-Almarai font-bold"
            onClick={() => {
              setSuccessUploadReciept(false);
              setReceiptStatus(false);
              // window.location.reload()
              navigate(`/order-details/${service.caseId}/${service.id}`)
            }}
          >
            {lang === "en" ? enPhrase["lblOk"] : arPhrase["lblOk"]}
          </p>
        </button>
      </div>
    </div>
  );
};

export default SuccessUploadReciept;
