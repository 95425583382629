import styles from "./styles.module.css";
import React, { useContext } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";

export default function PaymentSummary() {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  return (
    <div className={styles.PaymentSummary}>
      <div className={styles.PaymentSummaryContainer}>
        <div className={styles.PaymentSummaryBody}>
          <div className={styles.PaymentSummaryRow}>
            <h2>
              {lang === "en"
                ? enPhrase["lblCaseNumber"]
                : arPhrase["lblCaseNumber"]}
            </h2>
            <p>{"1/2014 تنفيذ مدني"}</p>
          </div>
          <div className={styles.PaymentSummaryRow}>
            <h2>
              {lang === "en"
                ? enPhrase["lblApplicationNumber"]
                : arPhrase["lblApplicationNumber"]}
            </h2>
            <p>{"2304"}</p>
          </div>
          <div className={styles.PaymentSummaryRow}>
            <h2>{"نوع الطلب"}</h2>
            <p>{"انتقال وتعيين حارس"}</p>
          </div>
          <div className={styles.PaymentSummaryRow}>
            <h2>
              {lang === "en"
                ? enPhrase["lblApplicationDetails"]
                : arPhrase["lblApplicationDetails"]}
            </h2>
            <p>
              {
                "الاستعلام عن المركبات والعقارات والأرصدة والأسهم بحد أقصى ٧ طلبات"
              }
            </p>
          </div>
          <div className={styles.PaymentSummaryRow}>
            <h2>
              {" "}
              {lang === "en"
                ? enPhrase["lblApplicationDate"]
                : arPhrase["lblApplicationDate"]}
            </h2>
            <p>{"10/09/2023"}</p>
          </div>
        </div>
      </div>
      <div className={styles.payInfo}>
        <div className={styles.payInfoLabel}>
          <p>
            {" "}
            {lang === "en"
              ? enPhrase["lblServiceValue"]
              : arPhrase["lblServiceValue"]}
          </p>
        </div>
        <div className={styles.payInfoAmount}>
          <span>{"د.إ"}</span>
          <p>{"10,000.00"}</p>
        </div>
      </div>
    </div>
  );
}
