import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Breadcrumb from "../components/breadCrumb";
import TableMobile from "../components/case-overview/TableMobile";
import ChartBar from "../components/case-overview/charts/ChartBar";
import HeadSection from "../components/case-overview/head-section";
import Table from "../components/case-overview/table";
import Case from "../components/case-tracking/CaseDetails";
import LargeTitle from "../components/case-tracking/LargeTitle";
import MainTable from "../components/case-tracking/MainTable";
import { PhrasesContext } from "../context/phrasesContext";
import ThemeLayout from "../layouts/ThemeLayout";
import { getCaseDetails } from "../services/Common";

const CaseDetails = () => {
  const { caseid } = useParams();
  const [caseData, setCase] = useState({});

  // const lang = window.localStorage.getItem("lang");
  const [lang, setLang] = useState("");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

 

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }
    getServiceDetailsService(currentLang);
  }, []);

  const getServiceDetailsService = (currentLang) => {
    return getCaseDetails(caseid, currentLang).then((res) => {
      setCase(res);
    });
  };

  

  const openPopup = () => {};
  return (
    <ThemeLayout>
      <div className="flex flex-col w-full py-4 max-w-[1140px] px-[24px] lg:px-[0px]">
        <Breadcrumb
          title={
            lang === "en"
              ? enPhrase["lblFollowCases"]
              : arPhrase["lblFollowCases"]
          }
          last={`${
            lang === "en"
              ? `${enPhrase["lblCase"]} ${caseData?.caseFileNumber}`
              : `${caseData?.caseFileNumber} ${arPhrase["lblCase"]} `
          } `}
        />
        <Case caseData={caseData} />
        {caseData?.requester?.length > 0 && (
          <MainTable
            title={
              lang === "en"
                ? enPhrase["lblEnforcementApplicant"]
                : arPhrase["lblEnforcementApplicant"]
            }
            data={caseData?.requester}
          />
        )}
        {caseData?.representative?.length > 0 && (
          <MainTable
            title={
              lang === "en"
                ? enPhrase["lblApplicationRepresentative"]
                : arPhrase["lblApplicationRepresentative"]
            }
            data={caseData?.representative}
          />
        )}
        {caseData?.defendant?.length > 0 && (
          <MainTable
            title={
              lang === "en"
                ? enPhrase["lblDetailsOfDefenat"]
                : arPhrase["lblDetailsOfDefenat"]
            }
            data={caseData?.defendant}
          />
        )}

        {caseData?.servicesChart?.length > 0 && (
          <>
            <div className="bg-[#fff] pt-[18px] px-[24px] pb-[24px] shadow-[0_10px_40px_0px_rgba(54,30,18,0.08)] mb-[48px]">
              <HeadSection
                label={lang === "en" ? "Number of requests" : "تعداد الطلبات"}
              />
              <ChartBar servicesDetails={caseData} isSubChart />
            </div>
          </>
        )}

        {caseData?.services?.length > 0 && (
          <>
            <div>
              <LargeTitle
                title={
                  lang === "en"
                    ? enPhrase["lblAllApplications"]
                    : arPhrase["lblAllApplications"]
                }
              />
              {window && window.innerWidth <= 1024 ? (
                <TableMobile servicesData={caseData?.services} isSubTable />
              ) : (
                <Table servicesData={caseData?.services} isSubTable />
              )}
            </div>
          </>
        )}
      </div>
    </ThemeLayout>
  );
};

export default CaseDetails;
