import React, { useContext } from "react";
import { PhrasesContext } from "../../context/phrasesContext";
import "./style.css";

const Help = ({ ContactUsHandler }) => {
  const lang = window.localStorage.getItem("lang");

  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  return (
    <div className="w-[327px] h-[383px] px-[0] pt-[0] rounded-[24px] border-[1px] border-solid  bg-[#ede2cc] m-auto absolute top-[88%] md:top-[80%] lg:top-[85%] left-0 right-0">
      <div className="w-[327px] h-[258.3px] px-[24px] pt-[32px] pb-[98px] bg-[#fff] half_circle_mobile">
        <p className="text-[28px] text-[#212121] font-Almarai font-bold w-[279px] text-center mb-[16px]">
          {lang === "en" ? enPhrase["lblNeedHelp"] : arPhrase["lblNeedHelp"]}
        </p>
        <p className="text-[16px] text-[#888888] font-Almarai  w-[279px] text-center">
          {lang === "en"
            ? enPhrase["lblshareOpinions"]
            : arPhrase["lblshareOpinions"]}
        </p>
      </div>

      <div className="pt-[32.7px] px-[84px] pb-[40px]">
        <button
          onClick={() => {
            ContactUsHandler();
          }}
          className="w-[158px] h-[52px] px-[32px] py-[18px] rounded-[8px] bg-[#b68a35] flex items-center justify-end"
        >
          <p className="w-[94px] h-[18px] text-[14px] text-[#fff] font-Almarai">
            {lang === "en"
              ? enPhrase["lblRequestHelp"]
              : arPhrase["lblRequestHelp"]}
          </p>
        </button>
      </div>
    </div>
  );
};

export default Help;
