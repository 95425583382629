import React from "react";
const ServiceStatus = ({ serviceStatusId }) => {
  return (
    <>
      {serviceStatusId === 1 ? (
        <img
          src="/assets/img/case-status/Pending.svg"
          alt=""
          className="w-[12px] h-[12px]"
        />
      ) : serviceStatusId === 2 ? (
        <img
          src="/assets/img/case-status/MoreData.svg"
          alt=""
          className="w-[12px] h-[12px]"
        />
      ) : serviceStatusId === 3 ? (
        <img
          src="/assets/img/case-status/UnderPayment.svg"
          alt=""
          className="w-[12px] h-[12px]"
        />
      ) : serviceStatusId === 4 ? (
        <img
          src="/assets/img/case-status/UnderProgress.svg"
          alt=""
          className="w-[12px] h-[12px]"
        />
      ) : serviceStatusId === 5 ? (
        <img
          src="/assets/img/case-status/Ended.svg"
          alt=""
          className="w-[12px] h-[12px]"
        />
      ) : serviceStatusId === 6 ? (
        <img
          src="/assets/img/case-status/UnableToProgress.svg"
          alt=""
          className="w-[12px] h-[12px]"
        />
      ) : serviceStatusId === 7 ? (
        <img
          src="/assets/img/case-status/PartialStoped.svg"
          alt=""
          className="w-[12px] h-[12px]"
        />
      ) : null}
    </>
  );
};

export default ServiceStatus;
