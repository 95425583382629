import React from "react";
import { Link } from "react-router-dom";
const ProfileItem = ({ text, icon, href, arrow, click,lang }) => {
  return (
    <Link to={href} className="flex w-[100%]">
      <button
        className="flex flex-row items-center justify-between w-[100%] h-[52px] gap-2 transition-all duration-300 px-4 md:px-1 lg:px-4 xl:px-8 bg-[#FFF] border-1 border-[#E6D7A2] rounded-3 hover:bg-[#F9F7ED] focus:outline-none"
        onClick={() => {
          if (click) click();
          return false;
        }}
      >
        <div className="flex flex-row items-center gap-2">
          {icon && <img src={icon} alt="logout" className="w-5 h-5" />}
          <span className="text-sm text-[#010202]">{text}</span>
        </div>
        <img src={arrow} alt="arrow" className={`w-5 h-5 ${lang === "en" ? "rotate-180":""}`} />
      </button>
    </Link>
  );
};

export default ProfileItem;
