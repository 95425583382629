import React from 'react';
import ThemeLayout from "../layouts/ThemeLayout";
import PaymentPage from "../components/payment";
export default function Payment () {
  
    return (
      <ThemeLayout>
      <PaymentPage />
      </ThemeLayout>
    );
}