import React from 'react';
import ThemeLayout from "../layouts/ThemeLayout";

export default function CaseTracking () {
  
    return (
      <ThemeLayout>
      HOLA
      </ThemeLayout>
    );
}