import React from "react";
const LargeTitle = ({ title, hasLabel, nullmargin }) => {
  return (
    <div className="flex items-center gap-4">
      <h3
        className={`text-xl font-Almarai font-bold text-[#010202] ${
          nullmargin ? "mb-0" : "mb-8"
        }`}
      >
        {title}
      </h3>
      {hasLabel && (
        <p className="flex leading-5 h-[20px]">
          <span className="text-[12px] text-[#FFFFFF] bg-[#6C4527] px-2 rounded-5">
            الأرشيف
          </span>
        </p>
      )}
    </div>
  );
};

export default LargeTitle;
