import React from 'react';
import NewsMobile from '../components/NewsMobile';

const News = ({ lang, enPhrase, arPhrase }) => {
  const NewsData = [
    {
      title:
        'خبراء في “جيتكس 2023” يستعرضون الحلول الرائدة في مجال الأمن السيبراني لمعالجة التهديدات',
      des: 'دور التحول الرقمي في تعزيز أطر التعاون عبر جميع القطاعات في دولة الإمارات',
      img: '/assets/img/home/news.png',
    },
    {
      title:
        'خبراء في “جيتكس 2023” يستعرضون الحلول الرائدة في مجال الأمن السيبراني لمعالجة التهديدات',
      des: 'دور التحول الرقمي في تعزيز أطر التعاون عبر جميع القطاعات في دولة الإمارات',
      img: '/assets/img/home/news.png',
    },
    {
      title:
        'خبراء في “جيتكس 2023” يستعرضون الحلول الرائدة في مجال الأمن السيبراني لمعالجة التهديدات',
      des: 'دور التحول الرقمي في تعزيز أطر التعاون عبر جميع القطاعات في دولة الإمارات',
      img: '/assets/img/home/news.png',
    },
  ];
  return (
    <div id='news'>
      {window && window.innerWidth < 1024 ? (
        <NewsMobile NewsData={NewsData} lang={lang} />
      ) : (
        <div class="w-full flex flex-row items-center justify-center mb-[80px] mt-[144px] gap-[24px] px-[24px]">
          <div
            className='w-[312px] h-[445px] border-[1px] border-solid border-[#e6d7a2] rounded-[24px] bg-[#f9f7ed] px-[32px] pt-[32px]'
            style={{
              backgroundImage: 'url(/assets/img/home/bgNews.svg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
            }}>
            <p className='text-[40px] font-Almarai font-bold max-w-[248px]'>
              {lang === 'en' ? 'Media Center' : 'المركز الإعلامي'}
            </p>
          </div>
          {NewsData?.map((item, index) => {
            return (
              <div
                key={index}
                className='w-[312px] h-[445px] border-[1px] border-solid border-[#e6d7a2] rounded-[24px] bg-[#f9f7ed]'>
                <div>
                  <img src={item?.img} alt={item?.title} />
                </div>
                <div className='px-[24px] pt-[24px] '>
                  <h2 className='text-[20px] font-Almarai font-bold text-[#361e12] mb-[16px]'>
                    {item?.title}
                  </h2>
                  <p className='text-[16px] font-Almarai text-[#361e12]'>
                    {item?.des}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default News;
