import React, { useContext } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";

const SuccessHappinessMessage = ({ setHappinessSuccessMessage }) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  return (
    <div className="p-[32px] flex flex-col items-center">
      <img
        src="/assets/img/happiness/check.svg"
        alt=""
        className="w-[42.2px] h-[42.2px] mb-[32px]"
      />
      <p className="text-[14px] xsm:text-[16px] md:text-[20px] text-[#010202] font-Almarai font-bold text-center h-auto mb-[11px]">
        {lang === "en"
          ? enPhrase["lblSuccessSurvay"]
          : arPhrase["lblSuccessSurvay"]}
      </p>

      <p className="text-[12px] xsm:text-[14px] text-[#666666] font-Almarai font-bold text-center mb-[34px] h-auto leading-[1.57]">
        {lang === "en"
          ? enPhrase["lblSuccessSurvaytext"]
          : arPhrase["lblSuccessSurvaytext"]}
      </p>
      <button
        className="w-full h-[46px]  rounded-[8px] bg-[#b68a35]"
        onClick={() => setHappinessSuccessMessage(false)}
      >
        <p className="text-[12px] md:text-[14px] text-[#fff] leading-[2.86] font-Almarai font-bold">
          {lang === "en" ? "OK" : "حسنا"}
        </p>
      </button>
    </div>
  );
};

export default SuccessHappinessMessage;
