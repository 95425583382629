import React, { useContext, useEffect, useState } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";
import { RequesterContext } from "../../../context/requesterContext";
import { AddSurvey } from "../../../services/Common";
import "./style.css";

const LevelHappinessLatest = (props) => {
  // const lang = window.localStorage.getItem("lang");
  // const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [fisrtQuestionID, setFisrtQuestionID] = useState("");
  const [secondQuestionID, setSecondQuestionID] = useState("");
  const [thirdQuestionID, setThirdQuestionID] = useState("");
  const [answerFisrtQuestionID, setAnswerFisrtQuestionID] = useState("");
  const [answerSecondQuestionID, setAnswerSecondQuestionID] = useState("");
  const [answerThirdQuestionID, setAnswerThirdQuestionID] = useState("");
  const [firstNote, setFirstNote] = useState("");
  const [secondNote, setSecondNote] = useState("");
  const [thirdNote, setThirdNote] = useState("");
  const [generalNote, setGeneralNote] = useState("");
 

  const [active, setActive] = useState(false);
  const [secondActive, setSecondActive] = useState(false);
  const [thirdActive, setThirdctive] = useState(false);
  const [lang, setLang] = useState("ar");

  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [requesterInfo] =useContext(RequesterContext)
  const {
    setHappinessMeter,
    setOpenModal,
    setSurveyShowModal,
    getSurveyService,
    answersList,
    surveyDetails,
    setHappinessSuccessMessage,
  } = props;

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    let currentLang = lang;
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
      currentLang = "en";
    } else {
      document.body.dir = "rtl";
      setLang("ar");
      currentLang = "ar";
    }

    // getPhrasesService();
    // getSurveyService(currentLang);
    // getRequesterService(currentLang);
  }, []);

  // useEffect(() => {
  //   getSurveyService(lang);
  //   getRequesterService();
  // }, []);
  // const getRequesterService = (currentLang) => {
  //   return getRequester(currentLang).then((res) => {
  //     setRequester(res);
  //   });
  // };

  const AnswerHandler = (id) => {
    setFisrtQuestionID(5);
    setAnswerFisrtQuestionID(id);
    if (id === 1) {
      setActive(true);
    }
    if (id === 2) {
      setActive("yellow");
    }
    if (id === 3) {
      setActive("red");
    }

    
  };

  const SecondAnswerHandler = (id) => {
    setSecondQuestionID(6);
    setAnswerSecondQuestionID(id);
    if (id === 1) {
      setSecondActive(true);
    }
    if (id === 2) {
      setSecondActive("yellow");
    }
    if (id === 3) {
      setSecondActive("red");
    }
  };

  const ThirdAnswerHandler = (id) => {
    setThirdQuestionID(7);
    setAnswerThirdQuestionID(id);
    if (id === 1) {
      setThirdctive(true);
    }
    if (id === 2) {
      setThirdctive("yellow");
    }
    if (id === 3) {
      setThirdctive("red");
    }
  };

  console.log("DataSurvay", {
    userId: requesterInfo.id,
    surveyNote: generalNote,
    surveyQuestion: [
      {
        questionId: fisrtQuestionID,
        categoryId: 0,
        answerId: answerFisrtQuestionID,
        note: firstNote,
      },
      {
        questionId: secondQuestionID,
        categoryId: 0,
        answerId: answerSecondQuestionID,
        note: secondNote,
      },
      {
        questionId: thirdQuestionID,
        categoryId: 0,
        answerId: answerThirdQuestionID,
        note: thirdNote,
      },
    ],
  });

  const HandlerAddSurvay = () => {
    let surveySchema = {
      userId: requesterInfo.id,
      surveyNote: generalNote,
      surveyQuestion: [
        {
          questionId: fisrtQuestionID,
          categoryId: 0,
          answerId: answerFisrtQuestionID,
          note: firstNote,
        },
        {
          questionId: secondQuestionID,
          categoryId: 0,
          answerId: answerSecondQuestionID,
          note: secondNote,
        },
        {
          questionId: thirdQuestionID,
          categoryId: 0,
          answerId: answerThirdQuestionID,
          note: thirdNote,
        },
      ],
    };

    AddSurvey(surveySchema).then(
      (res) => {
       
        if (res?.success === true) {
          setOpenModal(false);
          setHappinessSuccessMessage(true);
        }
      },
      (err) => {
        console.log("Fail Add Survay !!!!!");
      }
    );
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center  mt-[8px] relative">
        <div
          className="absolute top-0 left-[-16px] rounded-[16px] bg-[#f2eccf] p-[8px] cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <img src="/assets/img/close.svg" alt="close-icon" />
        </div>
        <div className="flex items-center justify-center gap-[20px]  mt-[40px] mb-[32px] ">
          <div className="w-[47.9px] h-[47.9px]">
            <img
              src="/assets/img/happiness/logo.svg"
              alt="logo"
              className="w-[100%] h-[100%]"
            />
          </div>
          <div className="text-center">
            <h2 className="text-[20px] font-bold font-Almarai text-[#010202]">
              {lang === "en"
                ? enPhrase["lblHappiness"]
                : arPhrase["lblHappiness"]}
            </h2>
            <p className="text-[18px] font-SFProDisplay leading-[1.22] text-[#807e7e] mt-[2px]">
              {"Happiness Meter"}
            </p>
          </div>
        </div>

        <div className="text-center">
          <h2 className="text-[20px] font-bold font-Almarai text-[#010202]">
            {surveyDetails[0]?.categoryName}
          </h2>
          <p className="text-[14px] font-bold font-Almarai text-[#666666] leading-[1.57] mt-[8px] ">
            {lang === "en"
              ? enPhrase["lblPleaseAnswer"]
              : arPhrase["lblPleaseAnswer"]}
          </p>
        </div>

        <div
          className={`bg-[#fbf9f5] w-full flex flex-col items-center justify-center pt-[18px] px-0 md:!px-[24px] pb-[43px] mt-[32px]`}
        >
          <div>
            <h2 className="text-[18px] text-[#010202] font-Almarai font-bold">
              {surveyDetails[0]?.questions[0]?.question}
            </h2>
          </div>
          <div className="flex w-[244px] items-center justify-center gap-[12px] mt-[16px]">
            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6] ${
                active === true ? "bg-[#2e7e31]" : "bg-[#fff]"
              } flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => AnswerHandler(answersList[0]?.id)}
            >
              {active === true ? (
                <img
                  src="/assets/img/happiness/Active-happiness.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/happiness_158420.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  active === true ? "text-[#fff]" : "text-[#010202]"
                } text-center`}
              >
                {answersList[0]?.answer}
              </p>
            </div>

            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6] ${
                active === "yellow" ? "bg-[#fcbc34]" : "bg-[#fff]"
              }  flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => AnswerHandler(answersList[1]?.id)}
            >
              {active === "yellow" ? (
                <img
                  src="/assets/img/happiness/Active-straight.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/straight_142315.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  active === "yellow" ? "text-[#010202]" : "text-[#010202]"
                } `}
              >
                {answersList[1]?.answer}
              </p>
            </div>

            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6] ${
                active === "red" ? "bg-[#ff3131]" : "bg-[#fff]"
              } flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => AnswerHandler(answersList[2]?.id)}
            >
              {active === "red" ? (
                <img
                  src="/assets/img/happiness/Active-sad.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/sad_3852217.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  active === "red" ? "text-[#fff]" : "text-[#010202]"
                }  w-[65px] text-center`}
              >
                {answersList[2]?.answer}
              </p>
            </div>
          </div>

          <div className="w-full h-[66px] mt-[16px] px-[24px]">
            <textarea
              maxLength={300}
              className="w-[100%] h-[100%] text-[14px] font-Almarai text-black rounded-[4px] border-solid border-[1px] border-[#d6d6d6] resize-none  focus:border-[#b68a35]"
              placeholder={
                lang === "en"
                  ? enPhrase["lblFeedback"]
                  : arPhrase["lblFeedback"]
              }
              onChange={(e) => setFirstNote(e.target.value)}
            />

            <p
              className={`text-[10px] text-[#666] font-Almarai ${
                firstNote?.length === 300 ? "text-red-600" : "text-[#666]"
              }`}
            >
              {lang === "en"
                ? enPhrase["lblMaxLetter"]
                : arPhrase["lblMaxLetter"]}
            </p>
          </div>
        </div>

        <div
          className={`bg-[#fbf9f5] w-full flex flex-col items-center justify-center pt-[18px] px-0 md:!px-[24px] pb-[43px] mt-[32px]`}
        >
          <div>
            <h2 className="text-[18px] text-[#010202] font-Almarai font-bold">
              {surveyDetails[0]?.questions[1]?.question}
            </h2>
          </div>
          <div className="flex w-[244px] items-center justify-center gap-[12px] mt-[16px]">
            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6]  ${
                secondActive === true ? "bg-[#2e7e31]" : "bg-[#fff]"
              } flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => SecondAnswerHandler(answersList[0]?.id)}
            >
              {secondActive === true ? (
                <img
                  src="/assets/img/happiness/Active-happiness.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/happiness_158420.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  secondActive === true ? "text-[#fff]" : "text-[#010202]"
                }`}
              >
                {answersList[0]?.answer}
              </p>
            </div>

            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6] ${
                secondActive === "yellow" ? "bg-[#fcbc34]" : "bg-[#fff]"
              } flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => SecondAnswerHandler(answersList[1]?.id)}
            >
              {secondActive === "yellow" ? (
                <img
                  src="/assets/img/happiness/Active-straight.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/straight_142315.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  secondActive === "yellow"
                    ? "text-[#010202]"
                    : "text-[#010202]"
                }`}
              >
                {answersList[1]?.answer}
              </p>
            </div>

            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6] ${
                secondActive === "red" ? "bg-[#ff3131]" : "bg-[#fff]"
              } flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => SecondAnswerHandler(answersList[2]?.id)}
            >
              {secondActive === "red" ? (
                <img
                  src="/assets/img/happiness/Active-sad.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/sad_3852217.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  secondActive === "red" ? "text-[#fff]" : "text-[#010202]"
                } w-[65px] text-center`}
              >
                {answersList[2]?.answer}
              </p>
            </div>
          </div>

          <div className="w-full h-[66px] mt-[16px] px-[24px]">
            <textarea
              maxLength={300}
              className="w-[100%] h-[100%] text-[14px] font-Almarai text-black rounded-[4px] border-solid border-[1px] border-[#d6d6d6] resize-none  focus:border-[#b68a35]"
              placeholder={
                lang === "en"
                  ? enPhrase["lblFeedback"]
                  : arPhrase["lblFeedback"]
              }
              onChange={(e) => setSecondNote(e.target.value)}
            />

            <p
              className={`text-[10px] text-[#666] font-Almarai ${
                secondNote?.length === 300 ? "text-red-600" : "text-[#666]"
              }`}
            >
              {lang === "en"
                ? enPhrase["lblMaxLetter"]
                : arPhrase["lblMaxLetter"]}
            </p>
          </div>
        </div>

        <div
          className={`bg-[#fbf9f5] w-full flex flex-col items-center justify-center pt-[18px] px-0 md:!px-[24px] pb-[43px] mt-[32px]`}
        >
          <div>
            <h2 className="text-[18px] text-[#010202] font-Almarai font-bold">
              {surveyDetails[0]?.questions[2]?.question}
            </h2>
          </div>
          <div className="flex w-[244px] items-center justify-center gap-[12px] mt-[16px]">
            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6] ${
                thirdActive === true ? "bg-[#2e7e31]" : "bg-[#fff]"
              } flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => ThirdAnswerHandler(answersList[0]?.id)}
            >
              {thirdActive === true ? (
                <img
                  src="/assets/img/happiness/Active-happiness.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/happiness_158420.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  thirdActive === true ? "text-[#fff]" : "text-[#010202]"
                }`}
              >
                {answersList[1]?.answer}
              </p>
            </div>

            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6] ${
                thirdActive === "yellow" ? "bg-[#fcbc34]" : "bg-[#fff]"
              }  flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => ThirdAnswerHandler(answersList[1]?.id)}
            >
              {thirdActive === "yellow" ? (
                <img
                  src="/assets/img/happiness/Active-straight.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/straight_142315.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  thirdActive === "yellow" ? "text-[#010202]" : "text-[#010202]"
                }`}
              >
                {answersList[1]?.answer}
              </p>
            </div>

            <div
              className={`w-[76px] h-[68px] border-[1px] border-solid border-[#d6d6d6] ${
                thirdActive === "red" ? "bg-[#ff3131]" : "bg-[#fff]"
              }  flex flex-col items-center justify-center py-[8px] px-[10px] rounded-[8px] cursor-pointer`}
              onClick={(id) => ThirdAnswerHandler(answersList[2]?.id)}
            >
              {thirdActive === "red" ? (
                <img
                  src="/assets/img/happiness/Active-sad.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              ) : (
                <img
                  src="/assets/img/happiness/sad_3852217.svg"
                  alt=""
                  className="w-[32px] h-[32px]"
                />
              )}
              <p
                className={`text-[14px] font-bold font-Almarai ${
                  thirdActive === "red" ? "text-[#fff]" : "text-[#010202]"
                } w-[65px] text-center`}
              >
                {answersList[2]?.answer}
              </p>
            </div>
          </div>

          <div className="w-full h-[66px] mt-[16px] px-[24px]">
            <textarea
              maxLength={300}
              className="w-[100%] h-[100%] text-[14px] font-Almarai text-black rounded-[4px] border-solid border-[1px] border-[#d6d6d6] resize-none  focus:border-[#b68a35]"
              placeholder={
                lang === "en"
                  ? enPhrase["lblFeedback"]
                  : arPhrase["lblFeedback"]
              }
              onChange={(e) => setThirdNote(e.target.value)}
            />
            <p
              className={`text-[10px] text-[#666] font-Almarai ${
                thirdNote?.length === 300 ? "text-red-600" : "text-[#666]"
              }`}
            >
              {lang === "en"
                ? enPhrase["lblMaxLetter"]
                : arPhrase["lblMaxLetter"]}
            </p>
          </div>
        </div>

        <div
          className={`bg-[#fbf9f5] w-full flex flex-col items-center justify-center pt-[18px] px-0 md:!px-[24px] pb-[43px] mt-[32px]`}
        >
          <div>
            <h2 className="text-[18px] text-[#010202] font-Almarai font-bold">
              {lang === "en" ? enPhrase["lblNotes"] : arPhrase["lblNotes"]}
            </h2>
          </div>

          <div className="w-full h-[66px] mt-[16px] px-[24px]">
            <textarea
              maxLength={300}
              className="w-[100%] h-[100%] text-[14px] font-Almarai text-black rounded-[4px] border-solid border-[1px] border-[#d6d6d6] resize-none  focus:border-[#b68a35]"
              placeholder={
                lang === "en"
                  ? enPhrase["lblFeedback"]
                  : arPhrase["lblFeedback"]
              }
              onChange={(e) => setGeneralNote(e.target.value)}
            />
            <p
              className={`text-[10px] text-[#666] font-Almarai ${
                generalNote?.length === 300 ? "text-red-600" : "text-[#666]"
              }`}
            >
              {lang === "en"
                ? enPhrase["lblMaxLetter"]
                : arPhrase["lblMaxLetter"]}
            </p>
          </div>
        </div>

        <button
          className={`w-full h-[46px] mt-[24px] rounded-[8px] ${
            fisrtQuestionID === "" ||
            secondQuestionID === "" ||
            thirdQuestionID === ""
              ? "bg-gray-300 disabled:"
              : "bg-[#b68a35] "
          }`}
          onClick={() => HandlerAddSurvay()}
        >
          <p className="text-[14px] text-[#fff] leading-[2.86] font-Almarai font-bold">
            {lang === "en" ? enPhrase["lblSend"] : arPhrase["lblSend"]}
          </p>
        </button>
      </div>
    </>
  );
};

export default LevelHappinessLatest;
