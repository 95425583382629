import React from "react";

const DownloadApp = ({ lang, enPhrase, arPhrase }) => {
  const getUri = () => {
    return "https://apps.apple.com/ae/app/eje/id6448539365";
  };

  const getUriAndroid = () => {
    return "https://play.google.com/store/apps/details?id=ae.eje.app";
  };
  return (
    <div className="xl:mt-[170px] xl:m-auto mb-[143px] xl:mb-[144px] w-full  !px-[24px]">
      <div
        className={`max-w-[327px] lg:max-w-[500px] xl:max-w-[1320px] h-[603px] xl:h-[344px] relative rounded-2xl m-auto bg-gradient-to-t from-[#b68a3594] to-[#b68a35]  ${
          lang === "en"
            ? "xl:bg-gradient-to-l from-[#b68a3594] to-[#a57921]"
            : "xl:bg-gradient-to-r from-[#b68a3594] to-[#a57921]"
        } pt-[40px] pb-[89px] px-[24px] xl:py-[96px] xl:px-[112px]`}
      >
        <div className="bg-background-eje w-full lg:max-w-[500px] xl:max-w-[1320px] h-[603px] xl:h-[344px] absolute top-0 left-0 rounded-2xl "></div>
        <div className="flex flex-col items-center xl:items-start">
          <div className="flex flex-col items-center justify-center xl:items-start xl:justify-start xl:w-[545px] ">
            <div className="flex flex-col items-center justify-center xl:justify-start xl:items-start">
              <p className="text-[26px] max-w-[279px] lg:max-w-[420px] xl:max-w-[562px] !xl:text-start xl:text-[28px] lg:text-[30px] 2xl:text-[31px] text-[#f9f7ed] font-Almarai font-bold text-center mb-[32px]">
                {lang === "en"
                  ? enPhrase["lblDownloadEmirateJudgment"]
                  : arPhrase["lblDownloadEmirateJudgment"]}
              </p>

              {/* <p className="  text-[16px] max-w-[279px] xl:max-w-[536px]  text-center xl:text-[18px] text-[#f9f7ed] font-Almarai mb-[32px]">
                {lang === "en"
                  ? enPhrase["lblManageLegalApps"]
                  : arPhrase["lblManageLegalApps"]}
              </p> */}
            </div>
            <div className="flex gap-[16px]">
              <img
                onClick={() => {
                  window.open(getUri(), "_blank");
                }}
                src={`${
                  lang === "en"
                    ? "/assets/img/home/AppStore.svg"
                    : "/assets/img/home/downloadAppIOS.png"
                }`}
                alt="AppStore-icon"
                className="w-[128px] h-[38px] xl:w-[160px] xl:h-[48px] cursor-pointer z-10"
              />
              <img
                onClick={() => {
                  window.open(getUriAndroid(), "_blank");
                }}
                src={`${
                  lang === "en"
                    ? "/assets/img/home/GooglePlay.svg"
                    : "/assets/img/home/downloadAppAndroid.png"
                }`}
                alt="GooglePlay-icon"
                className="w-[128px] h-[38px] xl:w-[160px] xl:h-[48px] cursor-pointer z-10"
              />
            </div>
          </div>

          <div
            className={`absolute bottom-[-50px] lg:bottom-[-85px] xl:top-[-68px] ${
              lang === "en" ? "xl:right-[56px]" : "xl:left-[56px]"
            } `}
          >
            <div className="flex items-center justify-center gap-[56px]">
              <section className="w-[263px] h-[360px] xl:w-[344px] xl:h-[472px] ">
                <img src="/assets/img/home/MobileDownload.webp" alt="" />
              </section>
              <section className="hidden xl:block">
                <img
                  src="/assets/img/home/EJE_QRCode.png"
                  alt=""
                  className="w-[144px] h-[144px]"
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
