import React, { useState } from "react";
import { Link } from "react-router-dom";

const ServicesSlideItems = ({ services, lang, arPhrase, enPhrase }) => {
  const [activeCard, setActiveCard] = useState();

  
  const cardHoverHandler = (item) => {
    setActiveCard(item);
  };

  //   const handlerRequest = () => {
  //     localStorage.setItem("requestId", slideItem.id);
  //   };

  return (
    <div className="flex items-center justify-center gap-[20px] 2xl:gap-[24px]">
      {services?.map((item) => (
        <div
          className={`relative w-[140px] 2xl:w-[152px] transition-all ${
            activeCard === item?.id
              ? "scale-[1.4] 2xl:scale-[1.5] mx-[34px] px-[3px] !bg-[#fff] pb-[16px] z-10 cursor-pointer"
              : "px-[24px] bg-background-home"
          } duration-700 delay-0 overflow-hidden flex flex-col items-center  pt-[24px] h-[140px] 2xl:h-[152px]   backdrop-opacity-[.15] backdrop-blur-[15.2px] border-[0.5px] border-solid border-black-rgba bg-background-home  rounded-[24px] `}
          onMouseEnter={() => cardHoverHandler(item?.id)}
          onMouseLeave={() => setActiveCard()}
        >
          <section>
            <img
              src={`${
                activeCard === item?.id
                  ? `${item?.icon}`
                  : `${item?.selectedicon}`
              }`}
              alt=""
              className={` mb-[16px] ${
                activeCard === item?.id
                  ? "w-[38px] h-[38px]"
                  : "w-[48px] h-[48px]"
              }`}
            />
          </section>
          <p
            className={`text-[14px] ${
              activeCard === item?.id ? "text-[#000]" : "text-[#FFF]"
            } font-Almarai  text-center w-full leading-[1.43]`}
          >
            {item?.name}
          </p>
          <Link
            to="/request"
            onClick={() => {
              localStorage.setItem("requestId", item.id);
            }}
            className={`bg-[#b68a35] w-[84px] rounded-[8px] mt-[17px] absolute   ${
              activeCard === item?.id
                ? "opacity-1 visible transition-all delay-0 ease-linear duration-500 translate-y-[88px] 2xl:translate-y-[95px] flex items-center justify-center !w-[100px] 2xl:!w-[120px] !h-[22px] px-[32px]  m-auto"
                : "opacity-1  transition-all delay-0 ease-linear duration-500 translate-y-[95px] 2xl:translate-y-[100px] h-[32px] w-[84px] "
            }`}
          >
            <span
              className={`text-[13px] text-[#FFF] flex items-center justify-center !h-[12px] ${
                activeCard === item?.id
                  ? "flex items-center justify-center"
                  : "hidden"
              }`}
            >
              {lang === "en" ? enPhrase["lblRequest"] : arPhrase["lblRequest"]}
            </span>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ServicesSlideItems;
