import classNames from "classnames/bind";
import { React, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { PhrasesContext } from "../../context/phrasesContext";
import Map from "../Map";
import Location from "./Location";
const cx = classNames;

const GeoLocationsMobile = (props) => {
  const { lang, locations } = props;
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  const [activeTab, setActiveTab] = useState(0);
  const [activeLocation, setActiveLocation] = useState(null);
  const [initMarkers, setInitMarkers] = useState([]);
  const [mapMarkers, setMapMarkers] = useState(initMarkers);
  const [mapZoom, setMapZoom] = useState(8);
  const [search, setSearch] = useState("");

  const findCenter = (markers) => {
    const lats = markers.map((m) => m.lat);
    const lngs = markers.map((m) => m.lng);
    return {
      lat: (Math.min(...lats) + Math.max(...lats)) / 2,
      lng: (Math.min(...lngs) + Math.max(...lngs)) / 2,
    };
  };

  useEffect(() => {
    setInitMarkers(getInitMarkers());
  }, [locations]);

  const getInitMarkers = () => {
    const allMarkers = [];
    locations.map((item) => {
      allMarkers?.push({
        lat: parseFloat(item?.gx),
        lng: parseFloat(item?.gy),
        icon: "/assets/img/map/mark.svg",
        id: item.emirateId,
        iconW: 80,
        iconH: 80,
        iconScale: 0.7,
      });
    });

    return allMarkers;
  };

  useEffect(() => {
    setMapMarkers(initMarkers);
  }, [initMarkers]);

  const openTabHandler = (id, lat, lng) => {
    setActiveTab(id);

    const newMarks = locations.filter((item) => item.emirateName === id);

    const allChangedMarkers = [];
    newMarks?.map((item) => {
      allChangedMarkers?.push({
        lat: parseFloat(item?.gx),
        lng: parseFloat(item?.gy),
        icon: "/assets/img/map/mark.svg",
        iconW: 80,
        iconH: 80,
        id: item.emirateId,
        iconScale: 0.7,
      });
    });

    if (id === 0) {
      setMapZoom(8);
      setActiveLocation(null);
      setMapMarkers(initMarkers);
    } else {
      setMapZoom(8);
      setActiveLocation(null);
      setMapMarkers(allChangedMarkers);
    }
  };

  // Update
  // const onTabClick = (e, index) => {
  //   console.log(e);
  //   setActiveTab(index);
  // };

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  const groupByCategory = locations.reduce((group, location) => {
    const { emirateName } = location;
    group[emirateName] = group[emirateName] ?? [];
    group[emirateName].push(location);
    return group;
  }, {});

  return (
    <div
      id="offices"
      className="!mt-[80px] m-auto mb-[80px] mr-[24px] ml-[24px] md:mb-[80px] px-[24px]"
    >
      <div className="flex mb-[26px]">
        <h1 className="text-[28px] text-[#361e12] font-bold">
          {lang === "en"
            ? enPhrase["lbl_office_title"]
            : arPhrase["lbl_office_title"]}
        </h1>
      </div>
      <div className="flex flex-col border-[2px] rounded-[20px] border-[#f2eccf] bg-[#fff] h-[592px]">
        <div className="flex overflow-scroll overflow-x-hidden overflow-y-hidden h-[100px]">
          {/* <div
            className={cx(
              "pl-[20px] pr-[20px]",
              activeTab === 0 && "border-b-[4px] border-[#b68a35]"
            )}
            onClick={(index, lat, lng) => openTabHandler(0, "", "")}
          >
            <p className="text-[16px] text-[#888] font-bold">
              {lang === "en" ? enPhrase["lblAll"] : arPhrase["lblAll"]}
            </p>
          </div>
          {locations?.map((item) => {
            return (
              <div
                key={item?.emirateId}
                className={cx(
                  "flex pl-[20px] pr-[20px] min-w-fit",
                  activeTab === item?.emirateId &&
                    "border-b-[4px] border-[#b68a35]"
                )}
                onClick={(index, lat, lng) =>
                  openTabHandler(item?.emirateId, item?.gx, item?.gy)
                }
              >
                <p className="text-[15px] text-[#888] font-bold">
                  {lang === "en" ? item?.emirateName : item?.emirateNameAr}
                </p>
              </div>
            );
          })} */}

          {/* Update */}
          <Tabs
            activeTab={activeTab}
            onTabClick={onTabClick}
            hideNavBtnsOnMobile={false}
            tabsScrollAmount={1}
            animationDuration={300}
            navBtnCLickAnimationDuration={300}
            selectedAnimationDuration={300}
            navBtnAs="div"
            className="!h-[60px]"
            leftBtnIcon={
              <div className="bg-white w-[32px] rounded-[50%] border-solid border-[0.5px] border-[#888] me-[5px] mt-[0px]">
                <img
                  className={`${lang === "en" ? "" : "rotate-180"}`}
                  src="/assets/img/home/arrow.svg"
                  alt="arrow"
                />
              </div>
            }
            rightBtnIcon={
              <div className="bg-white w-[32px] rounded-[50%] border-solid border-[0.5px] border-[#888] ms-[10px] mt-[0px]">
                <img
                  src="/assets/img/home/arrow.svg"
                  className={`${lang === "en" ? "rotate-180" : ""}`}
                  alt="arrow"
                />
              </div>
            }
          >
            <Tab className="!h-[50px]">
              <div
                className={cx("flex pl-[20px] pr-[20px] ")}
                onClick={(index, lat, lng) => openTabHandler(0, "", "")}
              >
                <p className="text-[16px] text-[#888] font-bold">
                  {lang === "en" ? enPhrase["lblAll"] : arPhrase["lblAll"]}
                </p>
              </div>
            </Tab>

            {Object.entries(groupByCategory)?.map(([category, item]) => {
              return (
                <Tab key={category} className="!h-[50px]">
                  <div
                    key={category?.emirateId}
                    className={cx("flex pl-[20px] pr-[20px] ")}
                    onClick={(index, lat, lng) =>
                      openTabHandler(category, category?.gx, item?.gy)
                    }
                  >
                    <p className="text-[16px] text-[#888] font-bold">
                      {category}
                    </p>
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        </div>
        <div className="flex w-[100%] ">
          <Map
            mapH={"250px"}
            borderRadius={"0px"}
            mapW={"100%"}
            centerPoint={findCenter(mapMarkers)}
            zoom={mapZoom}
            Markers={mapMarkers}
          />
        </div>
        {/* <div className="flex flex-col overflow-scroll overflow-x-hidden max-h-[279px]">
          {locations?.map((item) => {
            return activeTab === 0 || activeTab === item?.emirateId ? (
              <Location
                title={lang === "en" ? item?.emirateName : item?.emirateNameAr}
                des={
                  lang === "en"
                    ? enPhrase[item?.address]
                    : arPhrase[item?.address]
                }
                setActiveLocation={setActiveLocation}
                activeLocation={activeLocation}
                LocationId={item?.emirateId}
                phone={item?.phone}
                email={item?.email}
                lat={parseFloat(item?.gx)}
                lng={parseFloat(item?.gy)}
                setMapMarkers={setMapMarkers}
                initMarkers={initMarkers}
                setMapZoom={setMapZoom}
                inAll={activeTab === 0}
              />
            ) : null;
          })}
        </div> */}

        {/* Update */}

        <div className="flex flex-col overflow-scroll overflow-x-hidden h-[575px]">
          {/* {locations?.map((item) => {
                return activeTab === 0 || activeTab === item?.emirateId ? (
                  <Location
                    title={item?.name}
                    des={
                      item?.address
                    }
                    setActiveLocation={setActiveLocation}
                    activeLocation={activeLocation}
                    LocationId={item?.emirateId}
                    phone={item?.phone}
                    email={item?.email}
                    lat={parseFloat(item?.gx)}
                    lng={parseFloat(item?.gy)}
                    setMapMarkers={setMapMarkers}
                    initMarkers={initMarkers}
                    setMapZoom={setMapZoom}
                    inAll={activeTab === 0}
                  />
                ) : null;
              })} */}

          {/* Updates */}

          {activeTab === 0 ? (
            <div>
              {locations?.map((item) => (
                <Location
                  title={item?.name}
                  des={item?.address}
                  setActiveLocation={setActiveLocation}
                  activeLocation={activeLocation}
                  LocationId={item?.name}
                  phone={item?.phone}
                  email={item?.email}
                  lat={parseFloat(item?.gx)}
                  lng={parseFloat(item?.gy)}
                  setMapMarkers={setMapMarkers}
                  initMarkers={initMarkers}
                  setMapZoom={setMapZoom}
                  inAll={activeTab === 0}
                  lang={lang}
                />
              ))}
            </div>
          ) : activeTab === 1 ? (
            <div>
              {Object.values(groupByCategory)[0]?.map((item) => (
                <Location
                  title={item?.name}
                  des={item?.address}
                  setActiveLocation={setActiveLocation}
                  activeLocation={activeLocation}
                  LocationId={item?.name}
                  phone={item?.phone}
                  email={item?.email}
                  lat={parseFloat(item?.gx)}
                  lng={parseFloat(item?.gy)}
                  setMapMarkers={setMapMarkers}
                  initMarkers={initMarkers}
                  setMapZoom={setMapZoom}
                  inAll={activeTab === 0}
                  lang={lang}
                />
              ))}
            </div>
          ) : activeTab === 2 ? (
            <div>
              {Object.values(groupByCategory)[1]?.map((item) => (
                <Location
                  title={item?.name}
                  des={item?.address}
                  setActiveLocation={setActiveLocation}
                  activeLocation={activeLocation}
                  LocationId={item?.name}
                  phone={item?.phone}
                  email={item?.email}
                  lat={parseFloat(item?.gx)}
                  lng={parseFloat(item?.gy)}
                  setMapMarkers={setMapMarkers}
                  initMarkers={initMarkers}
                  setMapZoom={setMapZoom}
                  inAll={activeTab === 0}
                  lang={lang}
                />
              ))}
            </div>
          ) : activeTab === 3 ? (
            <div>
              {Object.values(groupByCategory)[2]?.map((item) => (
                <Location
                  title={item?.name}
                  des={item?.address}
                  setActiveLocation={setActiveLocation}
                  activeLocation={activeLocation}
                  LocationId={item?.name}
                  phone={item?.phone}
                  email={item?.email}
                  lat={parseFloat(item?.gx)}
                  lng={parseFloat(item?.gy)}
                  setMapMarkers={setMapMarkers}
                  initMarkers={initMarkers}
                  setMapZoom={setMapZoom}
                  inAll={activeTab === 0}
                  lang={lang}
                />
              ))}
            </div>
          ) : activeTab === 4 ? (
            <div>
              {Object.values(groupByCategory)[3]?.map((item) => (
                <Location
                  title={item?.name}
                  des={item?.address}
                  setActiveLocation={setActiveLocation}
                  activeLocation={activeLocation}
                  LocationId={item?.name}
                  phone={item?.phone}
                  email={item?.email}
                  lat={parseFloat(item?.gx)}
                  lng={parseFloat(item?.gy)}
                  setMapMarkers={setMapMarkers}
                  initMarkers={initMarkers}
                  setMapZoom={setMapZoom}
                  inAll={activeTab === 0}
                  lang={lang}
                />
              ))}
            </div>
          ) : activeTab === 5 ? (
            <div>
              {Object.values(groupByCategory)[3]?.map((item) => (
                <Location
                  title={item?.name}
                  des={item?.address}
                  setActiveLocation={setActiveLocation}
                  activeLocation={activeLocation}
                  LocationId={item?.name}
                  phone={item?.phone}
                  email={item?.email}
                  lat={parseFloat(item?.gx)}
                  lng={parseFloat(item?.gy)}
                  setMapMarkers={setMapMarkers}
                  initMarkers={initMarkers}
                  setMapZoom={setMapZoom}
                  inAll={activeTab === 0}
                  lang={lang}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GeoLocationsMobile;
