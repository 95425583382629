import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PhrasesContext } from "../../../context/phrasesContext";
import { Container } from "../../utils";
import "./style.css";
const NoRequest = ({ isArchive }) => {
  const lang = window.localStorage.getItem("lang");
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  return (
    <Container>
      <div className="mainSection">
        <section className="noRequestImages">
          <img
            src="/assets/img/case-overview/norequest.svg"
            alt="No-Requests"
          />
        </section>
        {isArchive ? (
          <h2 className="noRequesttext">
            {lang === "en"
              ? enPhrase["lblNoArchiveCases"]
              : arPhrase["lblNoArchiveCases"]}
          </h2>
        ) : (
          <h2 className="noRequesttext">
            {lang === "en"
              ? enPhrase["lblThereAreApplications"]
              : arPhrase["lblThereAreApplications"]}
          </h2>
        )}
        {isArchive ? null : (
          <p className="noRequestDesc">
            {lang === "en"
              ? enPhrase["lblDearCustomerRequests"]
              : arPhrase["lblDearCustomerRequests"]}
          </p>
        )}
        {isArchive ? (
          <div className="btnAction">
            <Link
              to="/case-overview"
              className={`flex transition-all duration-300 font-medium  linkAction mt-[24px] `}
            >
              <button className="flex flex-row items-center gap-2 h-[44px] font-bold bg-[#B68A35] px-3 rounded-3 text-white actions">
                <span>
                  {lang === "en"
                    ? "Return to Case Tracking"
                    : "الرجوع الي بوابة التنفيذ"}
                </span>
              </button>
            </Link>
          </div>
        ) : (
          <div className="btnAction">
            <Link
              to="/request"
              className={`flex transition-all duration-300 font-medium  linkAction`}
            >
              <button className="flex flex-row items-center gap-2 h-[44px] font-bold bg-[#B68A35] px-3 rounded-3 text-white actions">
                <img
                  src="/assets/img/button/add.svg"
                  alt="add"
                  className="w-5 h-5"
                />
                <span>
                  {lang === "en"
                    ? enPhrase["lblNewApplication"]
                    : arPhrase["lblNewApplication"]}
                </span>
              </button>
            </Link>
          </div>
        )}
      </div>
    </Container>
  );
};

export default NoRequest;
