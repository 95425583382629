import React from "react";
import CaseOverViewComponent from "../components/case-overview";
import ThemeLayout from "../layouts/ThemeLayout";

export default function CaseOverView() {
  return (
    <ThemeLayout>
      <CaseOverViewComponent />
    </ThemeLayout>
  );
}
