import React, { useContext } from 'react';
import { PhrasesContext } from '../../../context/phrasesContext';
import SmallTitle from '../SmallTitle';

export default function MainTable({ title, data }) {
  const lang = window.localStorage.getItem('lang');
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  return (
    <div className='flex flex-col w-full mb-8'>
      <SmallTitle title={title} />
      <div className='inline-block w-full'>
        <div className='overflow-hidden xs:overflow-scroll sm:overflow-scroll md:overflow-scroll lg:overflow-hidden xl:overflow-hidden  border-2 border-solid border-[#EEE2D0] rounded shadow-[0px_10px_40px_#361E1214]'>
          <table className='min-w-full text-right text-sm font-light w-full'>
            <thead className='bg-white font-medium dark:bg-neutral-600'>
              <tr>
                <th
                  scope='col'
                  className='px-4 py-4 font-bold w-[33%] sm:w-[auto] md:w-[33%] lg:w-[33%]'>
                  {lang === 'en' ? 'Name' : 'الاسم'}
                </th>
                <th
                  scope='col'
                  className='px-4 py-4 font-bold w-[33%] sm:w-[auto] md:w-[33%] lg:w-[33%]'>
                  {/* {lang === "en" ? "Mobile Number" : " رقم التليفون"} */}
                  {lang === 'en' ? enPhrase['lblPhone'] : arPhrase['lblPhone']}
                </th>
                <th
                  scope='col'
                  className='px-4 py-4 font-bold w-[33%] sm:w-[auto] md:w-[33%] lg:w-[33%]'>
                  {lang === 'en' ? enPhrase['lblEmail'] : arPhrase['lblEmail']}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el, indx) => (
                <tr
                  className={indx % 2 === 0 ? 'bg-[#F2F2F2]' : 'bg-white'}
                  key={indx}>
                  <td className='whitespace-nowrap px-4 py-4 text-sm text-[#010202]'>
                    {el?.name}
                  </td>
                  <td className='whitespace-nowrap px-4 py-4 text-sm text-[#010202]'>
                    {el?.mobile}
                  </td>
                  <td className='whitespace-nowrap px-4 py-4 text-sm text-[#010202]'>
                    {el?.email}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
