import { React, useContext, useEffect, useState } from 'react';
import { PhrasesContext } from '../context/phrasesContext';
import AboutSection from '../layouts/AboutSection';
import DownloadApp from '../layouts/DownloadApp';
import FAQ from '../layouts/FAQ';
import Footer from '../layouts/Footer';
import GeoLocations from "../components/GeoLocations/"
import GeoLocationsMobile from "../components/GeoLocationsMobile"
import HomeBanner from '../layouts/HomeBanner';
import Nav from '../layouts/Nav';
import News from '../layouts/News';
import OurPartner from '../layouts/OurPartner';
import { getHomeLanding, getInfo } from '../services/Common';

import { LiveChatWidget } from '@livechat/widget-react';
import ChatToAdel from '../components/ChatToAdel';

const Home = () => {
  const [faqs, setFaq] = useState([]);
  const [partners, setPartners] = useState([]);
  const [services, setServicess] = useState([]);
  const [offices, setOffices] = useState([]);
  const [ticketCategories, settTicketCategories] = useState([]);
  const [media, setMedia] = useState([]);
  
  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  const [lang, setLang] = useState("ar");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});
  const [images, setImages] = useState([]);
  const [vision, setVision] = useState([]);

  useEffect(() => {
    const lang = window.localStorage.getItem('lang');
    let currentLang = lang;
    if (lang === 'en') {
      document.body.dir = 'ltr';
      setLang('en');
      currentLang = 'en';
    } else {
      document.body.dir = 'rtl';
      setLang('ar');
      currentLang = 'ar';
    }

    // getPhrasesService();
    getInfoService(currentLang);
  
  }, []);

  // const getImages = (str) => {
  //   let i = 0;
  //   const imgs = [];

  //   str.split(',').forEach((x) => {
  //     imgs.unshift({
  //       id: i,
  //       path: x,
  //     });

  //     i++;
  //   });

  //   return imgs;
  // };

  const getInfoService = (cLang) => {
    return getHomeLanding(cLang).then((res) => {
      
      setFaq((faqs) => res.faqs);
      setPartners((partners) => res.partners || []);
      setOffices((offices) => res.offices);
      setServicess((services) => res.ourServices);
      settTicketCategories((ticketCategories) => res.ticketCategories);
      setMedia((media) => res.media);
      setVision((vision) => res.vision);
    });
  };

  

  const changeLanguage2 = (lang) => {
    if (lang === 'en') {
      document.body.dir = 'rtl';
      setLang('ar');
      window.localStorage.setItem('lang', 'ar');
    } else {
      document.body.dir = 'ltr';
      setLang('en');
      window.localStorage.setItem('lang', 'en');
    }
    window.location.reload();
  };

  

  // const [office, setOffice] = useState([]);
  // const [fqa, setFaqs] = useState([]);

  // const getInfoCommon = () => {
  //   return getInfo().then((res) => {
  //     if (res.status === 200) {
  //       setFaqs((faq) => res.data.faqs);
  //       setOffice((office) => res.data.offices);
  //       console.log("response", office);
  //     }
  //   });
  // };
  const [chatBtn, setChatBtn] = useState(true);
  const ChatHandler = () => {
    if (chatBtn) {
      setChatBtn(false);
    } else {
      setChatBtn(true);
    }
  };

  return (
    <div className='w-full relative'>
      <Nav
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        changeLanguage2={changeLanguage2}
      />
      {/* <VideoBanner lang={lang} enPhrase={enPhrase} arPhrase={arPhrase} services={services} /> */}
      <HomeBanner
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        services={services}
      />
      <AboutSection
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        vision={vision}
      />

      <OurPartner
        partners={partners}
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
      />

      {/* {services.length > 0 && (
        <Services
          services={services}
          lang={lang}
          enPhrase={enPhrase}
          arPhrase={arPhrase}
        />
      )} */}

      <DownloadApp lang={lang} enPhrase={enPhrase} arPhrase={arPhrase} />

      <News lang={lang} enPhrase={enPhrase} arPhrase={arPhrase} />
      {/* <Banner lang={lang} enPhrase={enPhrase} arPhrase={arPhrase} />   */}
      {/* <MainButtons />
      {services.length > 0 && (
        <Services
          services={services}
          lang={lang}
          enPhrase={enPhrase}
          arPhrase={arPhrase}
        />
      )}

      <About
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        images={images}
      />
      {partners.length > 0 && (
        <Partners
          partners={partners}
          lang={lang}
          enPhrase={enPhrase}
          arPhrase={arPhrase}
        />
      )}
      <Gallery lang={lang} enPhrase={enPhrase} arPhrase={arPhrase} />
      <SuccessPartners /> */}
      {window && window.innerWidth <= 1024 ? (
        <GeoLocationsMobile lang={lang} locations={offices} />
      ) : (
        <GeoLocations lang={lang} locations={offices} />
      )}

      {faqs?.length > 0 && (
        <FAQ
          faqs={faqs}
          lang={lang}
          enPhrase={enPhrase}
          arPhrase={arPhrase}
          ticketCategories={ticketCategories}
        />
      )}
      {/* {fqa?.length > 0 && (
        <FAQ
          fqa={fqa}
          lang={lang}
          enPhrase={enPhrase}
          arPhrase={arPhrase}
          ticketCategories={ticketCategories}
        />
      )} */}

      {/* {offices.length > 0 && (
        <Offices
          offices={offices}
          lang={lang}
          enPhrase={enPhrase}
          arPhrase={arPhrase}
        />
      )} */}
      {/* <ContactUs
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        ticketCategories={ticketCategories}
      /> */}
      {/* <BackToTop /> */}
      <Footer lang={lang} enPhrase={enPhrase} arPhrase={arPhrase} />
      <ChatToAdel
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        ChatHandler={ChatHandler}
      />

      {chatBtn === true ? (
        <LiveChatWidget license='16802304' visibility='minimized' />
      ) : (
        <LiveChatWidget license='16802304' visibility='maximized' />
      )}
    </div>
  );
};
export default Home;
