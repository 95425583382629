import React, { useContext, useEffect, useState } from "react";
import { Container } from "../components/utils";
import { PhrasesContext } from "../context/phrasesContext";
import Footer from "../layouts/Footer";
import Nav from "../layouts/Nav";
import FooterLayout from "../layouts/FooterLayout";
const PrivacyPolicy = () => {
  const [lang, setLang] = useState("");
  // const [enPhrase, setEnPhrase] = useState({});
  // const [arPhrase, setArPhrase] = useState({});

  const [enPhrase, arPhrase] = useContext(PhrasesContext);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    if (lang === "en") {
      document.body.dir = "ltr";
      setLang("en");
    } else {
      document.body.dir = "rtl";
      setLang("ar");
    }
    // getPhrasesService();
  }, []);

  

  const changeLanguage2 = (lang) => {
    if (lang === "en") {
      document.body.dir = "rtl";
      setLang("ar");
      window.localStorage.setItem("lang", "ar");
    } else {
      document.body.dir = "ltr";
      setLang("en");
      window.localStorage.setItem("lang", "en");
    }
    window.location.reload();
  };
  // const getPhrasesService = () => {
  //   return getPhrases().then((res) => {
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       const en_api = {};
  //       const ar_api = {};
  //       res.data.map((phrase) => {
  //         en_api[phrase.phraseKey] = phrase.phraseEn;
  //         ar_api[phrase.phraseKey] = phrase.phraseAr;
  //         return '';
  //       });
  //       setEnPhrase(en_api);
  //       setArPhrase(ar_api);
  //     }
  //   });
  // };
  return (
    <div className="w-full">
      <Nav
        lang={lang}
        enPhrase={enPhrase}
        arPhrase={arPhrase}
        changeLanguage2={changeLanguage2}
      />
      <section
        id="faq"
        className="w-full overflow-hidden bg-gold-10 border-t border-b wow fadeInUp pt-[0rem] xl:pt-[0rem]"
      >
        <Container>
          <div className="flex flex-col items-center justify-center pb-16 text-justify">
            <div className="flex flex-col items-center justify-center my-5">
              <h3 className="text-3xl lg:text-4xl text-black leading-snug font-semibold">
                {lang === "en"
                  ? enPhrase["lbl_privacy_policy"]
                  : arPhrase["lbl_privacy_policy"]}
              </h3>
            </div>
            <div className="items-center w-full bg-white">
              {lang === "en" && (
                <div className="w-full p-10 terms">
                  <div>
                    <p>Last updated: July 09, 2021</p>
                    <p>
                      This Privacy Policy describes Our policies and procedures
                      on the collection, use and disclosure of Your information
                      when You use the Service and tells You about Your privacy
                      rights and how the law protects You.
                    </p>
                    <p>
                      We use Your Personal data to provide and improve the
                      Service. By using the Service, You agree to the collection
                      and use of information in accordance with this Privacy
                      Policy.
                    </p>
                    <h1>Interpretation and Definitions</h1>
                    <h2>Interpretation</h2>
                    <p>
                      The words of which the initial letter is capitalized have
                      meanings defined under the following conditions. The
                      following definitions shall have the same meaning
                      regardless of whether they appear in singular or in
                      plural.
                    </p>
                    <h2>Definitions</h2>
                    <p>For the purposes of this Privacy Policy:</p>
                    <ul>
                      <li>
                        <p>
                          <strong>Account</strong> means a unique account
                          created for You to access our Service or parts of our
                          Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Affiliate</strong> means an entity that
                          controls, is controlled by or is under common control
                          with a party, where "control" means ownership of 50%
                          or more of the shares, equity interest or other
                          securities entitled to vote for election of directors
                          or other managing authority.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Application</strong> means the software
                          program provided by the Company downloaded by You on
                          any electronic device, named EJE
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Company</strong> (referred to as either "the
                          Company", "We", "Us" or "Our" in this Agreement)
                          refers to Emirates Capital Investment, Manam st, Ras
                          Al Khour, dubai .
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Country</strong> refers to: United Arab
                          Emirates
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Device</strong> means any device that can
                          access the Service such as a computer, a cellphone or
                          a digital tablet.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Personal Data</strong> is any information that
                          relates to an identified or identifiable individual.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Service</strong> refers to the Application.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Service Provider</strong> means any natural or
                          legal person who processes the data on behalf of the
                          Company. It refers to third-party companies or
                          individuals employed by the Company to facilitate the
                          Service, to provide the Service on behalf of the
                          Company, to perform services related to the Service or
                          to assist the Company in analyzing how the Service is
                          used.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Usage Data</strong> refers to data collected
                          automatically, either generated by the use of the
                          Service or from the Service infrastructure itself (for
                          example, the duration of a page visit).
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>You</strong> means the individual accessing or
                          using the Service, or the company, or other legal
                          entity on behalf of which such individual is accessing
                          or using the Service, as applicable.
                        </p>
                      </li>
                    </ul>
                    <h1>Collecting and Using Your Personal Data</h1>
                    <h2>Types of Data Collected</h2>
                    <h3>Personal Data</h3>
                    <p>
                      While using Our Service, We may ask You to provide Us with
                      certain personally identifiable information that can be
                      used to contact or identify You. Personally identifiable
                      information may include, but is not limited to:
                    </p>
                    <ul>
                      <li>
                        <p>Email address</p>
                      </li>
                      <li>
                        <p>First name and last name</p>
                      </li>
                      <li>
                        <p>Phone number</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                    </ul>
                    <h3>Usage Data</h3>
                    <p>
                      Usage Data is collected automatically when using the
                      Service.
                    </p>
                    <p>
                      Usage Data may include information such as Your Device's
                      Internet Protocol address (e.g. IP address), browser type,
                      browser version, the pages of our Service that You visit,
                      the time and date of Your visit, the time spent on those
                      pages, unique device identifiers and other diagnostic
                      data.
                    </p>
                    <p>
                      When You access the Service by or through a mobile device,
                      We may collect certain information automatically,
                      including, but not limited to, the type of mobile device
                      You use, Your mobile device unique ID, the IP address of
                      Your mobile device, Your mobile operating system, the type
                      of mobile Internet browser You use, unique device
                      identifiers and other diagnostic data.
                    </p>
                    <p>
                      We may also collect information that Your browser sends
                      whenever You visit our Service or when You access the
                      Service by or through a mobile device.
                    </p>
                    <h3>Information Collected while Using the Application</h3>
                    <p>
                      While using Our Application, in order to provide features
                      of Our Application, We may collect, with Your prior
                      permission:
                    </p>
                    <ul>
                      <li>
                        <p>Information regarding your location</p>
                      </li>
                      <li>
                        <p>
                          Pictures and other information from your Device's
                          camera and photo library
                        </p>
                      </li>
                    </ul>
                    <p>
                      We use this information to provide features of Our
                      Service, to improve and customize Our Service. The
                      information may be uploaded to the Company's servers
                      and/or a Service Provider's server or it may be simply
                      stored on Your device.
                    </p>
                    <p>
                      You can enable or disable access to this information at
                      any time, through Your Device settings.
                    </p>
                    <h2>Use of Your Personal Data</h2>
                    <p>
                      The Company may use Personal Data for the following
                      purposes:
                    </p>
                    <ul>
                      <li>
                        <p>
                          <strong>To provide and maintain our Service</strong>,
                          including to monitor the usage of our Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>To manage Your Account:</strong> to manage
                          Your registration as a user of the Service. The
                          Personal Data You provide can give You access to
                          different functionalities of the Service that are
                          available to You as a registered user.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>For the performance of a contract:</strong>{" "}
                          the development, compliance and undertaking of the
                          purchase contract for the products, items or services
                          You have purchased or of any other contract with Us
                          through the Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>To contact You:</strong> To contact You by
                          email, telephone calls, SMS, or other equivalent forms
                          of electronic communication, such as a mobile
                          application's push notifications regarding updates or
                          informative communications related to the
                          functionalities, products or contracted services,
                          including the security updates, when necessary or
                          reasonable for their implementation.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>To provide You</strong> with news, special
                          offers and general information about other goods,
                          services and events which we offer that are similar to
                          those that you have already purchased or enquired
                          about unless You have opted not to receive such
                          information.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>To manage Your requests:</strong> To attend
                          and manage Your requests to Us.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>For business transfers:</strong> We may use
                          Your information to evaluate or conduct a merger,
                          divestiture, restructuring, reorganization,
                          dissolution, or other sale or transfer of some or all
                          of Our assets, whether as a going concern or as part
                          of bankruptcy, liquidation, or similar proceeding, in
                          which Personal Data held by Us about our Service users
                          is among the assets transferred.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>For other purposes</strong>: We may use Your
                          information for other purposes, such as data analysis,
                          identifying usage trends, determining the
                          effectiveness of our promotional campaigns and to
                          evaluate and improve our Service, products, services,
                          marketing and your experience.
                        </p>
                      </li>
                    </ul>
                    <p>
                      We may share Your personal information in the following
                      situations:
                    </p>
                    <ul>
                      <li>
                        <strong>With Service Providers:</strong> We may share
                        Your personal information with Service Providers to
                        monitor and analyze the use of our Service, for payment
                        processing, to contact You.
                      </li>
                      <li>
                        <strong>For business transfers:</strong> We may share or
                        transfer Your personal information in connection with,
                        or during negotiations of, any merger, sale of Company
                        assets, financing, or acquisition of all or a portion of
                        Our business to another company.
                      </li>
                      <li>
                        <strong>With Affiliates:</strong> We may share Your
                        information with Our affiliates, in which case we will
                        require those affiliates to honor this Privacy Policy.
                        Affiliates include Our parent company and any other
                        subsidiaries, joint venture partners or other companies
                        that We control or that are under common control with
                        Us.
                      </li>
                      <li>
                        <strong>With business partners:</strong> We may share
                        Your information with Our business partners to offer You
                        certain products, services or promotions.
                      </li>
                      <li>
                        <strong>With other users:</strong> when You share
                        personal information or otherwise interact in the public
                        areas with other users, such information may be viewed
                        by all users and may be publicly distributed outside.
                      </li>
                      <li>
                        <strong>With Your consent</strong>: We may disclose Your
                        personal information for any other purpose with Your
                        consent.
                      </li>
                    </ul>
                    <h2>Retention of Your Personal Data</h2>
                    <p>
                      The Company will retain Your Personal Data only for as
                      long as is necessary for the purposes set out in this
                      Privacy Policy. We will retain and use Your Personal Data
                      to the extent necessary to comply with our legal
                      obligations (for example, if we are required to retain
                      your data to comply with applicable laws), resolve
                      disputes, and enforce our legal agreements and policies.
                    </p>
                    <p>
                      The Company will also retain Usage Data for internal
                      analysis purposes. Usage Data is generally retained for a
                      shorter period of time, except when this data is used to
                      strengthen the security or to improve the functionality of
                      Our Service, or We are legally obligated to retain this
                      data for longer time periods.
                    </p>
                    <h2>Transfer of Your Personal Data</h2>
                    <p>
                      Your information, including Personal Data, is processed at
                      the Company's operating offices and in any other places
                      where the parties involved in the processing are located.
                      It means that this information may be transferred to — and
                      maintained on — computers located outside of Your state,
                      province, country or other governmental jurisdiction where
                      the data protection laws may differ than those from Your
                      jurisdiction.
                    </p>
                    <p>
                      Your consent to this Privacy Policy followed by Your
                      submission of such information represents Your agreement
                      to that transfer.
                    </p>
                    <p>
                      The Company will take all steps reasonably necessary to
                      ensure that Your data is treated securely and in
                      accordance with this Privacy Policy and no transfer of
                      Your Personal Data will take place to an organization or a
                      country unless there are adequate controls in place
                      including the security of Your data and other personal
                      information.
                    </p>
                    <h2>Disclosure of Your Personal Data</h2>
                    <h3>Business Transactions</h3>
                    <p>
                      If the Company is involved in a merger, acquisition or
                      asset sale, Your Personal Data may be transferred. We will
                      provide notice before Your Personal Data is transferred
                      and becomes subject to a different Privacy Policy.
                    </p>
                    <h3>Law enforcement</h3>
                    <p>
                      Under certain circumstances, the Company may be required
                      to disclose Your Personal Data if required to do so by law
                      or in response to valid requests by public authorities
                      (e.g. a court or a government agency).
                    </p>
                    <h3>Other legal requirements</h3>
                    <p>
                      The Company may disclose Your Personal Data in the good
                      faith belief that such action is necessary to:
                    </p>
                    <ul>
                      <li>Comply with a legal obligation</li>
                      <li>
                        Protect and defend the rights or property of the Company
                      </li>
                      <li>
                        Prevent or investigate possible wrongdoing in connection
                        with the Service
                      </li>
                      <li>
                        Protect the personal safety of Users of the Service or
                        the public
                      </li>
                      <li>Protect against legal liability</li>
                    </ul>
                    <h2>Security of Your Personal Data</h2>
                    <p>
                      The security of Your Personal Data is important to Us, but
                      remember that no method of transmission over the Internet,
                      or method of electronic storage is 100% secure. While We
                      strive to use commercially acceptable means to protect
                      Your Personal Data, We cannot guarantee its absolute
                      security.
                    </p>
                    <h1>
                      Detailed Information on the Processing of Your Personal
                      Data
                    </h1>
                    <p>
                      The Service Providers We use may have access to Your
                      Personal Data. These third-party vendors collect, store,
                      use, process and transfer information about Your activity
                      on Our Service in accordance with their Privacy Policies.
                    </p>
                    <h2>Payments</h2>
                    <p>
                      We may provide paid products and/or services within the
                      Service. In that case, we may use third-party services for
                      payment processing (e.g. payment processors).
                    </p>
                    <p>
                      We will not store or collect Your payment card details.
                      That information is provided directly to Our third-party
                      payment processors whose use of Your personal information
                      is governed by their Privacy Policy. These payment
                      processors adhere to the standards set by PCI-DSS as
                      managed by the PCI Security Standards Council, which is a
                      joint effort of brands like Visa, Mastercard, American
                      Express and Discover. PCI-DSS requirements help ensure the
                      secure handling of payment information.
                    </p>
                    <ul>
                      <li>
                        <p>
                          <strong>CC Avenu</strong>
                        </p>
                        <p>
                          Their Privacy Policy can be viewed at
                          <a
                            href="https://www.ccavenue.com/privacy_policy_customers.jsp"
                            rel="external nofollow noopener noreferrer"
                            target="_blank"
                          >
                            https://www.ccavenue.com/privacy_policy_customers.jsp
                          </a>
                        </p>
                      </li>
                    </ul>
                    <h1>Children's Privacy</h1>
                    <p>
                      Our Service does not address anyone under the age of 13.
                      We do not knowingly collect personally identifiable
                      information from anyone under the age of 13. If You are a
                      parent or guardian and You are aware that Your child has
                      provided Us with Personal Data, please contact Us. If We
                      become aware that We have collected Personal Data from
                      anyone under the age of 13 without verification of
                      parental consent, We take steps to remove that information
                      from Our servers.
                    </p>
                    <p>
                      If We need to rely on consent as a legal basis for
                      processing Your information and Your country requires
                      consent from a parent, We may require Your parent's
                      consent before We collect and use that information.
                    </p>
                    <h1>Links to Other Websites</h1>
                    <p>
                      Our Service may contain links to other websites that are
                      not operated by Us. If You click on a third party link,
                      You will be directed to that third party's site. We
                      strongly advise You to review the Privacy Policy of every
                      site You visit.
                    </p>
                    <p>
                      We have no control over and assume no responsibility for
                      the content, privacy policies or practices of any third
                      party sites or services.
                    </p>
                    <h1>Changes to this Privacy Policy</h1>
                    <p>
                      We may update Our Privacy Policy from time to time. We
                      will notify You of any changes by posting the new Privacy
                      Policy on this page.
                    </p>
                    <p>
                      We will let You know via email and/or a prominent notice
                      on Our Service, prior to the change becoming effective and
                      update the "Last updated" date at the top of this Privacy
                      Policy.
                    </p>
                    <p>
                      You are advised to review this Privacy Policy periodically
                      for any changes. Changes to this Privacy Policy are
                      effective when they are posted on this page.
                    </p>
                    <h1>Contact Us</h1>
                    <p>
                      If you have any questions about this Privacy Policy, You
                      can contact us:
                    </p>
                    <ul>
                      <li>By phone number: 600 55 33 55</li>
                    </ul>
                  </div>
                </div>
              )}

              {lang === "ar" && (
                <div className="w-full p-10 terms">
                  <div>
                    <h1>سياسة الخصوصية</h1>
                    <p> آخر تحديث: 09 تموز (يوليو) 2021 </p>
                    <p>
                      {" "}
                      تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا المتعلقة بجمع
                      معلوماتك واستخدامها والكشف عنها عند استخدامك للخدمة وتخبرك
                      بحقوق الخصوصية الخاصة بك وكيف يحميك القانون.{" "}
                    </p>
                    <p>
                      نستخدم بياناتك الشخصية لتوفير الخدمة وتحسينها. باستخدام
                      الخدمة ، فإنك توافق على جمع واستخدام المعلومات وفقًا
                      لسياسة الخصوصية هذه.
                    </p>
                    <h1>التفسير والتعريفات</h1>
                    <h2> تفسير </h2>
                    <p>
                      الكلمات التي يتم كتابة الحرف الأول بها معاني محددة وفقًا
                      للشروط التالية. يجب أن يكون للتعريفات التالية نفس المعنى
                      بغض النظر عما إذا كانت تظهر بصيغة المفرد أو الجمع.
                    </p>
                    <h2> تعريفات </h2>
                    <p>لأغراض سياسة الخصوصية هذه:</p>
                    <ul>
                      <li>
                        <p>
                          <strong>الحساب</strong> يعني حسابًا فريدًا تم إنشاؤه
                          لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>شركة تابعة</strong> تعني الكيان الذي يتحكم أو
                          يتحكم فيه أو يخضع لسيطرة مشتركة مع أحد الأطراف ، حيث
                          تعني "السيطرة" ملكية 50٪ أو أكثر من الأسهم أو حقوق
                          الملكية أو الأوراق المالية الأخرى المخولة للتصويت
                          لانتخاب أعضاء مجلس الإدارة أو أي سلطة إدارية أخرى.
                        </p>
                      </li>
                      <li>
                        <p>
                          {" "}
                          <strong> التطبيق </strong> يعني البرنامج الذي توفره
                          الشركة الذي تقوم بتنزيله على أي جهاز إلكتروني ، يُسمى
                          EJE{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          {" "}
                          <strong> الشركة </strong> (يشار إليها باسم "الشركة" أو
                          "نحن" أو "لنا" أو "لنا" في هذه الاتفاقية) تشير إلى
                          الإمارات كابيتال للاستثمار ، شارع المنامة ، رأس الخور
                          ، دبي .{" "}
                        </p>
                      </li>
                      <li>
                        تشير{" "}
                        <p>
                          {" "}
                          <strong> الدولة </strong> إلى: الإمارات العربية
                          المتحدة{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          {" "}
                          <strong> الجهاز </strong> يعني أي جهاز يمكنه الوصول
                          إلى الخدمة مثل جهاز كمبيوتر أو هاتف محمول أو جهاز لوحي
                          رقمي.{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          {" "}
                          <strong> البيانات الشخصية </strong> هي أي معلومات
                          تتعلق بفرد محدد الهوية أو يمكن التعرف عليه.{" "}
                        </p>
                      </li>
                      <li>
                        تشير{" "}
                        <p>
                          {" "}
                          <strong> الخدمة </strong> إلى التطبيق.{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          {" "}
                          <strong> مقدم الخدمة </strong>
                        </p>
                      </li>
                      <li>
                        تشير{" "}
                        <p>
                          {" "}
                          <strong> بيانات الاستخدام </strong> إلى البيانات التي
                          يتم جمعها تلقائيًا ، إما الناتجة عن استخدام الخدمة أو
                          من البنية التحتية للخدمة نفسها (على سبيل المثال ، مدة
                          زيارة الصفحة).{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          {" "}
                          <strong> أنت </strong> تعني الشخص الذي يصل إلى الخدمة
                          أو يستخدمها ، أو الشركة ، أو الكيان القانوني الآخر
                          الذي يصل هذا الفرد إلى الخدمة أو يستخدمها نيابةً عنه ،
                          حسب الاقتضاء.{" "}
                        </p>
                      </li>
                    </ul>
                    <h1>جمع بياناتك الشخصية واستخدامها</h1>
                    <h2>أنواع البيانات المجمعة</h2>
                    <h3>البيانات الشخصية</h3>
                    <p>
                      أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا بمعلومات تعريف
                      شخصية معينة يمكن استخدامها للاتصال بك أو التعرف عليك. قد
                      تتضمن معلومات التعريف الشخصية ، على سبيل المثال لا الحصر:
                    </p>
                    <ul>
                      <li>
                        <p>عنوان البريد الإلكتروني</p>
                      </li>
                      <li>
                        <p>الاسم الأول واسم العائلة</p>
                      </li>
                      <li>
                        <p>رقم الهاتف</p>
                      </li>
                      <li>
                        <p>بيانات الاستخدام</p>
                      </li>
                    </ul>
                    <h3> بيانات الاستخدام </h3>
                    <p>
                      {" "}
                      يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.{" "}
                    </p>
                    <p>
                      {" "}
                      قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول
                      الإنترنت الخاص بجهازك (مثل عنوان IP) ، ونوع المتصفح ،
                      وإصدار المتصفح ، وصفحات الخدمة التي تزورها ، ووقت وتاريخ
                      زيارتك ، والوقت الذي تقضيه في تلك الصفحات ومعرفات الجهاز
                      الفريدة وبيانات التشخيص الأخرى.{" "}
                    </p>
                    <p>
                      {" "}
                      عند الوصول إلى الخدمة عن طريق أو من خلال جهاز محمول ، يجوز
                      لنا جمع معلومات معينة تلقائيًا ، بما في ذلك ، على سبيل
                      المثال لا الحصر ، نوع الجهاز المحمول الذي تستخدمه ،
                      والمعرف الفريد لجهازك المحمول ، وعنوان IP لجهازك المحمول
                      ونظام تشغيل هاتفك المحمول ونوع متصفح الإنترنت على الهاتف
                      المحمول الذي تستخدمه ومعرفات الجهاز الفريدة وبيانات
                      التشخيص الأخرى.{" "}
                    </p>
                    <p>
                      قد نجمع أيضًا المعلومات التي يرسلها متصفحك عندما تزور
                      خدمتنا أو عندما تدخل إلى الخدمة عن طريق أو من خلال جهاز
                      محمول.
                    </p>
                    <h3> المعلومات التي يتم جمعها أثناء استخدام التطبيق </h3>
                    <p>
                      أثناء استخدام تطبيقنا ، من أجل توفير ميزات تطبيقنا ، قد
                      نجمع ، بعد الحصول على إذن مسبق منك:
                    </p>
                    <ul>
                      <li>
                        <p>معلومات بخصوص موقعك</p>
                      </li>
                      <li>
                        <p>
                          الصور والمعلومات الأخرى من كاميرا جهازك ومكتبة الصور
                        </p>
                      </li>
                    </ul>
                    <p>
                      {" "}
                      نستخدم هذه المعلومات لتوفير ميزات خدمتنا ، لتحسين خدمتنا
                      وتخصيصها. قد يتم تحميل المعلومات على خوادم الشركة و / أو
                      خادم مزود الخدمة أو قد يتم تخزينها ببساطة على جهازك.{" "}
                    </p>
                    <p>
                      يمكنك تمكين أو تعطيل الوصول إلى هذه المعلومات في أي وقت ،
                      من خلال إعدادات جهازك.
                    </p>
                    <h2> استخدام بياناتك الشخصية </h2>
                    <p>يجوز للشركة استخدام البيانات الشخصية للأغراض التالية:</p>
                    <ul>
                      <li>
                        <p>
                          <strong>لتقديم خدمتنا وصيانتها</strong> ، بما في ذلك
                          مراقبة استخدام خدمتنا.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>لإدارة حسابك:</strong> لإدارة تسجيلك كمستخدم
                          للخدمة. يمكن أن تمنحك البيانات الشخصية التي تقدمها
                          إمكانية الوصول إلى الوظائف المختلفة للخدمة المتاحة لك
                          كمستخدم مسجل.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>لتنفيذ عقد:</strong>{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>للاتصال بك:</strong> للاتصال بك عن طريق البريد
                          الإلكتروني أو المكالمات الهاتفية أو الرسائل القصيرة أو
                          غير ذلك من أشكال الاتصالات الإلكترونية المماثلة ، مثل
                          الإخطارات الفورية لتطبيقات الهاتف المحمول فيما يتعلق
                          بالتحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف ،
                          المنتجات أو الخدمات المتعاقد عليها ، بما في ذلك
                          التحديثات الأمنية ، عند الضرورة أو المعقول لتنفيذها.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>لتزويدك</strong> بأخبار وعروض خاصة ومعلومات
                          عامة حول السلع والخدمات والأحداث الأخرى التي نقدمها
                          والتي تشبه تلك التي اشتريتها بالفعل أو استفسرت عنها ما
                          لم تكن قد اخترت عدم تلقي هذه المعلومات.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>لإدارة طلباتك:</strong> لحضور وإدارة طلباتك
                          إلينا.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>لنقل الأعمال:</strong> قد نستخدم معلوماتك
                          لتقييم أو إجراء عملية دمج أو تجريد أو إعادة هيكلة أو
                          إعادة تنظيم أو حل أو غير ذلك من عمليات البيع أو النقل
                          لبعض أو كل أصولنا ، سواء كانت الاستمرارية أو كجزء من
                          الإفلاس أو التصفية أو الإجراءات المماثلة ، حيث تكون
                          البيانات الشخصية التي نحتفظ بها حول مستخدمي خدمتنا من
                          بين الأصول المنقولة.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>لأغراض أخرى</strong>: قد نستخدم معلوماتك
                          لأغراض أخرى ، مثل تحليل البيانات ، وتحديد اتجاهات
                          الاستخدام ، وتحديد فعالية حملاتنا الترويجية ولتقييم
                          وتحسين خدماتنا ومنتجاتنا وخدماتنا والتسويق وتجربتك.
                        </p>
                      </li>
                    </ul>
                    <p>قد نشارك معلوماتك الشخصية في المواقف التالية:</p>
                    <ul>
                      <li>
                        {" "}
                        <strong> مع مقدمي الخدمة: </strong> قد نشارك معلوماتك
                        الشخصية مع مزودي الخدمة لمراقبة وتحليل استخدام خدمتنا ،
                        لمعالجة الدفع ، للاتصال بك.{" "}
                      </li>
                      <li>
                        {" "}
                        <strong> لنقل الأعمال: </strong> يجوز لنا مشاركة
                        معلوماتك الشخصية أو نقلها فيما يتعلق أو أثناء المفاوضات
                        بشأن أي دمج أو بيع أصول الشركة أو التمويل أو الاستحواذ
                        على كل أو جزء من عمل لشركة أخرى.{" "}
                      </li>
                      <li>
                        {" "}
                        <strong> مع الشركات التابعة: </strong> قد نشارك معلوماتك
                        مع الشركات التابعة لنا ، وفي هذه الحالة سنطلب من تلك
                        الشركات التابعة احترام سياسة الخصوصية هذه. تشمل الشركات
                        التابعة شركتنا الأم وأي شركات فرعية أخرى أو شركاء في
                        المشاريع المشتركة أو شركات أخرى نسيطر عليها أو تخضع
                        لسيطرة مشتركة معنا.{" "}
                      </li>
                      <li>
                        {" "}
                        <strong> مع شركاء الأعمال: </strong> قد نشارك معلوماتك
                        مع شركائنا في العمل لنقدم لك منتجات أو خدمات أو عروض
                        ترويجية معينة.{" "}
                      </li>
                      <li>
                        {" "}
                        <strong> مع مستخدمين آخرين: </strong> عندما تشارك
                        معلومات شخصية أو تتفاعل بطريقة أخرى في المناطق العامة مع
                        مستخدمين آخرين ، فقد يشاهد جميع المستخدمين هذه المعلومات
                        وقد يتم توزيعها علنًا في الخارج.{" "}
                      </li>
                      <li>
                        {" "}
                        <strong> بموافقتك </strong>: يجوز لنا الكشف عن معلوماتك
                        الشخصية لأي غرض آخر بموافقتك.{" "}
                      </li>
                    </ul>
                    <h2> الاحتفاظ ببياناتك الشخصية </h2>
                    <p>
                      {" "}
                      ستحتفظ الشركة ببياناتك الشخصية فقط طالما كان ذلك ضروريًا
                      للأغراض المنصوص عليها في سياسة الخصوصية هذه. سنحتفظ
                      ببياناتك الشخصية ونستخدمها بالقدر اللازم للامتثال
                      لالتزاماتنا القانونية (على سبيل المثال ، إذا كنا مطالبين
                      بالاحتفاظ ببياناتك للامتثال للقوانين المعمول بها)
                    </p>
                    <p>
                      ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل
                      الداخلي. بشكل عام ، يتم الاحتفاظ ببيانات الاستخدام لفترة
                      زمنية أقصر ، إلا في حالة استخدام هذه البيانات لتعزيز الأمن
                      أو لتحسين وظائف خدمتنا ، أو نحن ملزمون قانونًا بالاحتفاظ
                      بهذه البيانات لفترات زمنية أطول.
                    </p>
                    <h2> نقل بياناتك الشخصية </h2>
                    <p>
                      {" "}
                      تتم معالجة معلوماتك ، بما في ذلك البيانات الشخصية ، في
                      مكاتب تشغيل الشركة وفي أي أماكن أخرى توجد بها الأطراف
                      المشاركة في المعالجة. وهذا يعني أنه قد يتم نقل هذه
                      المعلومات - والاحتفاظ بها - على أجهزة الكمبيوتر الموجودة
                      خارج ولايتك أو مقاطعتك أو بلدك أو أي ولاية قضائية حكومية
                      أخرى حيث قد تختلف قوانين حماية البيانات عن تلك الموجودة في
                      نطاق سلطتك.
                    </p>
                    <p>
                      {" "}
                      موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه
                      المعلومات يمثل موافقتك على هذا النقل.{" "}
                    </p>
                    <p>
                      ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول لضمان التعامل
                      مع بياناتك بشكل آمن ووفقًا لسياسة الخصوصية هذه ولن يتم نقل
                      بياناتك الشخصية إلى مؤسسة أو دولة ما لم تكن هناك ضوابط
                      كافية مطبقة بما في ذلك أمان بياناتك والمعلومات الشخصية
                      الأخرى.
                    </p>
                    <h2>الكشف عن بياناتك الشخصية</h2>
                    <h3> المعاملات التجارية </h3>
                    <p>
                      إذا كانت الشركة متورطة في عملية دمج أو استحواذ أو بيع أصول
                      ، فقد يتم نقل بياناتك الشخصية. سنقدم إشعارًا قبل نقل
                      بياناتك الشخصية وخضوعها لسياسة خصوصية مختلفة.
                    </p>
                    <h3> تطبيق القانون </h3>
                    <p>
                      في ظل ظروف معينة ، قد يُطلب من الشركة الإفصاح عن بياناتك
                      الشخصية إذا طُلب منها ذلك بموجب القانون أو استجابة لطلبات
                      صحيحة من قبل السلطات العامة (مثل محكمة أو وكالة حكومية).
                    </p>
                    <h3> المتطلبات القانونية الأخرى </h3>
                    <p>
                      قد تفصح الشركة عن بياناتك الشخصية بحسن نية أن هذا الإجراء
                      ضروري من أجل:
                    </p>
                    <ul>
                      <li> الامتثال لالتزام قانوني </li>
                      <li> حماية والدفاع عن حقوق أو ممتلكات الشركة </li>
                      <li>
                        {" "}
                        منع أو التحقيق في أي مخالفات محتملة تتعلق بالخدمة{" "}
                      </li>
                      <li>
                        {" "}
                        حماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور{" "}
                      </li>
                      <li> الحماية من المسؤولية القانونية </li>
                    </ul>
                    <h2> أمان بياناتك الشخصية </h2>
                    <p>
                      يعد أمان بياناتك الشخصية أمرًا مهمًا بالنسبة لنا ، ولكن
                      تذكر أنه لا توجد طريقة نقل عبر الإنترنت أو طريقة تخزين
                      إلكتروني آمنة بنسبة 100٪. بينما نسعى جاهدين لاستخدام وسائل
                      مقبولة تجاريًا لحماية بياناتك الشخصية ، لا يمكننا ضمان
                      أمنها المطلق.
                    </p>
                    <h1> معلومات مفصلة حول معالجة بياناتك الشخصية </h1>
                    <p>
                      قد يتمكن مقدمو الخدمة الذين نستخدمهم من الوصول إلى بياناتك
                      الشخصية. يقوم بائعو الجهات الخارجية هؤلاء بجمع وتخزين
                      واستخدام ومعالجة ونقل المعلومات حول نشاطك على خدمتنا وفقًا
                      لسياسات الخصوصية الخاصة بهم.
                    </p>
                    <h2> المدفوعات </h2>
                    <p>
                      {" "}
                      يجوز لنا تقديم منتجات و / أو خدمات مدفوعة ضمن الخدمة. في
                      هذه الحالة ، قد نستخدم خدمات الجهات الخارجية لمعالجة الدفع
                      (مثل معالجي الدفع).{" "}
                    </p>
                    <p>
                      لن نخزن أو نجمع تفاصيل بطاقة الدفع الخاصة بك. يتم تقديم
                      هذه المعلومات مباشرةً إلى معالجي الدفع التابعين لجهات
                      خارجية والذين يخضع استخدامهم لمعلوماتك الشخصية لسياسة
                      الخصوصية الخاصة بهم. تلتزم معالجات الدفع هذه بالمعايير
                      التي حددتها PCI-DSS كما يديرها مجلس معايير أمان PCI ، وهو
                      جهد مشترك لعلامات تجارية مثل Visa و Mastercard و American
                      Express و Discover. تساعد متطلبات PCI-DSS على ضمان
                      المعالجة الآمنة لمعلومات الدفع.
                    </p>
                    <ul>
                      <li>
                        <p>
                          {" "}
                          <strong> CC أفينو </strong>{" "}
                        </p>
                        <p>
                          {" "}
                          يمكن الاطلاع على سياسة الخصوصية الخاصة بهم على{" "}
                          <a
                            href="https://www.ccavenue.com/privacy_policy_customers.jsp"
                            rel="external nofollow noopener noreferrer"
                            target="_blank"
                          >
                            {" "}
                            https: //www.ccavenue. com /
                            privacy_policy_customers.jsp{" "}
                          </a>{" "}
                        </p>
                      </li>
                    </ul>
                    <h1>خصوصية الأطفال</h1>
                    <p>
                      {" "}
                      لا تخاطب خدمتنا أي شخص يقل عمره عن 13 عامًا. نحن لا نجمع
                      عن قصد معلومات تعريف شخصية من أي شخص يقل عمره عن 13 عامًا.
                      إذا كنت أحد الوالدين أو الوصي وتدرك أن طفلك قد زودنا
                      بمعلومات شخصية البيانات ، يرجى الاتصال بنا. إذا علمنا أننا
                      قد جمعنا بيانات شخصية من أي شخص يقل عمره عن 13 عامًا دون
                      التحقق من موافقة الوالدين ، فإننا نتخذ خطوات لإزالة هذه
                      المعلومات من خوادمنا.{" "}
                    </p>
                    <p>
                      إذا احتجنا إلى الاعتماد على الموافقة كأساس قانوني لمعالجة
                      معلوماتك وكان بلدك يتطلب موافقة أحد الوالدين ، فقد نطلب
                      موافقة والديك قبل أن نجمع هذه المعلومات ونستخدمها.
                    </p>
                    <h1> روابط لمواقع أخرى </h1>
                    <p>
                      {" "}
                      قد تحتوي خدمتنا على روابط لمواقع أخرى لا نقوم بتشغيلها.
                      إذا قمت بالنقر فوق ارتباط جهة خارجية ، فسيتم توجيهك إلى
                      موقع الطرف الثالث. ننصحك بشدة بمراجعة سياسة الخصوصية لكل
                      موقع تزوره.{" "}
                    </p>
                    <p>
                      ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو
                      سياسات الخصوصية أو ممارسات أي مواقع أو خدمات تابعة لجهات
                      خارجية.
                    </p>
                    <h1> التغييرات في سياسة الخصوصية هذه </h1>
                    <p>
                      {" "}
                      قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر.
                      سنخطرك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على
                      هذه الصفحة.{" "}
                    </p>
                    <p>
                      {" "}
                      سنخبرك عبر البريد الإلكتروني و / أو إشعارًا بارزًا على
                      "خدمتنا" ، قبل أن يصبح التغيير ساريًا وتحديث تاريخ "آخر
                      تحديث" في الجزء العلوي من سياسة الخصوصية هذه.{" "}
                    </p>
                    <p>
                      يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي
                      تغييرات. تسري التغييرات التي تطرأ على سياسة الخصوصية هذه
                      عند نشرها على هذه الصفحة.
                    </p>
                    <h1> اتصل بنا </h1>
                    <p>
                      إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه ، يمكنك
                      الاتصال بنا:
                    </p>
                    <ul>
                      <li> برقم الهاتف: 600 55 33 55 </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
      <FooterLayout lang={lang} enPhrase={enPhrase} arPhrase={arPhrase}  dashboard/>
    </div>
  );
};

export default PrivacyPolicy;
