import axios from "axios";
import classNames from "classnames/bind";
import { React, useContext, useEffect, useState } from "react";
import { PhrasesContext } from "../../../context/phrasesContext";

const cx = classNames;

const Location = (props) => {
  const {
    title,
    des,
    setActiveLocation,
    activeLocation,
    LocationId,
    phone,
    email,
    lat,
    lng,
    setMapMarkers,
    initMarkers,
    setMapZoom,
    inAll = false,
    lang,
  } = props;
  const [enPhrase, arPhrase] = useContext(PhrasesContext);
  const [currentLatitude, setCurrentLatitude] = useState(0);
  const [currentLongitude, setCurrentLongitude] = useState(0);

  const DefaultLocation = { lat: 0, lng: 0 };
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const openLocationHandler = () => {
    if (activeLocation === LocationId) {
      setMapZoom(8);
      setActiveLocation(null);
      setMapMarkers(initMarkers);
    } else {
      setMapZoom(16);
      setActiveLocation(LocationId);
      setMapMarkers([
        {
          lat: lat,
          lng: lng,
          icon: "/assets/img/map/mark.svg",
          iconW: 80,
          iconH: 80,
          iconScale: 0.7,
        },
      ]);
    }
  };

  const getData = async () => {
    const res = await axios.get("https://ipapi.co/json");
    setDefaultLocation(res.data);
    setCurrentLatitude(res.data.latitude);
    setCurrentLongitude(res.data.longitude);
  };

  // useEffect(() => {
  //   checkLocationState();
  // }, []);

 

  const checkLocationState = () => {
    if (navigator?.permissions) {
      navigator?.permissions?.query({ name: "geolocation" }).then((res) => {
        const { state } = res;
        locationStateCheckers(state);
        res.onchange = (e) => {
          if (e.type === "change") {
            const { state } = res;
            locationStateCheckers(state);
          }
        };
      });
    }
  };

  const locationStateCheckers = (state) => {
    if (state && state === "granted") {
      navigator?.geolocation?.getCurrentPosition((position) => {
        setCurrentLatitude(position.coords.latitude);
        setCurrentLongitude(position.coords.longitude);
      });
    } else if (state && state === "denied") {
      // navigator?.geolocation?.getCurrentPosition(
      //   successCallback,
      //   errorCallback
      // );
      getData();
      // setCurrentLatitude(defaultLocation?.latitude);
      // setCurrentLongitude(defaultLocation?.longitude);
    } else {
      navigator?.geolocation?.getCurrentPosition(
        successCallback,
        errorCallback
      );
    }
  };

  useEffect(() => {
    getData();
    setTimeout(() => {
      checkLocationState();
    }, 500);
  }, []);

  const successCallback = (position) => {};

  const errorCallback = (error) => {};

  return (
    <>
      <div
        className={cx(
          "flex h-auto min-h-[100px] border-b-[1px] border-[#ddd]",
          activeLocation === LocationId && "bg-[#f9f7ed]",
          inAll && "last-of-type:border-b-0"
        )}
      >
        <div className="flex items-start pt-[16px] ms-[18px] me-[18px]">
          <img src="/assets/img/geoLocations/location.svg" alt="location" />
        </div>
        <div className="flex flex-col gap-[8px] pt-[16px] w-[68%]">
          <h2 className="text-[16px] text-[#361d13] font-bold">{title}</h2>
          <p className="text-[12px] text-[#888888]">{des}</p>
        </div>
        <div
          className="w-[48px] flex justify-center items-center border-[#ddd] cursor-pointer border-s-[1px]"
          onClick={openLocationHandler}
        >
          {activeLocation === LocationId ? (
            <img src="/assets/img/geoLocations/arrowOpen.svg" alt="arrow" />
          ) : (
            <img src="/assets/img/geoLocations/arrow.svg" alt="arrow" />
          )}
        </div>
      </div>
      {activeLocation === LocationId && (
        <div className="flex flex-col gap-[16px] border-b-[1px] border-[#ddd] bg-[#f9f7ed] justify-between items-center pl-[32px] pr-[32px]">
          <div className="flex gap-[24px] pt-[16px] justify-center w-full">
            <div className="flex items-center gap-[8px]">
              <img src="/assets/img/geoLocations/phone.svg" alt="phone" />
              <a
                className="text-[16px] text-[#5d5d5d] font-medium"
                href={`tel:${phone}`}
              >
                {phone}
              </a>
            </div>
            <div className="flex items-center gap-[8px]">
              <img src="/assets/img/geoLocations/email.svg" alt="email" />
              <a
                className="text-[16px] text-[#5d5d5d] font-medium"
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </div>
          </div>
          <div className="flex cursor-pointer pt-[16px] pb-[16px] border-t-[1px] border-[#ddd] justify-center w-full">
            <a
              className="flex items-center gap-[8px]"
              href={`https://www.google.com/maps/dir/${currentLatitude},${currentLongitude}/${lat},${lng}/@${lat}},${lng},4z/?hl=en`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/img/geoLocations/directions.svg"
                alt="directions"
              />
              <p className="text-[16px] text-[#b68a35] font-bold">
                {lang === "en"
                  ? enPhrase["lblDirections"]
                  : arPhrase["lblDirections"]}
              </p>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Location;
